import { MetaDataField } from "client-server-shared/config/fieldMapping";
import { useEditPostPropertiesById } from "hooks/use-edit-post-properties";
import React from "react";
import { ComponentBaseContext } from "../types";

import { TextInput, TextFieldContainer } from "./shared";

import { useGenerateMetaTitle } from "hooks/use-generate-meta";

export const MetaTitleEdit = ({
  value,
  collectionId,
  postId,
  sectionTitle,
  placeholder,
  infoTooltipText = ""
}: ComponentBaseContext & { value?: string }) => {
  const { onPropertyChanged } = useEditPostPropertiesById(collectionId, postId);

  const { onGeneratePostField, isLoading } = useGenerateMetaTitle({
    collectionId,
    postId,
  });

  const onGenerate = () => {
    onGeneratePostField();
  };

  return (
    <TextFieldContainer
      collectionId={collectionId}
      postId={postId}
      infoTooltipText={infoTooltipText}
      label={sectionTitle}
      name={MetaDataField.MetaTitle}
      value={value || ""}
      isLoading={isLoading}
      characterCount={(value || "").length}
      onGenerate={onGenerate}
    >
      <TextInput
        value={value || ""}
        placeholder={placeholder}
        onChange={(value) => {
          if (isLoading) {
            return;
          }
          onPropertyChanged(MetaDataField.MetaTitle, value as string);
        }}
      />
    </TextFieldContainer>
  );
};
