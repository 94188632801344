import { templateCategory, TemplateType } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

export const betterExplainTemplate: TemplateType = {
  id: "simplified-explanation-PmeQS",
  value: "simplified-explanation-PmeQS",
  label: "simplified-explanation",
  slug: "simplified-explanation",
  categories: [templateCategory.custom],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "explanation",
  title: "Simplified Explanation",
  defaultWordsCount: 200,
  contentTypeLabel: "explanation",
  helpText: "Provide a clear and easy-to-understand explanation of a concept",
  defaultCollectionName: "Simplified Explanation",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your simplified explanation",
  defaultPostName: "Untitled Explanation",
  toGeneratorPrompt: "Need to generate more simplified explanations?",
  cardDescription:
    "Create easy-to-understand explanations of complex concepts for a wide audience.",
  shortDescription: "",
  caption:
    "You can edit and customize the generated content to better suit your specific audience and preferences.",
  addtionalFields: [],
  excludedFields: [
    "writingStyle",
    "keywords",
    "editorialImage",
    "startFromOutline",
  ],
  fields: {
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 100,
      placeholder: "Enter or generate a title of the concept",
      onValidate: (post) => {
        return !!(isDescriptionDefined(post) || isPartialContentDefined(post));
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const language = post.language;
        const content = post.content;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;

        let prompt = "Generate a title for a concept";

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content from the concept explanation: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Details",
      fieldName: "description",
      placeholder: `- [Original Explanation]
- [Additional instructions]`,
      required: true,
      description: " ",
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Friendly, formal",
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 200,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;

        let prompt =
          "Write a simplified explanation of a concept based on the provided information";

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the explanation to ${wordsCount} words.`;
        }

        prompt +=
          "The explanation should cover the main aspects of the concept in a clear and concise manner.";

        return prompt;
      },
    },
  },
};
