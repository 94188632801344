import {
  serverRoutes,
  templateServerRouteBySlug,
} from "client-server-shared/config/routes";
import { siteConfig } from "client-server-shared/config/siteConfig";
import { LogoIconOnly } from "components/logo";
import { NextLink } from "components/next-link";
import { Avatar } from "components/ui/avatar";
import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Container } from "components/ui/container";
import { List, ListItem, ListSubHeader } from "components/ui/list/List";
import { Description, Typography } from "components/ui/Typography";
import { MainBackgroundWrapper } from "./shared";
import { MetaColorIcon } from "client-server-shared/icons/meta-color-icon";
import { TwitterColorIcon } from "client-server-shared/icons/twitter-color-icon";
import Affilate from "./affliate";
import { trackClickOnSocialMediaLink } from "utils/tracking/common";
import { Grid } from "components/ui/grid";

interface ListSectionProps {
  headerText: string;
  list: { text: string; to: string }[];
}

const VerificationItem = ({ heading, text }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: "12px",
        textAlign: "center",
      }}
    >
      <Description
        sx={{
          color: "text.white",
        }}
      >
        {heading}
      </Description>
      <Description
        sx={{
          color: "text.white",
        }}
      >
        {text}
      </Description>
    </Box>
  );
};
const VerificationPurpuses = () => {
  return null;
  return (
    <Box
      sx={{
        display: "flex",
        columnGap: "12px",
        justifyContent: "center",
        margin: "12px auto",
      }}
    >
      <VerificationItem heading="Registered entity name" text="YZplanet Oy" />
      <VerificationItem heading="Tax ID" text="FI31095196" />
    </Box>
  );
};

const useData = () => {
  const productLists = [
    {
      text: "AI Text Editor",
      to: serverRoutes.editor,
    },
    {
      text: "Bulk Content Generator",
      to: serverRoutes.bulkContentCreation,
    },
    {
      text: "AutoBlogging",
      to: serverRoutes.autoblogging,
    },
    {
      text: "Youtube To Blog",
      to: serverRoutes.youtubeToBlog,
    },
    {
      text: "Page Rank Improver",
      to: serverRoutes.pageRankImprover,
    },
    {
      text: "Free AI Tools",
      to: serverRoutes.tools,
    },
    {
      text: "Pricing",
      to: serverRoutes.pricing,
    },
    {
      text: "Testimonials",
      to: serverRoutes.testimonials,
    },
  ].filter(Boolean);
  const resourcesList = [
    {
      text: "Guides",
      to: serverRoutes.docs,
    },
    {
      text: "Blogs",
      to: serverRoutes.blog,
    },
    {
      text: "Personalized Writing",
      to: serverRoutes.styleMatch,
    },
    {
      text: "Blog Post Generator",
      to: templateServerRouteBySlug("blog"),
    },
    {
      text: "Blog Images Generator",
      to: serverRoutes.blogImageGenerator,
    },
    {
      text: "AI Internal Linking",
      to: serverRoutes.aiInternalLinking,
    },
    {
      text: "AI Article Writer",
      to: serverRoutes.aiArticleWriter,
    },
    {
      text: "Multilingual Bulk Translate & Rewriter",
      to: serverRoutes.bulkTranslate,
    },
  ];

  const supportList = [
    {
      text: "About us",
      to: serverRoutes.about,
    },
    {
      text: "Terms",
      to: serverRoutes.terms,
    },
    {
      text: "Security",
      to: serverRoutes.security,
    },
    {
      text: "Cookie Policy",
      to: serverRoutes.cookiePolicy,
    },
    {
      text: "Privacy Policy",
      to: serverRoutes.privacyPolicy,
    },
    {
      text: "Junia Affiliate",
      to: serverRoutes.affiliate,
    },
    {
      text: "Contact us",
      to: serverRoutes.contact, // "mailto:contact@junia.ai", // serverRoutes.contact,
    },
  ];
  const integrationsList = [
    {
      text: "Shopify",
      to: serverRoutes.shopify,
    },
    {
      text: "Wordpress",
      to: serverRoutes.wordpress,
    },
    {
      text: "Webflow",
      to: serverRoutes.webflow,
    },
    {
      text: "Ghost",
      to: serverRoutes.ghost,
    },
    {
      text: "Medium",
      to: serverRoutes.medium,
    },
    {
      text: "Junia API",
      to: serverRoutes.customApi,
    },
  ];
  return { productLists, resourcesList, supportList, integrationsList };
};

const ListSection = ({ headerText, list }: ListSectionProps) => {
  return (
    <List
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      subheader={
        <ListSubHeader
          disableGutters
          sx={{
            background: "none",
          }}
        >
          <Typography variant="body1" fontWeight={600} color="text.white">
            {headerText}
          </Typography>
        </ListSubHeader>
      }
    >
      {list.map((item) => {
        return (
          <ListItem
            key={item.text}
            disableGutters
            className="with-hover-underline-effect-a"
            sx={{
              paddingTop: "4px",
              paddingBottom: "4px",
              letterSpacing: ".5px",
            }}
          >
            <Typography
              component={NextLink}
              sx={{
                textDecoration: "none",
                fontSize: "14px",
                fontWeight: 300,
              }}
              href={item.to}
              color="text.white"
            >
              {item.text}
            </Typography>
          </ListItem>
        );
      })}
    </List>
  );
};

const bottoms = [
  {
    text: "Modern Slavery Statement",
    to: serverRoutes.modernSlaveryStatement,
  },
  {
    text: "EDI Policy",
    to: serverRoutes.ediPolicy,
  },
  {
    text: "Accessibility",
    to: serverRoutes.accessibility,
  },
  {
    text: "Code Of Ethics",
    to: serverRoutes.codeOfEthics,
  },
  {
    text: "Editorial Policy",
    to: serverRoutes.editorialPolicy,
  },
  {
    text: "Complaints Policy",
    to: serverRoutes.complaintsPolicy,
  },
  {
    text: "Guest Posting Policy",
    to: serverRoutes.guestPosting,
  },
];

const FooterBottom = () => {
  return (
    <Box
      sameLine
      alignCenter
      marginTop={48}
      sx={{
        justifyContent: "center",
        columnGap: "48px",
      }}
    >
      <Typography color="text.white">@ 2024 Junia AI</Typography>
      <Box>
        <IconButton
          component="a"
          href={siteConfig.links.facebook}
          target="__blank"
          rel="noopener noreferrer nofollow"
          aria-label="Facebook"
          onClick={() => {
            trackClickOnSocialMediaLink({
              type: "facebook",
            });
          }}
        >
          <Avatar>
            <MetaColorIcon />
          </Avatar>
        </IconButton>

        <IconButton
          component="a"
          rel="noopener noreferrer nofollow"
          href={siteConfig.links.twitter}
          target="__blank"
          onClick={() => {
            trackClickOnSocialMediaLink({
              type: "twitter",
            });
          }}
          aria-label="Twitter"
        >
          <Avatar>
            <TwitterColorIcon />
          </Avatar>
        </IconButton>
        {/*
                  <IconButton
          component="a"
          href={siteConfig.links.linkedin}
          target="__blank"
        >
          <Avatar>
            <LinkedinColorIcon />
          </Avatar>
        </IconButton>
        <IconButton
          component="a"
          href={siteConfig.links.youtube}
          target="__blank"
        >
          <Avatar>
            <YoutubeColorIcon />
          </Avatar>
        </IconButton>
          */}
      </Box>
    </Box>
  );
};

const Footer = () => {
  const { productLists, resourcesList, supportList, integrationsList } =
    useData();
  return (
    <MainBackgroundWrapper component="footer">
      <Container
        maxWidth="xl"
        sx={{
          padding: {
            xs: "80px 30px",
            sm: "80px",
            md: "80px",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            rowGap: "24px",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexBasis: "15%",
              rowGap: "24px",
              width: "100%",
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "initial",
              },
            }}
          >
            <LogoIconOnly width={65} />
          </Box>

          <Grid
            component="section"
            container
            columnSpacing={2}
            rowSpacing={2}
            sx={{
              width: "100%",
            }}
          >
            <Grid xs={6} sm={6} md={6} lg={3}>
              <ListSection headerText={"Product"} list={productLists} />
            </Grid>
            <Grid xs={6} sm={6} md={6} lg={3}>
              <ListSection headerText={"Resources"} list={resourcesList} />
            </Grid>
            <Grid xs={6} sm={6} md={6} lg={3}>
              <ListSection headerText={"Company"} list={supportList} />
            </Grid>
            <Grid xs={6} sm={6} md={6} lg={3}>
              <ListSection
                headerText={"Integrations"}
                list={integrationsList}
              />
            </Grid>
          </Grid>
        </Box>
        <Affilate />
        <FooterBottom />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            margin: "12px auto",
            flexWrap: "wrap",
            rowGap: "12px",
          }}
        >
          {bottoms.map((item) => {
            return (
              <Typography
                component={NextLink}
                sx={{
                  textDecoration: "none",
                  fontSize: "12px",
                  fontWeight: 300,
                }}
                href={item.to}
                color="text.white"
              >
                {item.text}
              </Typography>
            );
          })}
        </Box>
        <VerificationPurpuses />
      </Container>
    </MainBackgroundWrapper>
  );
};

export default Footer;
