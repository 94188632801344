import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";
const createdTemplate = templateCreator({
  value: "terms and conditions",
  categories: [templateCategory.websiteCopy, templateCategory.ecommerce],
  defaultWordsCount: 800,
  titleName: "title"
});

export const termsAndConditionsTemplate = {
  ...createdTemplate,
  contentType: "terms and conditions",
  contentTypeLabel: "terms and conditions",
  helpText:
    "Provide an overview of your business, product, or service, and any specific clauses or conditions you want to include",
  cardDescription:
    "Generate comprehensive terms and conditions to protect your business, product, or service while ensuring transparency for users",
  shortDescription:
    "Create a detailed terms and conditions document based on the provided information",
  caption:
    "Generate a basic terms of service for your website or app. Please review and consult with legal professionals before using to ensure compliance with applicable laws and regulations.",
  excludedFields: [
    "writingStyle",
    "toneOfVoice",
    "editorialImage",
    "startFromOutline",
  ],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a title for your terms and conditions",
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;

        let prompt = `Generate a suitable title for a terms and conditions document based on the provided information:`;

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Details",
      fieldName: "description",
      description: " ",
      required: true,
      placeholder: `- [Overview of Business/Product/Service] Example: Junia AI is an AI content creation platform based in Helsinki, Finland, it operates based on monthly/yearly subscriptions and uses stripe for payment handling \n- [Specific Clauses or Conditions] Example: Using the platform to generate and spread disinformation is stricly prohibited`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;
        const title = post.title;
        let prompt =
          "Create a comprehensive terms and conditions document for a business, product, or service with the following information";

        if (description) {
          prompt += `. Details: ${description}`;
        }
        if (targetAudience) {
          prompt += `, should be suitable for the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
