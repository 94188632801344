import { InfoBaseItem } from "client-server-shared/types/types";
import { addMetaBuilder } from "./meta-builder";
import { TemplateType } from "./types";
import { isPlainObject } from "client-server-shared/utils/lodash-methods";

export const wrapTemplates = <T extends TemplateType>(templates: T[]) => {
  return templates.map((template) => {
    return {
      ...template,
      fields: {
        ...template.fields,
        ...addMetaBuilder(),
      },
    };
  });
};

export const populateInfoBaseItem = async (
  item: InfoBaseItem | undefined,
  getInfobaseContentBySourceIds: (ids: string[]) => Promise<string>
) => {
  let content = "";
  if (item && isPlainObject(item) && typeof item === "object") {
    if (item.sourceIds && item.sourceIds.length > 0) {
      content = await getInfobaseContentBySourceIds(item.sourceIds);
    }
    if (item.text) {
      content = `${content}\n${item.text}`;
    }
  }
  return content;
};
