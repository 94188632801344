import { templateCategory, TemplateFieldType, TemplateType } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

export const datingAppReplyer: TemplateType = {
  id: "dating-app-replyer-PmeQS",
  value: "dating-app-replyer-PmeQS",
  label: "dating-app-replyer",
  slug: "dating-app-replyer",
  categories: [templateCategory.socialMedia, templateCategory.entertainment],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "reply",
  title: "Dating App Message Reply",
  defaultWordsCount: 30,
  contentTypeLabel: "reply",
  helpText:
    "Provide the original message content and any additional context to generate a witty and engaging reply",
  defaultCollectionName: "Dating App Message Replies",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your reply",
  defaultPostName: "Untitled Dating App Reply",
  newStructure: true,
  tokenCount: 300,
  freeLimit: 3,
  toGeneratorPrompt: "Need to generate more dating app message replies?",
  cardDescription:
    "Generate engaging and witty replies to messages on dating apps.",
  shortDescription:
    "Craft a personalized and captivating response to messages on any dating app",
  caption: "Impress your matches with well-crafted and engaging responses",
  addtionalFields: [],
  excludedFields: ["keywords", "writingStyle"],
  fields: {
    pastConversations: {
      label: "Previous conversations with your match",
      optional: true,
      fieldName: "pastConversations",
      placeholder: `Example: 
- Them: His/Her message.
- Me: Your reply`,
      info: "The conversations can be copied and pasted from dating apps, whatsapp and more",
      description: " ",
      type: TemplateFieldType.TextArea,
      wordsLimit: 500,
    },
    description: {
      label: "Details",
      fieldName: "description",
      placeholder: `- 'I love sushi' \nSuggest a sushi date to the person`,
      description:
        "Enter the details of the original message you're responding to",
      required: true,
    },
    targetAudience: {
      label: "Details of your match",
      optional: true,
      fieldName: "targetAudience",
      placeholder: "Example: Their dating profile bio or their interests",
      description: " ",
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder:
        "Select a tone or let AI create one by uploading your past writing.",
      autoCompleteSuggestions: [
        "Direct",
        "Playful",
        "Flirtatious",
        "Casual",
        "Complementary",
        "Serious",
        "Witty",
      ].map((v) => ({ label: v, value: v })),
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 30,
      onInValid: () => {},
      buildSystemPrompt: (post, func, bg) => {
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;

        let prompt = `You're an expert in helping users create a suitable reply to a message on dating apps. You will be provided information about the message you're replying to by the user.`;
        if (bg) {
          prompt += `Here are some background information you may need to know: "${bg}".\n`;
        }
        if (toneOfVoice) {
          prompt += `You will write in following tone of voice: "${toneOfVoice}"\n`;
        }
        if (language && language !== "English") {
          prompt += `You will write in ${language} language.\n`;
        }
        prompt += `Your written message will be uploaded straight to the dating app. You will reply with the written message only without anything else in your response.`;
        return prompt;
      },
      buildPrompt: (post) => {
        const description = post.description;

        const targetAudience = post.targetAudience;

        const pastConversations = post.pastConversations;
        let prompt = "";
        if (targetAudience) {
          prompt += `Some information about my match: "${targetAudience}"\n`;
        }

        if (pastConversations) {
          prompt += ` Here are is the past conversations with my match:
${pastConversations}\n`;
        }

        if (description) {
          prompt += `. Details of the messages i am replying to and the purpose of the reply: ${description}`;
        }

        prompt += `.
        
Reply:`;

        return prompt;
      },
    },
  },
};
