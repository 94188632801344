import { templateCategory, TemplateType } from "../types";

import {
  isDescriptionDefined,
  isPartialContentDefined,
  isPurposeDefined,
  isTitleDefined,
} from "../validation";

export const productLauncherTemplate: TemplateType = {
  id: "product-launcher-251dy",
  value: "product-launcher-251dy",
  label: "product-launcher",
  slug: "product-launcher",
  categories: [
    templateCategory.ecommerce,
    templateCategory.marketing,
    templateCategory.socialMedia,
  ],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "product launcher post",
  title: "Product Launcher",
  defaultWordsCount: 300,
  contentTypeLabel: "product launcher post",
  helpText: "Choose at least one post topic to begin",
  defaultCollectionName: "Product Launcher collection",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your Product Launcher",
  defaultPostName: "Untitled Product Launcher",
  toGeneratorPrompt: "Need to generate more product launching posts?",
  cardDescription:
    "Craft a post that generates excitement and anticipation for the launch of a new product or service.",
  shortDescription: "All fields are <strong>optional</strong>",
  caption: "",
  addtionalFields: [],
  excludedFields: ["editorialImage", "startFromOutline", "writingStyle"],
  fields: {
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a post title",
      onValidate: (post) => {
        return !!(
          isDescriptionDefined(post) ||
          isPartialContentDefined(post) ||
          isPurposeDefined(post)
        );
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt =
          "Generate a compelling title for post that builds anticipation for an upcoming product/service";

        if (description) {
          prompt += `, with the description of: ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }
        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (content) {
          prompt += `. Here's some partial content from the post: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },

    description: {
      label: "Product/Service description",
      fieldName: "description",
      required: true,
      placeholder: `What does your product or service do, how it works, and what benefits it provides to the users?`,
      description: " ",
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Excitement and Enthusiasm, Urgency and Scarcity",
      autoCompleteSuggestions: [
        "Excitement and Enthusiasm",
        "Urgency and Scarcity",
        "Personal and Relatable",
        "Humorous and Playful",
      ].map((v) => ({ label: v, value: v })),
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!(isTitleDefined(post) || isDescriptionDefined(post));
      },
      calculateCost: (config) => config.wordsCount || 250,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const toneOfVoice = post.toneOfVoice;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;
        let prompt =
          "Create an engaging post that promotes an upcoming product/service based on the provided information";

        if (description) {
          prompt += `. product/service description: ${description}`;
        }

        if (toneOfVoice) {
          prompt += `, using a ${toneOfVoice} tone of voice`;
        }

        if (targetAudience) {
          prompt += `, resonates with the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += `. The post should be approximately ${wordsCount} words long`;
        }

        prompt +=
          ". Please ensure it builds excitement, anticipation and it's shareable";

        return prompt;
      },
    },
  },
};
