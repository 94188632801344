import { useSelector } from "hooks/use-selector";
import {
  selectIsUserAdmin,
  selectUserCreatedAt,
  selectUserHasActiveSubscription,
  selectUserId,
  selectUserInActiveProPlan,
  selectUserInActiveUnlimitedPlan,
  selectUserInAppSumoPlan,
  selectUserInAppSumoPlanTierFour,
  selectUserInAppSumoPlanTierOne,
  selectUserInAppSumoPlanTierThree,
  selectUserInAppSumoPlanTierTwo,
} from "selectors/user";
import { useUserFromShopify } from "./use-user";
import { withSpecialAccessToBulkGenerator } from "client-server-shared/constants";
import { dayjs } from "client-server-shared/utils/dayjs";

export const useUserHasFeatures = () => {
  const userInAppSumoTier1 = useSelector(selectUserInAppSumoPlanTierOne);
  const userInAppSumoTier2 = useSelector(selectUserInAppSumoPlanTierTwo);
  const createdAt = useSelector(selectUserCreatedAt);
  const userInAppSumoPlan = useSelector(selectUserInAppSumoPlan);
  const userInPaidPlan = useSelector(selectUserHasActiveSubscription);
  const userInProPlan = useSelector(selectUserInActiveProPlan);
  const userInUnlimitedPlan = useSelector(selectUserInActiveUnlimitedPlan);
  const hasAutoLinkingFeature = userInPaidPlan && !userInAppSumoTier1;
  const userId = useSelector(selectUserId);
  const hasIntegrationFeature = userInPaidPlan && !userInAppSumoTier1;
  const userInAppSumoPlanTierThree = useSelector(
    selectUserInAppSumoPlanTierThree
  );
  const userInAppSumoPlanTierFour = useSelector(
    selectUserInAppSumoPlanTierFour
  );
  const userFromShopify = useUserFromShopify();
  const userAdmin = useSelector(selectIsUserAdmin);
  const hasSecheduledPublishFeature = userInAppSumoPlan
    ? userInAppSumoPlanTierThree && userInAppSumoPlanTierFour
    : userInPaidPlan
    ? true
    : false;

  const canUseCustomAPI =
    userInAppSumoPlanTierThree ||
    userInAppSumoPlanTierFour ||
    userInProPlan ||
    userInUnlimitedPlan;

  const doesNotHasAutoInsertCompetitorKeywords =
    userInAppSumoTier1 || userInAppSumoTier2;

  const hasTeamFeature =
    userInProPlan ||
    userInUnlimitedPlan ||
    userInAppSumoPlanTierThree ||
    userInAppSumoPlanTierFour;
  const canSeeBulkGenerator =
    (!userFromShopify && !userInAppSumoPlan) ||
    withSpecialAccessToBulkGenerator(userId);

  const canUseBulkGenerator =
    userInProPlan ||
    userInUnlimitedPlan ||
    withSpecialAccessToBulkGenerator(userId);

  let canSeeArticleImprover = true;
  let canUseArticleImprover = true;
  if (userInUnlimitedPlan || userFromShopify) {
    canSeeArticleImprover = false;
  }
  if (!userInPaidPlan) {
    canUseArticleImprover = false;
  }
  let useNewSideNav = true;

  const canSeePageBooster = !userInUnlimitedPlan && !userFromShopify;

  const canUsePageBooster =
    userInProPlan || userInAppSumoPlanTierThree || userInAppSumoPlanTierFour;

  const canUseIndexer = userInPaidPlan;
  const canSeeIndexer = !userInUnlimitedPlan && !userFromShopify;

  const canSeeYoutubeToBlog = !userFromShopify;

  return {
    hasTeamFeature,
    hasAutoLinkingFeature,
    hasIntegrationFeature,
    hasSecheduledPublishFeature,
    doesNotHasAutoInsertCompetitorKeywords,
    canSeeBulkGenerator,
    canUseBulkGenerator,
    canUseArticleImprover,
    canSeeArticleImprover,
    canUseCustomAPI,
    canSeePageBooster,
    useNewSideNav,
    canUseIndexer,
    canSeeIndexer,
    canUsePageBooster,
    canSeeYoutubeToBlog,
  };
};
