import { dayjs } from "./utils/dayjs";

const blackFridayDate = "2024-12-01T17:31:47.983Z";
export const blackFridayOneWeekAfter = dayjs(blackFridayDate)
  .add(6, "day")
  .toISOString();

export const isBlackFridayBannerActive = () => {
  return !dayjs().isAfter(blackFridayOneWeekAfter);
};

const christmasDate = "2024-12-09T17:31:47.983Z";
export const christmasOneWeekAfter = dayjs(christmasDate)
  .add(6, "day")
  .toISOString();

export const isChristmasBannerActive = () => {
  return !dayjs().isAfter(christmasOneWeekAfter);
};
