import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Description, Typography } from "components/ui/Typography";
import { PrimaryInput } from "components/input";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Section, SectionTitle } from "modules/selections/common";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

import { useCloseSelectedPopup } from "context/popup-context";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { PurplePinkButton } from "components/purple-button";
import SchedulePublish from "../schedule-publish";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";
import { useUserHasFeatures } from "hooks/use-features";
import { InlineLinkSpan, InlineSpan } from "components/as-inline-span";
import { IconComponent } from "components/icon-component";
import { useSelector } from "hooks/use-selector";
import {
  selectActiveContentByCollectionAndPostId,
  selectPostByCollectionIdAndPostId,
} from "selectors/collections";
import { slugify } from "client-server-shared/utils/text-utils";
import { getH1 } from "utils/get-h1";
import { extractUrlsFromString } from "client-server-shared/utils/get-url-from-string";
import { docRouteBySlug } from "client-server-shared/config/routes";
const APIPublishModal = ({ onSave, singleArticle, postId, collectionId }) => {
  const onClose = useCloseSelectedPopup();
  const post = useSelector((state) =>
    selectPostByCollectionIdAndPostId(state, collectionId, postId)
  );
  const [baseURL, setBaseURL] = React.useState("");
  const { onUpgrade } = useClickUpgrade({
    source: SourceType.ShopifyIntegrationModal,
  });
  const { canUseCustomAPI } = useUserHasFeatures();
  const [schedulePublishConfig, setSchedulePublishConfig] = React.useState({
    enabled: false,
    amount: singleArticle ? 1 : 5,
  });
  const content = useSelector((state) =>
    selectActiveContentByCollectionAndPostId(state, collectionId, postId)
  );

  const isBaseURLValid = extractUrlsFromString(baseURL).length > 0;

  const slug = post?.slug || slugify(getH1(content));
  const baseURLRequired = schedulePublishConfig.autoIndex;
  const canSubmit = !(baseURLRequired && !isBaseURLValid);
  return (
    <Dialog fullWidth maxWidth={"sm"} open onClose={onClose}>
      <DialogTitle
        sx={{
          padding: "24 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: "18px",
                marginRight: "24px",
              }}
            >
              Publish To Junia API
            </Typography>
            <Description
              sx={{
                marginTop: "6px",
                maxWidth: "400px",
              }}
            >
              <InlineSpan inherit>
                This feature allows you to fetch articles from Junia and make
                them available to your own websites.{" "}
              </InlineSpan>
              <InlineLinkSpan
                inherit
                href={docRouteBySlug("fetch-articles-from-junia-ai-api")}
                innerProps={{
                  target: "_blank",
                }}
              >
                View the API documentation here
              </InlineLinkSpan>
            </Description>
            {canUseCustomAPI ? null : (
              <Description
                sx={{
                  marginTop: "6px",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "4px",
                  fontWeight: 600,
                  color: "#F44335",
                }}
              >
                <IconComponent
                  icon={LockOutlinedIcon}
                  sx={{
                    height: "15px",
                    width: "15px",
                  }}
                />
                <InlineSpan inherit>
                  <InlineSpan
                    inherit
                    onClick={() => {
                      onUpgrade();
                    }}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Please upgrade
                  </InlineSpan>{" "}
                  to a higher plan to use this feature.
                </InlineSpan>
              </Description>
            )}
          </Box>
          <IconButton
            aria-label={"Close"}
            onClick={onClose}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <SchedulePublish
          singleArticle={singleArticle}
          schedulePublishConfig={schedulePublishConfig}
          onChange={setSchedulePublishConfig}
        />
        <Section>
          <SectionTitle
            optional
            description={`Only required if "Submit To Indexer" is enabled. This should be the base URL of the article in your website, examples: https://www.example.com/articles or https://example.com/blog`}
          >
            Base Article URL
          </SectionTitle>
          <PrimaryInput
            size="small"
            value={baseURL}
            onChange={(e) => {
              setBaseURL(e.target.value);
            }}
            helperText={
              baseURLRequired && !isBaseURLValid
                ? "Please enter a base URL so that we can submit the correct URL to the indexer"
                : undefined
            }
            error={baseURLRequired && !isBaseURLValid}
          />
        </Section>
        {baseURLRequired && isBaseURLValid ? (
          singleArticle ? (
            <Description
              sx={{
                marginBottom: "12px",
              }}
            >
              We will submit the URL {`${baseURL}/${slug}`} to the indexer to
              notify Search Engines to index the article.
            </Description>
          ) : null
        ) : null}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PurplePinkButton
            disabled={!canUseCustomAPI || !canSubmit}
            onClick={async () => {
              onSave({
                schedulePublishConfig,
                baseURL,
              });
              onClose();
            }}
          >
            {singleArticle ? "Publish" : "Save"}
          </PurplePinkButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default APIPublishModal;
