import { slugify } from "client-server-shared/utils/text-utils";
import capitalize from "lodash/capitalize";
import startCase from "lodash/startCase";
import pluralize from "pluralize";

// contentType
// contentTypeLabel
// helpText
// cardDescription
// shortDescription
// caption
// addtionalFields
// excludedFields

export interface CreateOptions {
  /** review responder */
  value: string;
  categories: string[];
  defaultWordsCount: number;
  /** subject line */
  titleName?: string;
  label?: string;
}

export const templateCreator = ({
  value,
  label,
  categories,
  defaultWordsCount,
  titleName = "title",
}: CreateOptions) => {
  return {
    id: `${slugify(value)}-PmeQS`,
    value: `${slugify(value)}`,
    label: `${startCase(label || value)}`,
    slug: `${slugify(value)}`,
    categories: categories,
    tags: [],
    contentType: "content",
    title: `${startCase(value)}`,
    defaultWordsCount: defaultWordsCount || 400,
    defaultCollectionName: `${pluralize(capitalize(value), 2)}`,
    collectionNamePlaceholder: "Enter a name for your collection",
    postNamePlaceholder: `Enter a ${titleName} for your ${value}`,
    defaultPostName: `Untitled ${startCase(label || value)}`,
    fields: {
      toneOfVoice: {
        label: "Tone of voice",
        fieldName: "toneOfVoice",
        optional: true,
        placeholder: "Friendly, formal",
      },
      writingStyle: {
        label: "Writing Style",
        fieldName: "writingStyle",
        optional: true,
        placeholder: "Eg: Informative, conversational",
      },
    },
  };
};
