import { Box, BoxProps } from "components/ui/box";
import { Description, Typography } from "components/ui/Typography";
import React from "react";

import { useUser } from "hooks/use-user";
import { Interval, PlanType, PlanName } from "client-server-shared/pricing/v2";
import { useCheckout } from "hooks/use-checkout";
import {
  dashboardRouteConfig,
  serverRoutes,
} from "client-server-shared/config/routes";
import {
  ButtonWithLoading,
  OutlineGreyButton,
} from "components/ui/button/button-with-loading";
import { SourceType } from "utils/tracking/type";
import { trackClickOnPricing } from "utils/tracking/payment";
import { Tooltip } from "components/ui/tooltip";
import { useRouter } from "next/router";
import { InlineSpan } from "components/as-inline-span";
import { useSelector } from "hooks/use-selector";
import { selectUserCanHavePaidTrial } from "selectors/user";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { IconComponent } from "components/icon-component";
import { Divider } from "components/ui/divider";
import Slider from "components/ui/form/slider";
import { useShortId } from "hooks/use-short-id";
import { usePricingContext } from "./context";

interface PricingContextValue {
  selectedInterval: Interval;
  onIntervalChange: (interval: Interval) => void;
}

const marks = [
  {
    value: 0,
  },
  {
    value: 35,
  },
  {
    value: 70,
  },
  {
    value: 100,
  },
];

const BlueCheckMark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="9"
      style={{
        minWidth: "11px",
        minHeight: "9px",
      }}
      viewBox="0 0 11 9"
      fill="none"
    >
      <path
        d="M1.80762 5.13796C2.68945 5.13796 5.49705 10.4007 5.90351 6.94581C6.19125 4.50008 7.66067 1.98321 9.94291 1.07031"
        stroke="#1976D2"
        strokeWidth="1.65687"
        strokeLinecap="round"
      />
    </svg>
  );
};

interface FeatureListItemProps {
  description: string;
  disabled?: boolean;
  learnMoreQuery?: string;
  info?: string;
  fontRatio?: number;
}

export const FeatureListItem = ({
  description,
  disabled,
  info,
  learnMoreQuery,
  isFreeTiralCard,
  isPro,
  isAdvancedPlan,
  fontRatio = 1,
}: FeatureListItemProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        columnGap: "24px",
      }}
    >
      <BlueCheckMark />
      {
        <Box
          sx={{
            width: "100%",
            display: "flex",
            columnGap: "2px",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Tooltip title={info ? info : ""}>
            <Typography
              color={"text.black"}
              variant="body2"
              sx={{
                fontSize: `${14 * fontRatio}px`,
                fontWeight: 400,
              }}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </Tooltip>
          {info && (
            <Tooltip title={info ? info : ""}>
              <IconComponent
                icon={InfoOutlinedIcon}
                sx={{
                  height: "14px",
                  width: "14px",
                }}
              />
            </Tooltip>
          )}
        </Box>
      }
    </Box>
  );
};

export const PricingCard = ({
  planName,
  description,
  priceIds,
  currency = "USD",
  unit_amount,
  features,
  page,
  label,
  info,
  sx = {},
}: PlanType & {
  sx?: BoxProps;
  page:
    | SourceType.BillingPage
    | SourceType.PricingPage
    | SourceType.UpgradePopup;
}) => {
  const user = useUser();
  const isFreeTiralCard = planName === PlanName.free;
  const isPro = planName === PlanName.creator;
  const isAdvancedPlan = planName === PlanName.pro;
  const { handleCheckout, isLoading, handleStartingFreeTrial } = useCheckout();
  const priceString = new Intl.NumberFormat("en-US", {
    currency: currency,
    minimumFractionDigits: 0,
  }).format((unit_amount || 0) / 100);
  const router = useRouter();

  const { customValue, setCustomValue } = usePricingContext();

  const currencyLabel = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  })
    .format(0)
    .replace(/[0-9.,\s]/g, "");
  const inUpgradePopup = page === SourceType.UpgradePopup;
  const userCanHavePaidTrial = useSelector(selectUserCanHavePaidTrial);
  const usePaidTrail = userCanHavePaidTrial && planName === PlanName.creator;
  const fontRatio = inUpgradePopup ? 0.8 : 1;
  const sliderTitleId = useShortId();
  const sliderId = useShortId();

  if (isFreeTiralCard && user) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexBasis: {
          xs: "100%",
          sm: "100%",
          md: "40%",
          lg: "30%",
          xl: "30%",
        },
        borderRadius: "12px",
        position: "relative",
        background: "#FFFFFF",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "24px 24px 12px 24px",
        }}
      >
        {planName === PlanName.pro && (
          <Box
            sx={{
              position: "absolute",
              background: "rgba(184, 95, 215, 1)",
              padding: "3px 12px",
              top: "16px",
              right: "16px",
              borderRadius: "4px",
              marginLeft: "10px",
            }}
          >
            <Typography
              fontSize={12 * fontRatio}
              fontWeight={600}
              sx={{
                whiteSpace: "nowrap",
              }}
              variant="body2"
              color="text.white"
            >
              {"Popular"}
            </Typography>
          </Box>
        )}
        <Box padding={10}>
          <Box
            sameLine
            alignCenter
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h4"
              fontWeight={500}
              sx={{
                fontSize: `${24 * fontRatio}px`,
              }}
              color={"text.black"}
            >
              {label || planName}
            </Typography>
          </Box>
          <Typography
            variant="caption2"
            color={"#9C9C9E"}
            sx={{
              display: "flex",
              fontSize: `${14 * (inUpgradePopup ? 0.9 : 1)}px`,
              maxWidth: "330px",
            }}
          >
            {description ? description : ""}
          </Typography>

          <Typography
            fontWeight={400}
            variant="h4"
            textAlign={"center"}
            color="text.white"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <InlineSpan
              sx={{
                fontSize: `${48 * (inUpgradePopup ? 0.7 : 1)}px`,
                color: "#C0CBD9",
              }}
            >
              {currencyLabel}
            </InlineSpan>
            <InlineSpan
              sx={{
                fontSize: `${48 * (inUpgradePopup ? 0.7 : 1)}px`,
                color: "#000000",
              }}
            >
              {priceString}
            </InlineSpan>
            <InlineSpan
              sx={{
                color: "#9C9C9E",
                fontSize: `${16 * fontRatio}px`,
                marginLeft: "6px",
              }}
            >
              {"/month"}
            </InlineSpan>
          </Typography>

          {/*
        {!isFreeTiralCard && (
          <Typography
            fontWeight={400}
            variant="body2"
            textAlign={"center"}
            color="text.black"
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
            margin="10px 10px"
          >
            {wordsPerMonth} words/month
          </Typography>
        )}
            */}
          <ButtonWithLoading
            variant="contained"
            onClick={() => {
              trackClickOnPricing({
                source: page,
                register: !!user,
              });
              if (isFreeTiralCard) {
                handleStartingFreeTrial();
              } else {
                handleCheckout(priceIds[0], {
                  paidTrial: usePaidTrail,
                });
              }
            }}
            loading={isLoading}
            capitalize
            sx={{
              width: "100%",
              display: "flex",
              margin: "auto",
              borderRadius: "4px",
              boxShadow: "none",
              background: "#111827",
              color: "text.white",
              "&:hover": {
                background: "#111827",
                opacity: 0.8,
              },
            }}
          >
            {user && !isFreeTiralCard
              ? usePaidTrail
                ? "Start for $2,99"
                : "Upgrade Now"
              : "Get started"}
          </ButtonWithLoading>

          <Box
            sx={{
              marginTop: "24px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              marginBottom={14}
              color={"text.black"}
              variant="body2"
              sx={{
                fontWeight: 600,
              }}
            >
              {isFreeTiralCard
                ? "What's included?"
                : isPro
                ? !!user
                  ? "What's included?"
                  : "Everything in Free, plus:"
                : "Everything in Creator, plus:"}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "12px",
              }}
            >
              {features
                .filter((feature) => {
                  if (feature.loginedOnly && !user) {
                    return false;
                  }
                  return true;
                })
                .map((feature) => {
                  return (
                    <FeatureListItem
                      fontRatio={inUpgradePopup ? 0.9 : 1}
                      key={feature.description}
                      description={feature.description}
                      info={feature.info}
                      isFreeTiralCard={isFreeTiralCard}
                      isPro={isPro}
                      isAdvancedPlan={isAdvancedPlan}
                      disabled={feature.disabled}
                      learnMoreQuery={feature.learnMoreQuery}
                    />
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Box>
      {isAdvancedPlan && (
        <Box
          sx={{
            padding: "0 24px 12px 24px",

            "& .MuiSlider-thumb": {
              height: "16px !important",
              width: "16px !important",
            },
            "& .MuiSlider-track": {
              backgroundColor: "rgba(184, 95, 215, 1)",
            },
          }}
        >
          <Divider
            sx={{
              marginBottom: "12px",
            }}
          />
          <Typography
            id={sliderTitleId}
            marginBottom={14}
            color={"rgba(0, 0, 0, 0.6)"}
            variant="body2"
            sx={{
              fontWeight: 600,
              marginBottom: "12px",
            }}
          >
            CUSTOMIZE YOUR PLAN
          </Typography>
          <Slider
            id={sliderId}
            aria-labelledby={sliderTitleId}
            step={null}
            marks={marks}
            value={customValue}
            onChange={(event, newValue) => {
              setCustomValue(newValue as number);
            }}
            sx={{
              color: "rgba(0, 0, 0, 0.3)",
              height: 4,
            }}
            valueLabelDisplay="off"
            aria-label="Customize plan slider"
          />
        </Box>
      )}
      <OutlineGreyButton
        onClick={() => {
          const element = document.getElementById("pricing-table");
          if (element) {
            setTimeout(() => {
              element.scrollIntoView({ behavior: "smooth" });
            }, 100);
          } else {
            if (user) {
              window.open(
                `${dashboardRouteConfig.billing}#pricing-table`,
                "_blank"
              );
            }
          }
        }}
        sx={{
          marginTop: "auto",
          border: 0,
          padding: "24px 0",
          color: "text.black",
          background: "rgb(237 241 244)",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          borderBottomLeftRadius: "12px",
          borderBottomRightRadius: "12px",
          "&:hover": {
            background: "rgb(237 241 244)",
            border: 0,
            opacity: 0.8,
          },
        }}
      >
        {"See all features"}
      </OutlineGreyButton>
    </Box>
  );
};
