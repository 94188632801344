import { TemplateFieldType, TemplateType, templateCategory } from "../types";

import { templateCreator } from "../template-builder";
import { populateInfoBaseItem } from "../util";
import { isPlainObject } from "client-server-shared/utils/lodash-methods";
import { GPT35 } from "client-server-shared/ai-config";

const createdTemplate = templateCreator({
  value: "unique content ideas",
  categories: [
    templateCategory.all,
    templateCategory.blog,
    templateCategory.custom,
    templateCategory.socialMedia,
  ],
  defaultWordsCount: 400,
  titleName: "title",
});

export const uniqueContentIdeaTemplate: TemplateType = {
  ...createdTemplate,
  contentType: "unique content ideas",
  contentTypeLabel: "unique content ideas",
  cardDescription:
    "Generate unique ideas for blog posts & social media posts that stand out from the competition.",
  shortDescription:
    "Generate unique ideas for blog posts & social media posts that stand out from the competition.",
  caption:
    "Generate unique ideas for blog posts & social media posts that stand out from the competition.",
  newStructure: true,
  tokenCount: 1000,
  freeLimit: 3,
  howToPath: "/docs/how-to-use-unique-content-ideas-generator",
  excludedFields: [],
  addtionalFields: [],
  fields: {
    samples: {
      label: "Inspiration Source",
      required: true,
      fieldName: "samples",
      description: "Input diverse sources for fresh ideas.",
      info: "It could be anything, from Twitter post comments to TikTok video comments or any relevant background information, a story, or a personal experience.",
      placeholder: `E.g., Copy and paste Twitter posts, comments from forums or any other social media platforms, or any other text.`,
      type: TemplateFieldType.InfoBase,
    },
    additionalInstructions: {
      label: "Additional Instructions",
      optional: true,
      fieldName: "additionalInstructions",
      description: " ",
      placeholder: `Enter any additional instructions for the ideas. For example: Focus on the topic of AI.`,
      type: TemplateFieldType.TextArea,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        if (
          post.samples &&
          isPlainObject(post.samples) &&
          typeof post.samples !== "string"
        ) {
          return !!(
            (post.samples.text && post.samples.text.length > 0) ||
            (post.samples.sourceIds && post.samples.sourceIds.length > 0)
          );
        }
        return false;
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: () => {},
      getAIConfig: (config = {}) => {
        return {
          model: GPT35,
          temperature: 1,
        };
      },
      buildSystemPrompt: async (post) => {
        const language = post.language;

        let prompt = `As an expert writer skilled in creating highly detailed and in-depth content, you understand the importance of certain criteria for ranking on Google.

1. Incorporating relevant NLP keywords that match user search intent and new talking points. Avoid keyword stuffing.
2. Creating unique content. With many similar articles on the same topic, your content needs to stand out. You need to provide fresh perspectives, viewpoints & insights not covered by other articles. You need to include new talking points not mentioned elsewhere. You need to ensure comprehensive coverage of the topic without being overly lengthy or boring.

You will be given content taken from various sources (like Twitter posts, comments, other social media platforms, or any other platform where people share their unique insights and perspectives). Your task is to use these unique insights wisely to generate truly unique content ideas.

You will reply in the following markdown format:

## [Idea here]

**Talking Points:** [Talking points here]
**Reasoning:** [Reasoning here]

more...
        `;

        if (language && language !== "English") {
          prompt += `You will reply in ${language} language.\n`;
        }

        return prompt;
      },
      buildPrompt: async (post, getInfobaseContentBySourceIds) => {
        const samples = post.samples;
        const additionalInstructions = post.additionalInstructions;

        let sampleContent = await populateInfoBaseItem(
          samples,
          getInfobaseContentBySourceIds
        );
        const prompt = `Based on the provided content below from various sources, come up with 10 unique content ideas, each idea should include relevant talking points and keywords that makes it stand out. Also provide reasoning for why each idea will stand out from the competition. ${
          additionalInstructions || ""
        }

Content: "${sampleContent}"`;
        return prompt;
      },
    },
  },
};
