import React from "react";
import { styled } from "@mui/material/styles";

import { TextField, TextFieldProps } from "./ui/form";
import { Typography } from "./ui/Typography";
import { countWordsPlain } from "client-server-shared/utils/countWords";

export const inputStyle = {
  "& .MuiFormHelperText-root": {
    marginLeft: 0,
    color: "rgb(244, 67, 53)",
  },
  "& .MuiInputBase-root": {
    borderRadius: "8px",
    fontSize: "12px",
    color: "#0a1440",
    boxShadow:
      "rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(169, 201, 199, 0.15) 0px 3px 8px 0px",
    "& fieldset": {
      border: "1px solid rgb(229 231 235)",
    },
    "&:hover fieldset": {
      border: "1px solid rgb(180 182 187) !important",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid rgb(180 182 187) !important",
    },
  },
};

const noHoverBorderInputStyle = {
  "& .MuiInputBase-root": {
    "&:hover fieldset": {
      border: "1px solid rgb(229 231 235) !important",
    },
    "&.Mui-focused fieldset": {
      border: "1px solid rgb(229 231 235) !important",
    },
  },
};

const StyledTextField = styled(TextField)((props) => {
  return {
    ...inputStyle,
  };
});
const emptyObject = {};
export const PrimaryInput = React.memo(
  React.forwardRef(
    (
      props: TextFieldProps & {
        noHoverBorder?: boolean;
        wordsLimit?: number;
      },
      ref
    ) => {
      const { sx = {}, wordsLimit, noHoverBorder, ...rest } = props;
      const overrideStyle = sx;

      return (
        <>
          <StyledTextField
            {...rest}
            ref={ref}
            sx={{
              ...(noHoverBorder ? noHoverBorderInputStyle : emptyObject),
              ...sx,
            }}
          />
          {typeof wordsLimit === "number" ? (
            <Typography
              variant="caption"
              sx={{
                marginTop: "6px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {`${countWordsPlain(
                typeof rest.value === "string" && rest.value ? rest.value : ""
              )}/${wordsLimit} words`}
            </Typography>
          ) : null}
        </>
      );
    }
  )
);
