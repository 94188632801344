import onGenerateMeta from "redux-middlewares/generate-meta";

import { Post } from "client-server-shared/types/types";
import { useAppDispatch } from "./use-app-dispatch";
import { useFieldLoadingStatus } from "./use-generate-fields";
import { useNotifications } from "./use-notification";
import { selectActiveContentByCollectionAndPostId } from "selectors/collections";
import { useSelector } from "./use-selector";
import { strippedHtml } from "client-server-shared/utils/text-utils";

export const useGenerateMetaDescription = ({ collectionId, postId }) => {
  const postContent = useSelector((state) =>
    selectActiveContentByCollectionAndPostId(state, collectionId, postId)
  );

  const notificationApi = useNotifications();
  const dispatch = useAppDispatch();
  const { isLoading } = useFieldLoadingStatus(postId, "metaDescription");

  const onGeneratePostField = () => {
    if (isLoading) {
      return;
    }
    const valid = postContent && strippedHtml(postContent).length > 0;
    if (!valid) {
      notificationApi.addInfoNotification("Please generate some content first");

      return;
    }

    dispatch(onGenerateMeta(collectionId, postId, "metaDescription"));
  };

  return { onGeneratePostField, isLoading };
};

export const useGenerateMetaTitle = ({ collectionId, postId }) => {
  const postContent = useSelector((state) =>
    selectActiveContentByCollectionAndPostId(state, collectionId, postId)
  );

  const notificationApi = useNotifications();
  const dispatch = useAppDispatch();
  const { isLoading } = useFieldLoadingStatus(postId, "metaTitle");

  const onGeneratePostField = () => {
    if (isLoading) {
      return;
    }
    const valid = postContent && strippedHtml(postContent).length > 0;
    if (!valid) {
      notificationApi.addInfoNotification("Please generate some content first");

      return;
    }

    dispatch(onGenerateMeta(collectionId, postId, "metaTitle"));
  };

  return { onGeneratePostField, isLoading };
};
