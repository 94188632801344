import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";

const createdTemplate = templateCreator({
  value: "faq section",
  categories: [templateCategory.websiteCopy],
  defaultWordsCount: 1500,
  titleName: "title",
});

export const faqSectionTemplate = {
  ...createdTemplate,
  contentType: "faq section",
  contentTypeLabel: "faq section",
  helpText:
    "Provide the topics or questions you want to cover in the FAQ section, and the generator will create relevant questions and answers",

  cardDescription:
    "Generate comprehensive and engaging FAQ sections for your website, product, or service to address common questions and concerns",
  shortDescription:
    "Create an informative FAQ section based on the provided topics or questions",
  caption:
    "Help your users find the answers they need with a well-structured and easy-to-navigate FAQ section",
  excludedFields: ["toneOfVoice", "editorialImage", "startFromOutline"],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    writingStyle: {
      label: "Format",
      fieldName: "writingStyle",
      description: " ",
      optional: true,
      placeholder: "Plain text or JSON",
    },
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: `Enter or generate a title for your faq section`,
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;

        let prompt = `Generate a suitable title for a faq section based on the provided information:`;

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "What's the FAQ about?",
      fieldName: "description",
      description: " ",
      required: true,
      placeholder: `- [Description] Example: Junia AI's free blog post generator can create original blog post in seconds\n- [Additional Details] Example: Include some tips and tricks on using the generator and include the JSON-LD data`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const writingStyle = post.writingStyle;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;
        const title = post.title;

        let prompt =
          "Generate an FAQ section for a website, product, or service based on the following information";

        if (description) {
          prompt += `. Details: ${description}`;
        }
        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words`;
        }
        if (writingStyle) {
          prompt += `, reply in ${writingStyle} format`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
