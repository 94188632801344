export const toneOfVoiceSuggestions = [
  {
    label: "Friendly",
    value: "Friendly",
  },
  {
    label: "Professional",
    value: "Professional",
  },
  {
    label: "Bold",
    value: "Bold",
  },
  {
    label: "Serious",
    value: "Serious",
  },
  {
    label: "Humorous",
    value: "Humorous",
  },
  {
    label: "Casual",
    value: "Casual",
  },
  {
    label: "Conversational",
    value: "Conversational",
  },
  {
    label: "Educational",
    value: "Educational",
  },
  {
    label: "Witty",
    value: "Witty",
  },
  {
    label: "Persuasive",
    value: "Persuasive",
  },
  {
    label: "Empathetic",
    value: "Empathetic",
  },
  ...[
    "Excitement and Enthusiasm",
    "Urgency and Scarcity",
    "Personal and Relatable",
    "Humorous and Playful",
  ].map((v) => ({ label: v, value: v })),
];

export const keywordsField = {
  keywords: {
    label: "Keywords",
    fieldName: "keywords",
    optional: true,
    placeholder: "Type relevant keywords and press enter",
  },
};
