import React from "react";
import { IconButton, IconButtonProps } from "./ui/button";
import { BoxProps } from "./ui/box";

export const IconComponent = React.forwardRef(
  ({ icon, size = "xs", ...rest }, ref) => {
    const Icon = icon;
    let effectiveSize = "14px";
    if (size === "xs") {
      effectiveSize = "14px";
    } else if (size === "sm") {
      effectiveSize = "17px";
    } else if (size === "md") {
      effectiveSize = "17px";
    } else if (size === "lg") {
      effectiveSize = "20px";
    } else if (size === "xl") {
      effectiveSize = "24px";
    }
    return (
      <Icon
        ref={ref}
        {...rest}
        sx={{
          height: "14px",
          width: "14px",
          minWidth: rest.sx?.width || effectiveSize,
          minHeight: rest.sx?.height || effectiveSize,
          fill: "rgba(0, 0, 0, 0.5)",
          ...(rest.sx || {}),
        }}
      />
    );
  }
);

/*

export const IconButtonInGrayBox = React.forwardRef(
  (
    {
      icon,
      iconProps = {},
      ...rest
    }: IconButtonProps & { iconProps?: BoxProps },
    ref
  ) => {
    return (
      <IconButton {...rest}>
        <IconComponent
          icon={icon}
          {...iconProps}
          sx={{
            ...(iconProps.sx || {}),
          }}
        />
      </IconButton>
    );
  }
);
*/
