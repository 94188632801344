import React from "react";
import { Dialog, DialogContent, DialogTitle } from "../ui/dialog";
import { Typography } from "../ui/Typography";
import { Box } from "../ui/box";
import { useCloseSelectedPopup } from "context/popup-context";
import { OutlineGreyButton } from "../ui/button/button-with-loading";
import { PurplePinkButton } from "../purple-button";
import { useUserFromShopify } from "hooks/use-user";
import { IconButton } from "components/ui/button";
import CloseIcon from "@mui/icons-material/Close";

const LimitReachModal = ({
  action,
  actionText = "Yes, sure",
  description1,
  description2,
  slot = {},
}: {
  description1: string;
  description2?: string;
  action: () => void | null;
  actionText?: string;
  slot?: {
    leftAction?: React.ReactNode;
  };
}) => {
  const onClosePopup = useCloseSelectedPopup();
  const fromShopify = useUserFromShopify();
  if (fromShopify) {
    return (
      <Dialog
        fullWidth
        maxWidth="xs"
        PaperProps={{
          sx: {
            borderRadius: "10px",
            padding: "12px 0",
          },
        }}
        open
        onClose={onClosePopup}
      >
        <DialogTitle
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontSize: "18px",
              marginRight: "24px",
            }}
          >
            Hi friend 👋
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              marginBottom: "12px",
            }}
          >
            You have used up all your credits for this month. Great work!
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              marginBottom: "24px",
            }}
          >
            You will be granted more credits on the 1st of the next month. We
            appreciate your support!
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "12px",
              marginTop: "24px",
            }}
          >
            <PurplePinkButton
              onClick={() => {
                onClosePopup();
              }}
              sx={{}}
            >
              Yes, sure
            </PurplePinkButton>
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      PaperProps={{
        sx: {
          borderRadius: "10px",
          padding: "12px 0",
        },
      }}
      open
      onClose={onClosePopup}
    >
      <IconButton
        aria-label={"Close"}
        onClick={onClosePopup}
        sx={{
          marginLeft: "auto",
          position: "absolute",
          right: "12px",
          top: "6px",
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          marginTop: "6px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: "18px",
            marginRight: "24px",
          }}
        >
          Hi friend 👋
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body2"
          sx={{
            fontSize: "14px",
            marginBottom: "12px",
          }}
        >
          {description1}
        </Typography>
        {description2 && (
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              marginBottom: "24px",
            }}
          >
            {description2}
          </Typography>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            columnGap: "12px",
            marginTop: "24px",
          }}
        >
          {slot?.leftAction}
          <PurplePinkButton
            onClick={() => {
              action();
            }}
            sx={{}}
          >
            {actionText}
          </PurplePinkButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LimitReachModal;
