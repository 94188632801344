
export const cnUrl = "https://www.heyjunia.cn";
export const enUrl = "https://www.junia.ai";

const siteConfig = {
  copyright: "YZplanet oy",
  title: "Junia AI - #1 AI Writer for SEO & Content Generation",
  description:
    "Junia AI is the best AI writer for SEO and on-brand long-form content! Generate in-depth, plagiarism-free content that ranks on Google. Ready to win the SEO game?",
  email: "contact@junia.ai",
  logo: {
    url: "/logo.svg",
    alt: "Logo of a robot cat | Junia.ai",
  },
  whiteLogo: {
    url: "/logo-white.png",
    alt: "Logo of a robot cat | Junia.ai",
  },
  logoIcon: {
    url: "/logo-icon-only.png",
    alt: "Logo of a robot cat | Junia.ai",
  },
  logoWithBlackText: {
    url: "/logo-with-black-text.svg",
    alt: "Logo of a robot cat | Junia.ai",
  },
  favicon: "/favicon.png",
  openGraphImage: "/og.png",
  url: enUrl,
  locale: "en",
  name: "Junia AI",
  links: {
    twitter: "https://twitter.com/junia_ai",
    facebook: "https://www.facebook.com/profile.php?id=100091403777685",
    youtube: "https://youtube.com/junia.ai",
    linkedin: "https://linkedin.com/junia.ai",
  },
  affiliate_signup: "https://junia-ai.getrewardful.com/signup",
};


export { siteConfig };
