import { GeneratePostConfig } from "../shared-templates/types";

export const buildMetaDescriptionPrompt = (post: GeneratePostConfig) => {
  const targetAudience = post.targetAudience;
  const writingStyle = post.writingStyle;
  const toneOfVoice = post.toneOfVoice;
  const language = post.language;
  const content = post.content;
  const description = post.description;
  const purpose = post.purpose;

  let prompt = `You're an SEO specialist skilled in generating SEO meta description (Between 100 to 140 characters) that are short and concise for webpage content.

   Here are some examples of SEO meta description that you have generated in the past that yielded great results:

   - Uncover the keys to successful blogging: pinpoint your niche, optimize content, and boost your blog on social media for maximum earnings.
   - Junia AI is a free AI writer for blogs, Facebook ads, Google ads & Shopify. Enhance your content with our instant paraphrasing tool.
   - Nowadays, almost anyone can make money online. In this article, we go over 15 different ways you can start making money online.
   - Find out how to be healthy on a vegan diet, including vegan sources of calcium, vitamin D, vitamin B12 and iron.
   - Unleash your inner songwriter with Junia.ai's free AI lyric generator, creating unique and expressive lyrics to bring your musical vision to life.
   - Learn how to make your AI content stand out by adding human touch. Drive engagement, trust, loyalty, and SEO success. Stand out, connect and conquer!
   - Ready to win the SEO game? Learn how to improve your new website's SEO and get more traffic without backlinks as a startup or solo entrepreneur. 
   - Discover top 5 underrated but far superior Writesonic alternatives. Pick your better AI writing tools with our detailed comparisons.

   You're now tasked to generate a suitable SEO meta description for a piece of content.
   
   Here are the partial content:
   
   "${content || purpose || description}"
  
   `;

  prompt += `Your generated meta description will be uploaded straight to wordpress, don't exceed 140 characters.`;

  if (language && language !== "English") {
    prompt += `, you will generate in ${language} language without any additional formatting`;
  } else {
    prompt += `, you will reply in the same language as the original input content is in without any additional formatting`;
  }

  prompt += `, you will reply the generated meta description only without anything else in your response.`;

  return prompt;
};

export const buildMetaTitlePrompt = (post: GeneratePostConfig) => {
  const language = post.language;
  const content = post.content;
  const description = post.description;
  const purpose = post.purpose;

  let prompt = `You're an SEO specialist skilled in generating SEO meta titles (Between 50 to 60 characters) that are short and concise for webpage content.

   Here are some examples of SEO meta titles that you have generated in the past that yielded great results:

   - Top 100 AI Text-to-Image Prompts: Stunning Diffusion Art Ideas
   - Junia.ai: Write better marketing copy and content with AI
   - How to Start a Blog and Make Money in 10 Simple Steps
   - Free AI Lyric Generator: Unleash Your Inner Songwriter Today
   - 5 Best AI image generators — tested and compared
   - Top Blogging Secrets: Niche, Optimize & Promote for Success
   - How to add Human Touch to AI-Generated Content using AI
   - Top 10 AI writing tools (Free and Paid)

   You're now tasked to generate a suitable SEO meta title for a piece of content.
   
   Here are the partial content:
   
   "${content || purpose || description}"
  
   `;

  prompt += `Your generated meta title will be uploaded straight to wordpress, don't exceed 60 characters.`;

  if (language && language !== "English") {
    prompt += `, you will generate in ${language} language without any additional formatting`;
  } else {
    prompt += `, you will reply in the same language as the original input content is in without any additional formatting`;
  }

  prompt += `, you will reply the generated meta title only without anything else in your response.`;

  return prompt;
};
