import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import React from "react";
import { Container, ResponsiveText } from "../front-page/shared";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { InlineSpan } from "components/as-inline-span";
import CountDownTimer from "components/coutdown/CountDownTimer";
import { isMobile } from "utils/is-mobile";

import { PurplePinkButton } from "components/purple-button";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";
import { christmasOneWeekAfter } from "client-server-shared/promotion";
import { useIsChrismasBannerActive } from "./utils";

export const XMarsBg = () => {
  React.useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    const onMobile = isMobile();
    var COUNT = 50;
    var masthead = document.querySelector(".x-mars-bg");
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var width = masthead.clientWidth;
    var height = masthead.clientHeight;
    var i = 0;
    var active = false;

    const requestAnimFrameFunc =
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function (callback) {
        window.setTimeout(callback, 1000 / 60);
      };

    function onResize() {
      width = masthead.clientWidth;
      height = masthead.clientHeight;
      canvas.width = width;
      canvas.height = height;
      ctx.fillStyle = "#FFF";

      var wasActive = active;
      active = width > onMobile ? 100 : 600;
      if (!wasActive && active) requestAnimFrameFunc(update);
    }

    var Snowflake = function () {
      this.x = 0;
      this.y = 0;
      this.vy = 0;
      this.vx = 0;
      this.r = 0;

      this.reset();
    };

    Snowflake.prototype.reset = function () {
      this.x = Math.random() * width;
      this.y = Math.random() * -height;
      this.vy = 1 + Math.random() * 3;
      this.vx = 0.5 - Math.random();
      this.r = 1 + Math.random() * 2;
      this.o = 0.5 + Math.random() * 0.5;
    };

    canvas.style.position = "absolute";
    canvas.style.left = canvas.style.top = "0";

    var snowflakes = [],
      snowflake;
    for (i = 0; i < COUNT; i++) {
      snowflake = new Snowflake();
      snowflake.reset();
      snowflakes.push(snowflake);
    }

    function update() {
      try {
        ctx.clearRect(0, 0, width, height);

        if (!active) return;

        for (i = 0; i < COUNT; i++) {
          snowflake = snowflakes[i];
          snowflake.y += snowflake.vy;
          snowflake.x += snowflake.vx;

          ctx.globalAlpha = snowflake.o;
          ctx.beginPath();
          ctx.arc(snowflake.x, snowflake.y, snowflake.r, 0, Math.PI * 2, false);
          ctx.closePath();
          ctx.fill();

          if (snowflake.y > height) {
            snowflake.reset();
          }
        }
      } catch (e) {}

      requestAnimFrameFunc(update);
    }

    onResize();
    window.addEventListener("resize", onResize, false);

    masthead.appendChild(canvas);
    return () => {
      window.removeEventListener("resize", onResize, false);
    };
  }, []);
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100%",
      }}
      className="x-mars-bg"
    />
  );
};

export const ChrismaBannerDashboard = React.memo(() => {
  const { onUpgrade } = useClickUpgrade({
    source: SourceType.Dashboard,
  });

  return (
    <Box
      sx={{
        background: "#CC231E",
        padding: {
          xs: "12px 6px",
          sm: "12px 34px",
          md: "12px 34px",
          lg: "12px 34px",
        },

        columnGap: "48px",
        position: "relative",
        rowGap: "6px",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        },
      }}
    >
      <XMarsBg />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          columnGap: "24px",
          padding: {
            xs: "0",
            sm: "0",
            md: "0",
            lg: "0 24px",
          },
          rowGap: "12px",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "6px",
            position: "relative",
          }}
        >
          {/*
            <LightBulk />
            */}
          <ResponsiveText
            fontSize={16}
            sx={{
              color: "text.white",
              maxWidth: "600px",
              marginRight: "auto",
            }}
          >
            <InlineSpan
              inherit
              sx={{
                fontWeight: "700!important",
              }}
            >
              NEW YEAR DEAL:{" "}
            </InlineSpan>
            <InlineSpan inherit> Get </InlineSpan>
            <InlineSpan
              inherit
              sx={{
                fontWeight: "700!important",
              }}
            >
              6 months free{" "}
            </InlineSpan>
            <InlineSpan
              inherit
              sx={{
                fontWeight: 400,
              }}
            >
              on all Annual Plans (50% off).
            </InlineSpan>
          </ResponsiveText>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "24px",
          }}
        >
          <CountDownTimer targetDate={christmasOneWeekAfter} />
          <PurplePinkButton
            onClick={() => {
              onUpgrade();
            }}
            sx={{
              margin: 0,
              whiteSpace: "nowrap",
              padding: {
                xs: "7px 12px",
                sm: "7px 24px",
                md: "7px 24px",
                lg: "7px 24px",
              },
              background: "#39125F",
              "&:hover": {
                background: "#39125F",
                opacity: 0.8,
              },
              borderRadius: "10px",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            {"Upgrade now"}
          </PurplePinkButton>
        </Box>
      </Box>
    </Box>
  );
});

const ChrismaBanner = React.memo(() => {
  const [open, setOpen] = React.useState(true);
  const active = useIsChrismasBannerActive();
  if (!active) {
    return null;
  }
  if (!open) {
    return null;
  }
  return (
    <Box
      sx={{
        background: "#111827",
        padding: "12px",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          paddingTop: "12px",
          borderRadius: "6px",
          paddingBottom: "12px",
          justifyContent: "space-between",
          columnGap: "48px",
          rowGap: "6px",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
          position: "relative",
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          background: "#CC231E",
        }}
      >
        <XMarsBg />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            columnGap: "24px",
            padding: {
              xs: "0",
              sm: "0",
              md: "0",
              lg: "0 24px",
            },
            rowGap: "12px",
            flexWrap: "wrap",
            zIndex: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "6px",
            }}
          >
            <ResponsiveText
              fontSize={16}
              sx={{
                color: "text.white",
                marginRight: "auto",
              }}
            >
              <InlineSpan
                inherit
                sx={{
                  fontWeight: "700!important",
                  color: "text.white",
                }}
              >
                NEW YEAR DEAL:
              </InlineSpan>
              <InlineSpan inherit> Get </InlineSpan>
              <InlineSpan
                inherit
                sx={{
                  fontWeight: "700!important",
                }}
              >
                6 months free{" "}
              </InlineSpan>
              <InlineSpan
                inherit
                sx={{
                  fontWeight: 400,
                }}
              >
                on all Annual Plans (50% off).
              </InlineSpan>
            </ResponsiveText>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "48px",
              paddingRight: "24px",
              justifyContent: "space-between",
              rowGap: "12px",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <CountDownTimer targetDate={christmasOneWeekAfter} />
          </Box>
          {/*
            <PurplePinkButton
            component={Link}
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              margin: 0,
              padding: "7px 24px",
              borderRadius: "22px",
              background: "#C326FA",
              marginRight: "24px",
              fontSize: "14px",
              fontWeight: 600,
            }}
            href={serverRoutes.pricing}
          >
            {t("Go Unlimited now")}
          </PurplePinkButton>
            */}
        </Box>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
          aria-label="close"
          sx={{
            position: "absolute",
            padding: 0,
            border: ".5px solid #FFFFFF",
            opacity: 0.5,
            top: {
              xs: "initial",
              sm: "initial",
              md: "10px",
              lg: "10px",
              xl: "10px",
            },
            bottom: {
              xs: "10px",
              sm: "10px",
              md: "initial",
              lg: "initial",
              xl: "initial",
            },
            right: "10px",
            "&:hover": {
              border: ".5px solid transparent",
            },
          }}
        >
          <CloseOutlinedIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
          />
        </IconButton>
      </Container>
    </Box>
  );
});

export default ChrismaBanner;
