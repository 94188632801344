import { lang } from "client-server-shared/constants";
import { Post, PostConfig } from "client-server-shared/types/types";
import { v4 } from "uuid";

export const clonePost = (post: Post) => {
  return {
    ...post,
    id: undefined,
    clientId: v4(),
    status: null,
    generatorOpen: false,
    public: false,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  };
};

export const addDefaultPostConfig = <T extends PostConfig = PostConfig>(
  postConfig: T
): T => {
  const config = postConfig || {};
  const { wordsCount, variationsCount, constraintCategories, categoriesList } =
    config;
  return {
    ...config,
    language: config.language || lang,
    categoriesList: Array.isArray(categoriesList) ? categoriesList : [],
    wordsCount: typeof wordsCount !== "number" ? 1000 : wordsCount,
    variationsCount: typeof variationsCount !== "number" ? 1 : variationsCount,
    constraintCategories:
      typeof constraintCategories === "undefined"
        ? false
        : constraintCategories,
    keywords: Array.isArray(config.keywords) ? config.keywords : [],
  };
};
