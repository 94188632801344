import React from "react";

import { Interval } from "client-server-shared/pricing/v2";
import { useUser } from "hooks/use-user";

import { useUserDetailsData } from "hooks/user-user-details-data";
import { trackTogglePlanType } from "utils/tracking/payment";
import { SourceType } from "utils/tracking/type";

interface PricingContextValue {
  selectedInterval: Interval;
  onIntervalChange: (interval: Interval) => void;
  customValue: number;
  setCustomValue: (value: number) => void;
}

interface CommonProps {
  page: SourceType.PricingPage | SourceType.BillingPage;
}

export const PricingContext = React.createContext<PricingContextValue | null>(
  null
);

export const usePricingContext = () => {
  const value = React.useContext(PricingContext);
  if (!value) {
    throw new Error("usePricingContext must be used within a PricingProvider");
  }
  return value;
};

export const PricingProvider = ({ children, page }) => {
  const [interval, setInterval] = React.useState(Interval.yearly);
  const [customValue, setCustomValue] = React.useState(0);
  const user = useUser();
  useUserDetailsData();

  const onIntervalChange = React.useCallback((nextInterval: Interval) => {
    trackTogglePlanType({
      source: page,
      register: !!user,
    });
    setInterval(nextInterval);
  }, []);
  return (
    <PricingContext.Provider
      value={{
        selectedInterval: interval,
        customValue,
        setCustomValue,
        onIntervalChange,
      }}
    >
      {children}
    </PricingContext.Provider>
  );
};
