import { Box, BoxProps } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Tooltip } from "components/ui/tooltip";
import { Typography } from "components/ui/Typography";
import React from "react";
import { TypographyProps } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

export const Section = ({ children, ...rest }: BoxProps) => {
  return (
    <Box
      {...rest}
      sx={{
        marginBottom: "14px",
        ...(rest.sx || {}),
      }}
    >
      {children}
    </Box>
  );
};

export const FormSection = ({
  children,
  defaultOpen = true,
  title,
  customOpen,
  customSetOpen,
  sx = {},
  noMargin = false,
}) => {
  const [isOpen, setOpen] = React.useState(defaultOpen);
  const effectiveIsOpen =
    typeof customOpen !== "undefined" ? customOpen : isOpen;
  return (
    <Section
      sx={{
        ...sx,
        ...(noMargin ? { marginBottom: 0 } : {}),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          columnGap: "4px",
          marginBottom: noMargin ? "0px" : "8px",
          cursor: "pointer",
        }}
        onClick={() => {
          if (customSetOpen) {
            customSetOpen();
          } else {
            setOpen(!isOpen);
          }
          setOpen(!isOpen);
        }}
      >
        <IconButton
          sx={{
            padding: 0,
          }}
        >
          {effectiveIsOpen ? (
            <KeyboardArrowDownOutlinedIcon
              sx={{
                height: "17px",
                width: "17px",
              }}
            />
          ) : (
            <KeyboardArrowRightOutlinedIcon
              sx={{
                height: "17px",
                width: "17px",
              }}
            />
          )}
        </IconButton>
        {title}
      </Box>
      {effectiveIsOpen ? children : null}
    </Section>
  );
};

export const Title = ({ children, htmlFor, forId, required, id, ...rest }) => {
  return (
    <Typography
      variant="subtitle2"
      component="label"
      htmlFor={htmlFor || forId}
      id={id}
      {...rest}
      sx={{
        fontSize: "12px",
        fontWeight: 600,
        whiteSpace: "nowrap",
        color: "rgba(0, 0, 0, 0.87)",
        ...(required
          ? {
              "&:after": {
                content: '"*"',
                color: "#ef5350",
              },
            }
          : {}),
        ...(rest.sx || {}),
      }}
    >
      {children}
    </Typography>
  );
};

export const SectionTitle = ({
  children,
  info,
  extraChildren,
  rootProps = {},
  titleProps = {},
  id,
  forId,
  noMargin,
  required,
  optional,
  between,
  description,
  descriptionProps = {},
}: {
  titleProps?: TypographyProps;
  forId?: string;
  id?: string;
  rootProps?: any;
  children: React.ReactNode;
  info?: React.ReactElement | string;
  extraChildren?: React.ReactElement;
  noMargin?: boolean;
  required?: boolean;
  optional?: boolean;
  between?: boolean;
  description?: string;
  descriptionProps?: TypographyProps;
}) => {
  return (
    <>
      <Box
        sameLine
        alignCenter
        {...rootProps}
        sx={{
          width: "100%",
          justifyContent: between ? "space-between" : "initial",
          marginBottom: noMargin ? 0 : description ? "2px" : "8px",
          ...(rootProps.sx || {}),
        }}
      >
        <Title forId={forId} id={id} required={required} {...titleProps}>
          {children}
        </Title>
        {info && (
          <Tooltip title={info}>
            <IconButton
              sx={{
                marginLeft: "3px",
              }}
              padding={0}
            >
              <InfoOutlinedIcon
                sx={{
                  height: "15px",
                  width: "15px",
                  fill: "rgba(0, 0, 0, 0.2)",
                }}
              />
            </IconButton>
          </Tooltip>
        )}
        {extraChildren}
        {optional && !extraChildren && (
          <Typography
            sx={{
              marginLeft: "auto",
              fontSize: "12px",
              color: "#8B9BA9",
            }}
          >
            {"Optional"}
          </Typography>
        )}
      </Box>
      {description ? (
        <Typography
          variant="body2"
          color="#7B7B7B"
          {...descriptionProps}
          sx={{
            fontSize: "12px",
            maxWidth: "300px",
            marginBottom: "12px",
            textAlign: "left",
            ...(descriptionProps.sx || {}),
          }}
        >
          {description}
        </Typography>
      ) : null}
    </>
  );
};
