import React from "react";

import { useEditPostPropertiesById } from "hooks/use-edit-post-properties";
import { ComponentBaseContext } from "../types";
import { TextInput, TextFieldContainer } from "./shared";
import { MetaDataField } from "client-server-shared/config/fieldMapping";
import { useGenerateMetaDescription } from "hooks/use-generate-meta";

export const MetaDescriptionEdit = ({
  value,
  collectionId,
  postId,
  sectionTitle,
  placeholder,
  infoTooltipText = ""
}: ComponentBaseContext & { value?: string }) => {
  const { onPropertyChanged } = useEditPostPropertiesById(collectionId, postId);

  const { onGeneratePostField, isLoading } = useGenerateMetaDescription({
    collectionId,
    postId,
  });

  const onGenerate = () => {
    onGeneratePostField();
  };
  return (
    <TextFieldContainer
      collectionId={collectionId}
      postId={postId}
      label={sectionTitle}
      infoTooltipText={infoTooltipText}
      name={MetaDataField.MetaDescription}
      value={value || ""}
      isLoading={isLoading}
      onGenerate={onGenerate}
      characterCount={(value || "").length}
    >
      <TextInput
        placeholder={placeholder || ""}
        value={value || ""}
        onChange={(value) =>
          onPropertyChanged(MetaDataField.MetaDescription, value as string)
        }
      />
    </TextFieldContainer>
  );
};
