import { useAppDispatch } from "hooks/use-app-dispatch";
import { setSubscription, setUserDetails } from "reducers/user";
import { useProcessingLog } from "./use-loading";
import useSWR from "swr";

import { swrFechter } from "utils/swrFechter";
import { useUser } from "./use-user";
import { apiAppUrl } from "client-server-shared/constants";

export const userDetailsLoadingLogId = "user-details-loading-status";

export const useFetchUserDetailsDataOnlyOnce = () => {
  const { initiated } = useProcessingLog(userDetailsLoadingLogId);
  return useUserDetailsData(!initiated);
};

export const useUserDetailsData = (canFetch: boolean = true) => {
  const userId = useUser()?.id;
  const dispatch = useAppDispatch();
  const { onSuccess } = useProcessingLog(userDetailsLoadingLogId);
  const { data } = useSWR(
    () => (canFetch && userId ? `${apiAppUrl}/api/me` : null),
    swrFechter,
    {
      dedupingInterval: 6000,
      focusThrottleInterval: 10000,
    }
  );

  if (data) {
    const { activePlan, ...userDetails } = data;
    dispatch(setSubscription(activePlan));
    dispatch(setUserDetails(userDetails));

    onSuccess();
  }
};
