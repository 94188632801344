// @ts-nocheck

import { TemplateType } from "client-server-shared/types/template-types";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import FeaturedVideoOutlinedIcon from "@mui/icons-material/FeaturedVideoOutlined";
import DownhillSkiingOutlinedIcon from "@mui/icons-material/DownhillSkiingOutlined";
import GavelOutlinedIcon from "@mui/icons-material/GavelOutlined";
import CorporateFareOutlinedIcon from "@mui/icons-material/CorporateFareOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import InsertEmoticonOutlinedIcon from "@mui/icons-material/InsertEmoticonOutlined";
import {
  productReviewTemplate,
  instagramPostTemplate,
  instagramBioTemplate,
  facebookPostTemplate,
  productLauncherTemplate,
  eventPromotionTemplate,
  customerTestimonialTemplate,
  specialDiscountPromotionTemplate,
  welcomeConfirmationEmailTemplate,
  jobDescriptionTemplate,
  aboutUsTemplate,
  landingPageTemplate,
  youtubeVideoScriptTemplate,
  tiktokVideoScriptTemplate,
  productPromotionTemplate,
  recurringEmailTemplate,
  shortStoryTemplate,
  reviewResponderTemplate,
  benefitStatementTemplate,
  betterExplainTemplate,
  coldEmailTemplate,
  socialThreadReplyerTemplate,
  datingAppReplyer,
  cateStudyTemplate,
  pressReleaseTemplate,
  faqSectionTemplate,
  userGuideManualTemplate,
  courseCurriculumTemplate,
  webinarScriptTemplate,
  interviewQuestionsTemplate,
  surveyQuestionsTemplate,
  restaurantReviewTemplate,
  travelItineraryTemplate,
  industryJargonListTemplate,
  termsAndConditionsTemplate,
  clientProposalTemplate,
  researchSummaryTemplate,
  privacyPolicyTemplate,
  realEstateListingTemplate,
  contentRepurposingIdeasTemplate,
  inspirationalQuotesTemplate,
  competitorAnalysisTemplate,
  petitionTextTemplate,
  eventInvitationTemplate,
  bookIdeaTemplate,
} from "../shared-templates/index";
import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined";
import { HashTagIcon } from "client-server-shared/icons/hash-tag";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import FestivalOutlinedIcon from "@mui/icons-material/FestivalOutlined";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import MailOutlineOutlinedIcon from "@mui/icons-material/MailOutlineOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TiktokIcon } from "client-server-shared/icons/tiktok-icon";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import NewReleasesOutlinedIcon from "@mui/icons-material/NewReleasesOutlined";
import CastForEducationOutlinedIcon from "@mui/icons-material/CastForEducationOutlined";
import PsychologyAltOutlinedIcon from "@mui/icons-material/PsychologyAltOutlined";
import BrandingWatermarkOutlinedIcon from "@mui/icons-material/BrandingWatermarkOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PendingActionsOutlinedIcon from "@mui/icons-material/PendingActionsOutlined";
import { MetaIcon } from "client-server-shared/icons/meta-icon";
import { InstagramColorIcon } from "client-server-shared/icons/instagram-color-icon";
import { YoutubeIcon } from "client-server-shared/icons/youtube-icon";

import { clientCustomTemplate } from "./default-template";
import { wrapTemplates } from "../shared-templates/util";
import {
  clientBlogTemplate,
  clientDatingAppReplyer,
  clientFacebookAdsTemplate,
  clientLyricTemplate,
  clientProductDescriptionTemplate,
  clientTwitterPostTemplate,
  clientUniqueContentIdeaTemplate,
  clientSummarizerTemplate,
} from "./feature-templates";

const clientProductReviewTemplate: TemplateType = {
  ...productReviewTemplate,
  icon: RateReviewOutlinedIcon,
};

const clientInstagramPostTemplate = {
  ...instagramPostTemplate,
  icon: HashTagIcon,
};

const clientInstagramBioTemplate = {
  ...instagramBioTemplate,
  icon: InstagramColorIcon,
};

const clientFacebookPostTemplate = {
  ...facebookPostTemplate,
  icon: MetaIcon,
};

const clientProductLauncherTemplate = {
  ...productLauncherTemplate,
  icon: RocketLaunchOutlinedIcon,
};

const clientEventPromotionTemplate = {
  ...eventPromotionTemplate,
  icon: FestivalOutlinedIcon,
};

const clientCustomerTestimonialTemplate = {
  ...customerTestimonialTemplate,
  icon: TextsmsOutlinedIcon,
};

const clientSpecialDiscountPromotionTemplate = {
  ...specialDiscountPromotionTemplate,
  icon: DiscountOutlinedIcon,
};

const clientWelcomeConfirmationEmailTemplate = {
  ...welcomeConfirmationEmailTemplate,
  icon: MailOutlineOutlinedIcon,
};

const clientJobDescriptionTemplate = {
  ...jobDescriptionTemplate,
  icon: WorkOutlineOutlinedIcon,
};

const clientAboutUsTemplate = {
  ...aboutUsTemplate,
  icon: InfoOutlinedIcon,
};

const clientLandingPageTemplate = {
  ...landingPageTemplate,
  icon: LanguageOutlinedIcon,
};

const clientYoutubeVideoScriptTemplate = {
  ...youtubeVideoScriptTemplate,
  icon: YoutubeIcon,
};

const clientTiktokVideoScriptTemplate = {
  ...tiktokVideoScriptTemplate,
  icon: TiktokIcon,
};

const clientProductPromotionTemplate = {
  ...productPromotionTemplate,
  icon: Inventory2OutlinedIcon,
};

const clientRecurringEmailTemplate = {
  ...recurringEmailTemplate,
  icon: MailOutlineOutlinedIcon,
};

const clientShortStoryTemplate = {
  ...shortStoryTemplate,
  icon: AutoStoriesOutlinedIcon,
};

const clientReviewResponderTemplate = {
  ...reviewResponderTemplate,
  icon: RateReviewOutlinedIcon,
};

const clientBenefitStatementTemplate = {
  ...benefitStatementTemplate,
  icon: LoyaltyOutlinedIcon,
};
const clientBetterExplainTemplate = {
  ...betterExplainTemplate,
  icon: ChatBubbleOutlineOutlinedIcon,
};

const clientColdEmailTemplate = {
  ...coldEmailTemplate,
  icon: MailOutlineOutlinedIcon,
};

const clientSocialThreadReplyerTemplate = {
  ...socialThreadReplyerTemplate,
  icon: ChatBubbleOutlineOutlinedIcon,
};

const clientCateStudyTemplate = {
  ...cateStudyTemplate,
  icon: RateReviewOutlinedIcon,
};
const clientPressReleaseTemplate = {
  ...pressReleaseTemplate,
  icon: NewReleasesOutlinedIcon,
};

const clientFaqSectionTemplate = {
  ...faqSectionTemplate,
  icon: InfoOutlinedIcon,
};

const clientUserGuideManualTemplate = {
  ...userGuideManualTemplate,
  icon: InfoOutlinedIcon,
};

const clientCourseCurriculumTemplate = {
  ...courseCurriculumTemplate,
  icon: CastForEducationOutlinedIcon,
};

const clientWebinarScriptTemplate = {
  ...webinarScriptTemplate,
  icon: FeaturedVideoOutlinedIcon,
};

const clientInterviewQuestionsTemplate = {
  ...interviewQuestionsTemplate,
  icon: ChatBubbleOutlineOutlinedIcon,
};

const clientSurveyQuestionsTemplate = {
  ...surveyQuestionsTemplate,
  icon: PsychologyAltOutlinedIcon,
};

const clientRestaurantReviewTemplate = {
  ...restaurantReviewTemplate,
  icon: RateReviewOutlinedIcon,
};

const clientTravelItineraryTemplate = {
  ...travelItineraryTemplate,
  icon: DownhillSkiingOutlinedIcon,
};

const clientIndustryJargonListTemplate = {
  ...industryJargonListTemplate,
  icon: InfoOutlinedIcon,
};

const clientTermsAndConditionsTemplate = {
  ...termsAndConditionsTemplate,
  icon: GavelOutlinedIcon,
};
const clientClientProposalTemplate = {
  ...clientProposalTemplate,
  icon: BrandingWatermarkOutlinedIcon,
};

const clientResearchSummaryTemplate = {
  ...researchSummaryTemplate,
  icon: RateReviewOutlinedIcon,
};

const clientPrivacyPolicyTemplate = {
  ...privacyPolicyTemplate,
  icon: PrivacyTipOutlinedIcon,
};
const clientRealEstateListingTemplate = {
  ...realEstateListingTemplate,
  icon: CorporateFareOutlinedIcon,
};

const clientContentRepurposingIdeasTemplate = {
  ...contentRepurposingIdeasTemplate,
  icon: DifferenceOutlinedIcon,
};

const clientInspirationalQuotesTemplate = {
  ...inspirationalQuotesTemplate,
  icon: InsertEmoticonOutlinedIcon,
};

const clientCompetitorAnalysisTemplate = {
  ...competitorAnalysisTemplate,
  icon: BarChartOutlinedIcon,
};

const clientPetitionTextTemplate = {
  ...petitionTextTemplate,
  icon: PendingActionsOutlinedIcon,
};

const clientEventInvitationTemplate = {
  ...eventInvitationTemplate,
  icon: CelebrationOutlinedIcon,
};

const clientBookIdeaTemplate = {
  ...bookIdeaTemplate,
  icon: MenuBookOutlinedIcon,
};

const clientTemplates = wrapTemplates([
  clientCustomTemplate,
  clientBlogTemplate,
  clientUniqueContentIdeaTemplate,
  clientSummarizerTemplate,
  clientTwitterPostTemplate,
  clientProductReviewTemplate,
  clientDatingAppReplyer,
  clientLyricTemplate,
  clientInstagramPostTemplate,
  clientReviewResponderTemplate,
  clientInstagramBioTemplate,
  clientFacebookPostTemplate,
  clientFacebookAdsTemplate,
  clientProductDescriptionTemplate,
  clientProductLauncherTemplate,
  clientEventPromotionTemplate,
  clientCustomerTestimonialTemplate,
  clientTravelItineraryTemplate,
  clientSpecialDiscountPromotionTemplate,
  clientWelcomeConfirmationEmailTemplate,
  clientUserGuideManualTemplate,
  clientPressReleaseTemplate,
  clientJobDescriptionTemplate,
  clientAboutUsTemplate,
  clientLandingPageTemplate,
  clientYoutubeVideoScriptTemplate,
  clientTiktokVideoScriptTemplate,
  clientProductPromotionTemplate,
  clientRecurringEmailTemplate,
  clientShortStoryTemplate,
  clientBenefitStatementTemplate,
  clientBetterExplainTemplate,
  clientColdEmailTemplate,
  clientSocialThreadReplyerTemplate,
  clientCateStudyTemplate,
  clientFaqSectionTemplate,
  clientCourseCurriculumTemplate,
  clientWebinarScriptTemplate,
  clientInterviewQuestionsTemplate,
  clientSurveyQuestionsTemplate,
  clientRestaurantReviewTemplate,
  clientIndustryJargonListTemplate,
  clientTermsAndConditionsTemplate,
  clientClientProposalTemplate,
  clientResearchSummaryTemplate,
  clientPrivacyPolicyTemplate,
  clientRealEstateListingTemplate,
  clientContentRepurposingIdeasTemplate,
  clientInspirationalQuotesTemplate,
  clientCompetitorAnalysisTemplate,
  clientPetitionTextTemplate,
  clientEventInvitationTemplate,
  clientBookIdeaTemplate,
]);

export default clientTemplates;
