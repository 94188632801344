import FormatListNumberedRtlOutlinedIcon from "@mui/icons-material/FormatListNumberedRtlOutlined";
import LyricsOutlinedIcon from "@mui/icons-material/LyricsOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { blogTemplate } from "../shared-templates/blog-post";
import { lyricTemplate } from "../shared-templates/lyric";
import { twitterPostTemplate } from "../shared-templates/twitter-post";
import { productDescriptionTemplate } from "../shared-templates/product-description";
import { datingAppReplyer } from "../shared-templates/dating-app-replyer";
import { facebookAdsTemplate } from "../shared-templates/facebook-ads";
import { uniqueContentIdeaTemplate } from "../shared-templates/unique-content-ideas";
import { summarizerTemplate } from "../shared-templates/summarizer";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { MetaIcon } from "client-server-shared/icons/meta-icon";
import { TwitterIcon } from "client-server-shared/icons/twitter-icon";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";

import { wrapTemplates } from "../shared-templates/util";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";

export const clientBlogTemplate = {
  ...blogTemplate,
  icon: FormatListNumberedRtlOutlinedIcon,
};

export const clientUniqueContentIdeaTemplate = {
  ...uniqueContentIdeaTemplate,
  icon: AutoAwesomeOutlinedIcon,
};

export const clientSummarizerTemplate = {
  ...summarizerTemplate,
  icon: SummarizeOutlinedIcon,
};

export const clientLyricTemplate = {
  ...lyricTemplate,
  icon: LyricsOutlinedIcon,
};

export const clientTwitterPostTemplate = {
  ...twitterPostTemplate,
  icon: TwitterIcon,
};

export const clientFacebookAdsTemplate = {
  ...facebookAdsTemplate,
  icon: MetaIcon,
};

export const clientDatingAppReplyer = {
  ...datingAppReplyer,
  icon: ChatBubbleOutlineOutlinedIcon,
};
export const clientProductDescriptionTemplate = {
  ...productDescriptionTemplate,
  icon: Inventory2OutlinedIcon,
};

export const featureTemplates = wrapTemplates([
  clientUniqueContentIdeaTemplate,
  clientSummarizerTemplate,
  clientBlogTemplate,
  clientTwitterPostTemplate,
  clientProductDescriptionTemplate,
  clientFacebookAdsTemplate,
  clientDatingAppReplyer,
]);
