import { isChristmasBannerActive } from "client-server-shared/promotion";
import { useSelector } from "hooks/use-selector";
import { selectUserInYearlyPlan } from "selectors/user";

export const useIsChrismasBannerActive = () => {
  const userInYearlyPlan = useSelector(selectUserInYearlyPlan);
  const inChrismas = isChristmasBannerActive();
  if (userInYearlyPlan) {
    return false;
  }

  return inChrismas;
};
