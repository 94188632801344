import React from "react";
import MuiTooltip, { TooltipProps } from "@mui/material/Tooltip";
import { getStyleProps } from "modules/themes/styleUtils";
import { Box } from "../box";

const trackedOpen = {}; // tooltip open count tracker, sometimes annoying to see the same tooltip over and over

export const TooltipContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        padding: "6px",
      }}
    >
      {children}
    </Box>
  );
};

export const Tooltip = (
  props: TooltipProps & { allowCounts?: number; allowCountKey?: string }
) => {
  const { allowCounts, allowCountKey, title, onOpen, ...rest } = props;
  const [active, setActive] = React.useState(true);
  const handleOpen = React.useCallback(
    (event) => {
      if (allowCounts && allowCountKey) {
        if (allowCountKey in trackedOpen) {
          trackedOpen[allowCountKey] = trackedOpen[allowCountKey] + 1;
        } else {
          trackedOpen[allowCountKey] = 1;
        }

        if (trackedOpen[allowCountKey] >= allowCounts) {
          setActive(false);
        }
      }
      if (onOpen) {
        onOpen(event);
      }
    },
    [allowCounts, title, allowCountKey]
  );

  return (
    <MuiTooltip
      placement="top"
      enterTouchDelay={50}
      {...rest}
      {...getStyleProps(rest)}
      onOpen={handleOpen}
      title={!active ? "" : title}
    >
      {rest.children}
    </MuiTooltip>
  );
};
