import { TemplateType } from "../types";

import { customTemplate } from "../custom";
import { wrapTemplates } from "../util";

export const noTemplate: TemplateType = wrapTemplates([
  {
    ...customTemplate,
    id: "default-XSO6C",
    value: "default-XSO6C",
    slug: "default",
    title: "Document",
  },
])[0];
