import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Description, Typography } from "components/ui/Typography";
import { PrimaryInput } from "components/input";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Section, SectionTitle } from "modules/selections/common";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import { usePublishToGhost } from "./shared";
import { IntegrationContextProvider, useIntegrationContext } from "../context";
import { IntegrationType } from "client-server-shared/constants";
import { IntegrationLayout } from "../layout";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { IconComponent } from "components/icon-component";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";
import { useUserHasFeatures } from "hooks/use-features";
import { InlineSpan } from "components/as-inline-span";
import { useCloseSelectedPopup } from "context/popup-context";
import { IntegrationServer } from "client-server-shared/types/types";
import { useSelector } from "hooks/use-selector";
import { selectIntegrationsByType } from "selectors/user";
import { GhostEditModal } from "./ghost-edit-modal";
import { SchedulePublishConfig } from "client-server-shared/types/workflow";
import { selectPostByCollectionIdAndPostId } from "selectors/collections";
import { useEditPostPropertiesById } from "hooks/use-edit-post-properties";
import protectedClientApi from "utils/protected-client-api";
import { MetaTitleEdit } from "modules/collection-by-id/post-metadata/meta-title";
import { MetaDataField } from "client-server-shared/config/fieldMapping";
import { textEllipsis } from "modules/themes/styleUtils";
import { Select } from "components/ui/select";
import { MenuItem } from "components/ui/menu";
import SchedulePublish from "../schedule-publish";
import { PurplePinkButton } from "components/purple-button";
import { MetaDescriptionEdit } from "modules/collection-by-id/post-metadata/meta-description";
import { useLoading } from "hooks/use-loading";
import { useMounted } from "hooks/use-mounted";
import { openLinkInNewTab } from "utils/open-new-tab";
import { useRefreshCollection } from "hooks/use-load-collection";
import { useNotifications } from "hooks/use-notification";
import { Divider } from "components/ui/divider";

const GhostPublishModal = ({
  postId,
  collectionId,
  onClose,
}: {
  postId: string;
  collectionId: string;
  onClose: () => void;
}) => {
  const [status, setStatus] = React.useState("public");
  const [author, setAuthor] = React.useState("");

  const [tags, setTags] = React.useState([]);
  const [selectedTags, setSelectedTags] = React.useState([]);

  const { onUpgrade } = useClickUpgrade({
    source: SourceType.WordPressIntegrationModal,
  });
  const { hasIntegrationFeature } = useUserHasFeatures();
  const { addFailureNotification, addSuccessNotification } = useNotifications();
  const [schedulePublishConfig, setSchedulePublishConfig] =
    React.useState<SchedulePublishConfig>({
      enabled: false,
      amount: 1,
    });
  const {
    integrations,
    activeIntegrationId,
    setActiveIntegrationId,
    selectedIntegration,
  } = useIntegrationContext();
  const post = useSelector((state) =>
    selectPostByCollectionIdAndPostId(state, collectionId, postId)
  );

  const [authors, setAuthors] = React.useState([]);
  const { onPostMetadataChanged } = useEditPostPropertiesById(
    collectionId,
    postId
  );
  const loading = useLoading();
  const isLoading = loading.isLoading;
  const { safeRunAsync, safeRun } = useMounted();
  const { onRefreshCollection } = useRefreshCollection();

  const onPublishToGhost = async () => {
    await safeRunAsync(async () => {
      loading.onLoading();
      try {
        let tagsIds = [];

        if (tags.length > 0 && selectedTags.length > 0) {
          tagsIds = selectedTags
            .map((tag) => {
              return tags.find((c) => c.name === tag)?.name;
            })
            .filter(Boolean);
        }
        const res = await protectedClientApi.publishToGhost({
          postId,
          status,
          integrationId: activeIntegrationId,
          existingPostId: post?.postMetadata?.ghost_id,
          author,
          tags: tagsIds,
          schedulePublishConfig,
          metaTitle: post?.[MetaDataField.MetaTitle],
          metaDescription: post?.[MetaDataField.MetaDescription],
        });
        if (!schedulePublishConfig?.publishedDate) {
          const { url, id } = res;
          addSuccessNotification("Successfully published to Ghost!");
          onPostMetadataChanged("ghost_id")(id);
        } else {
          addSuccessNotification("Successfully scheduled to publish to Ghost!");
        }
        loading.onIdle();
        onRefreshCollection(collectionId);
        onClose();
      } catch (e) {
        let message = `There's an error publishing to Ghost. Please check if your Ghost connection is still valid and make sure your post is not being edited by others at the same time.`;

        addFailureNotification(message);
        loading.onIdle();
      }
    });
  };

  const context = {
    collectionId,
    postId: postId,
  };
  React.useEffect(() => {
    if (selectedIntegration) {
      protectedClientApi
        .getGhostCommonData(selectedIntegration.id)
        .then((data) => {
          const { authors, tags } = data;

          if (Array.isArray(authors)) {
            setAuthors(authors);
          } else {
            setAuthors([]);
          }
          if (authors.length > 0) {
          } else {
            setAuthor("");
          }

          setTags(tags);
          setSelectedTags([]);
        });
    } else {
      setAuthors([]);
      setAuthor("");
      setTags([]);
      setSelectedTags([]);
    }
  }, [selectedIntegration]);

  return (
    <Dialog
      fullWidth
      maxWidth={integrations.length === 0 ? "sm" : "lg"}
      open
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          padding: "24 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                marginRight: "24px",
              }}
            >
              {`Publish to Ghost`}
            </Typography>
            {hasIntegrationFeature ? null : (
              <Description
                sx={{
                  marginTop: "6px",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "4px",
                  fontWeight: 600,
                  color: "#F44335",
                }}
              >
                <IconComponent
                  icon={LockOutlinedIcon}
                  sx={{
                    height: "15px",
                    width: "15px",
                  }}
                />
                <InlineSpan inherit>
                  <InlineSpan
                    inherit
                    onClick={() => {
                      onUpgrade();
                    }}
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                  >
                    Please upgrade
                  </InlineSpan>{" "}
                  to a higher plan to use this feature.
                </InlineSpan>
              </Description>
            )}
          </Box>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Description
          sx={{
            marginTop: "6px",
            maxWidth: "400px",
          }}
        >
          You can connect to multiple Ghost blogs and choose which Ghost blog to
          publish to.
        </Description>
      </DialogTitle>
      <DialogContent>
        <IntegrationLayout>
          <Section>
            <SectionTitle
              optional
              info="This is only needed if you're updating the same article that has been published previously"
            >
              Existing Ghost Post Id
            </SectionTitle>
            <PrimaryInput
              fullWidth
              size="small"
              value={post?.postMetadata?.ghost_id || ""}
              onChange={(e) => {
                onPostMetadataChanged("ghost_id")(e.target.value);
              }}
            />
          </Section>
          <Section>
            <MetaTitleEdit
              sectionTitle="Meta Title"
              value={post[MetaDataField.MetaTitle]}
              {...context}
            />
          </Section>
          <Section>
            <MetaDescriptionEdit
              sectionTitle="Meta Description"
              value={post[MetaDataField.MetaDescription]}
              {...context}
            />
          </Section>
          {authors && authors.length > 0 ? (
            <Section>
              <SectionTitle
                description={
                  "Leaving this empty will use the default author of the blog"
                }
              >
                Author
              </SectionTitle>
              <Select
                size="small"
                onChange={(e, newValue) => {
                  if (e.target.value === "none") {
                    setAuthor("");
                    return;
                  }
                  setAuthor(e.target.value);
                }}
                value={author}
              >
                <MenuItem value="none">
                  <Typography
                    variant="body2"
                    sx={{
                      ...textEllipsis,
                      maxWidth: "100px",
                      fontSize: "12px",
                    }}
                  >
                    None
                  </Typography>
                </MenuItem>
                {authors.map((author) => {
                  return (
                    <MenuItem key={author.name} value={author.name}>
                      <Typography
                        variant="body2"
                        sx={{
                          ...textEllipsis,
                          maxWidth: "100px",
                          fontSize: "12px",
                        }}
                      >
                        {author.name}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </Section>
          ) : null}

          {tags && tags.length > 0 ? (
            <Section>
              <SectionTitle
                description={
                  "Leaving this empty will use the default tags of the blog"
                }
              >
                Tags
              </SectionTitle>
              <Select
                size="small"
                multiple
                onChange={(e, newValue) => {
                  setSelectedTags(
                    // On autofill we get a stringified value.
                    typeof e.target.value === "string"
                      ? e.target.value.split(",")
                      : e.target.value
                  );
                }}
                renderValue={(selected) => selected.join(", ")}
                value={selectedTags}
              >
                {tags.map((tag) => {
                  return (
                    <MenuItem key={tag.id} value={tag.name}>
                      <Typography
                        variant="body2"
                        sx={{
                          ...textEllipsis,
                          maxWidth: "100px",
                          fontSize: "12px",
                        }}
                      >
                        {tag.name}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </Section>
          ) : null}

          <Section>
            <SectionTitle>Mode</SectionTitle>
            <Select
              size="small"
              onChange={(e, newValue) => {
                setStatus(e.target.value);
              }}
              value={status}
            >
              {["public", "draft"].map((status) => {
                return (
                  <MenuItem key={status} value={status}>
                    <Typography
                      variant="body2"
                      sx={{
                        ...textEllipsis,
                        maxWidth: "100px",
                        fontSize: "12px",
                      }}
                    >
                      {status}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </Section>
          <SchedulePublish
            singleArticle
            schedulePublishConfig={schedulePublishConfig}
            onChange={setSchedulePublishConfig}
          />
          {/*
            <AdvancedSetting
            advancedConfig={advancedConfig}
            setAdvancedConfig={setAdvancedConfig}
          />
            */}
          {!selectedIntegration ? null : (
            <Description
              sx={{
                marginBottom: "12px",
              }}
            >
              This will publish the article to {selectedIntegration.name}
            </Description>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <PurplePinkButton
              disabled={!hasIntegrationFeature}
              loading={isLoading}
              onClick={async () => {
                await onPublishToGhost();
                /*

                onPublishToWordPress({
                  postId,
                  status,
                  author,
                  categories: categoryIds,
                  tags: tagsIds,
                  integrationId: activeIntegrationId,
                  authorName: authors.find((a) => a.id === author)?.name,
                  schedulePublishConfig,
                  onSuccess: () => {
                    onClose();
                  },
                });
                */
              }}
            >
              Publish
            </PurplePinkButton>
          </Box>
          {selectedIntegration && (
            <Box
              sx={{
                marginTop: "16px",
              }}
            >
              <Divider
                sx={{
                  marginBottom: "12px",
                }}
              />
              <Description>
                <InlineSpan inherit>
                  Having Trouble Publishing? Make sure the article is not being
                  edited anywhere else and ensure the alt text of each images
                  are less than 125 characters.
                </InlineSpan>
              </Description>
            </Box>
          )}
        </IntegrationLayout>
      </DialogContent>
    </Dialog>
  );
};

const GhostPublishModalContainer = ({
  postId,
  collectionId,
}: {
  postId: string;
  collectionId: string;
}) => {
  const onClose = useCloseSelectedPopup();

  const integrations = useSelector((state) =>
    selectIntegrationsByType(state, IntegrationType.Ghost)
  );
  const [showConnectModal, setShowConnectModal] = React.useState(false);
  const [selectedIntegration, setSelectedIntegration] =
    React.useState<IntegrationServer | null>(null);
  return (
    <>
      <IntegrationContextProvider
        inPublishMode
        type={IntegrationType.Ghost}
        onAddNewIntegration={() => {
          setShowConnectModal(true);
        }}
        onCustomEditPost={(integration) => {
          setSelectedIntegration(integration);
        }}
      >
        {showConnectModal && (
          <GhostEditModal
            afterConnect={() => {
              setShowConnectModal(false);
            }}
            onClose={() => {
              setShowConnectModal(false);
            }}
          />
        )}
        {integrations.length === 0 ? (
          <GhostEditModal onClose={onClose} />
        ) : (
          <GhostPublishModal
            postId={postId}
            collectionId={collectionId}
            onClose={onClose}
          />
        )}

        {selectedIntegration && (
          <GhostEditModal
            url={selectedIntegration.ghost_url}
            apiKey={selectedIntegration.ghost_api_key}
            id={selectedIntegration.id}
            afterConnect={() => {
              setSelectedIntegration(null);
            }}
            onClose={() => {
              setSelectedIntegration(null);
            }}
          />
        )}
      </IntegrationContextProvider>
    </>
  );
};

export default GhostPublishModalContainer;
