import { templateCategory, TemplateType } from "../types";

import {
  isDescriptionDefined,
  createOnInvalidFunc,
  isKeywordsDefined,
  isPartialContentDefined,
  isTitleDefined,
  isPurposeDefined,
} from "../validation";

export const customerTestimonialTemplate: TemplateType = {
  id: "customer-testimonial-TeHqM",
  value: "customer-testimonial-TeHqM",
  label: "Customer Testimonial",
  slug: "customer-testimonial",
  categories: [templateCategory.ecommerce],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "customer testimonial",
  title: "Customer Testimonial",
  contentTypeLabel: "customer testimonial",
  helpText: "Choose at least one keyword, description, or title to begin",
  defaultCollectionName: "Customer Testimonial collection",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a name for your testimonial",
  defaultPostName: "Untitled Customer Testimonial",
  toGeneratorPrompt: "Need to generate more testimonials?",
  defaultWordsCount: 150,
  cardDescription: "Showcase a customer testimonial for any purpose.",
  shortDescription: "All fields are <strong>optional</strong>",
  caption: "",
  addtionalFields: [
    {
      label: "Product/Service description?",
      fieldName: "purpose",
      required: true,
      placeholder: "What does your product/service do?",
    },
  ],
  requiredFields: ["title"],
  excludedFields: [
    "wordsCount",
    "targetAudience",
    "editorialImage",
    "startFromOutline",
    "keywords",
  ],
  fields: {
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a testimonial title",
      onValidate: (post) => {
        return !!(
          isPurposeDefined(post) ||
          isDescriptionDefined(post) ||
          isPartialContentDefined(post) ||
          isKeywordsDefined(post)
        );
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;
        const purpose = post.purpose;
        let prompt = "Generate a compelling title for a testimonial";

        if (purpose) {
          prompt += ` about the product/service: ${purpose}`;
        }

        if (description) {
          prompt += ` with the customer info of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }

        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (content) {
          prompt += `. Here's some partial content from the testimonial: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    writingStyle: {
      label: "Writing Style",
      fieldName: "writingStyle",
      optional: true,
      placeholder: "Eg: Informative, conversational",
    },
    description: {
      label: "Customer Info",
      required: true,
      fieldName: "description",
      description: "What's the main points you want to cover?",
      placeholder: `- [Customer info]
- [Customer background]
- [Success story]`,
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Friendly, Relax, Excitement, Formal",
    },
    targetAudience: {
      label: "Target audience",
      optional: true,
      fieldName: "targetAudience",
      placeholder: " ",
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!(
          isTitleDefined(post) ||
          isDescriptionDefined(post) ||
          isPurposeDefined(post) ||
          isKeywordsDefined(post)
        );
      },
      calculateCost: (config) => config.wordsCount || 150,
      onInValid: createOnInvalidFunc(
        "Some product/service description or some customer info is required to generate a testimonial"
      ),
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const keywords = post.keywords;
        const purpose = post.purpose;
        const title = post.title;
        let prompt =
          "Generate a customer testimonial based on the following information: \n";

        if (purpose) {
          prompt += ` about the product/service: ${purpose}`;
        }
        if (description) {
          prompt += `, customer information: ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, using a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, with a ${toneOfVoice} tone of voice`;
        }

        if (keywords && keywords.length > 0) {
          prompt += `, incorporating the following keywords: ${keywords.join(
            ", "
          )}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += `. The testimonial should be approximately ${wordsCount} words long`;
        }

        prompt +=
          ". The testimonial should be from a satisfied customer who has used our product/service and is willing to recommend it to others. The testimonial should be no more than 3-4 sentences long and should be written in a positive, upbeat tone ";

        return prompt;
      },
    },
  },
};
