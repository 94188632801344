import { templateCategory, TemplateType } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

export const reviewResponderTemplate: TemplateType = {
  id: "review-responder-PmeQS",
  value: "review-responder-PmeQS",
  label: "review-responder",
  slug: "review-responder",
  categories: [templateCategory.socialMedia],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "review response",
  title: "Review Responder",
  defaultWordsCount: 400,
  contentTypeLabel: "review response",
  helpText: "Provide the review details and your desired response tone",
  defaultCollectionName: "Review responses",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your review response",
  defaultPostName: "Untitled Review Response",
  toGeneratorPrompt: "Need to generate more review responses?",
  cardDescription:
    "Craft professional and empathetic responses to customer reviews, addressing their feedback and concerns",
  shortDescription:
    "Try to provide more details to create a well-crafted review response",
  caption:
    "You can edit and customize the generated content to better suit your specific audience and preferences.",
  addtionalFields: [],
  excludedFields: [
    "targetAudience",
    "keywords",
    "writingStyle",
    "editorialImage",
    "startFromOutline",
  ],
  fields: {
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a title for your review response",
      onValidate: (post) => {
        return !!(isDescriptionDefined(post) || isPartialContentDefined(post));
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt =
          "Generate a title for a response to a review from a customer";

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content from the response: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },

    description: {
      label: "Details",
      fieldName: "description",
      placeholder: `- [Review from the customer] Example: I started seeing my sales increased 2 days after using Junia AI\n- [Instructions for the response] Example: Thank the customer for the kind review and mention the product quality of Junia AI will only get better and better`,
      required: true,
      description: "Enter the details of the review you're responding to",
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Friendly, formal",
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const toneOfVoice = post.toneOfVoice;
        const wordsCount = post.wordsCount;
        const language = post.language;

        let prompt =
          "Craft a response to a review from a customer based on the provided information";

        if (description) {
          prompt += `. Details: ${description}`;
        }

        if (toneOfVoice) {
          prompt += ` using a ${toneOfVoice} tone of voice`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words.`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
