import React from "react";
import { selectUserHasActiveSubscription } from "selectors/user";
import { useSelector } from "./use-selector";
import { useOpenPopup } from "context/popup-context";
import { LimitReachedType } from "client-server-shared/types/limit";

export const useNotEnoughWorkflows = () => {
  const onOpenPopup = useOpenPopup();

  const onNotEnoughWorkflows = ({
    description1,
  }: {
    description1?: string;
  } = {}) => {
    onOpenPopup("creditLimitReached", {
      type: LimitReachedType.workflowsLimitReached,
      description1: description1,
    });
  };

  return { onNotEnoughWorkflows };
};

export const useWordsLimitReachedPrompt = ({
  type,
}: {
  type?: "blog-post-workflow" | "free-plan-regenerate-outline";
} = {}) => {
  const onOpenPopup = useOpenPopup();
  const { onNotEnoughWorkflows } = useNotEnoughWorkflows();
  const showPrompt = React.useCallback(() => {
    if (type === "blog-post-workflow") {
      onNotEnoughWorkflows();
    } else if (type === "free-plan-regenerate-outline") {
      onOpenPopup("creditLimitReached", {
        type: LimitReachedType.outlineLimitReached,
        description1:
          "To regenerate this outline, you'll need to wait a few minutes or subscribe to a paid plan.",
        description2:
          "Would you like to unlock more features and benefits with our paid plans?",
      });
    } else {
      onOpenPopup("creditLimitReached", {
        type: LimitReachedType.wordsLimitReached,
      });
    }
  }, []);
  return {
    showPrompt,
  };
};
export const useImagesLimitReachedPrompt = () => {
  const onOpenPopup = useOpenPopup();
  const showPrompt = React.useCallback(() => {
    onOpenPopup("creditLimitReached", {
      type: LimitReachedType.imagesLimitReached,
    });
  }, []);
  return {
    showPrompt,
  };
};
export const useActivePaidPlanPrompt = () => {
  const userInActivePaidPlan = useSelector(selectUserHasActiveSubscription);

  const onOpenPopup = useOpenPopup();

  const showPrompt = React.useCallback(() => {
    if (!userInActivePaidPlan) {
      onOpenPopup("creditLimitReached", {
        type: LimitReachedType.requiredPaidPlan,
      });
    }
  }, [userInActivePaidPlan]);

  return {
    userInActivePaidPlan,
    showPrompt,
  };
};
