import { Box, BoxProps } from "components/ui/box";
import { Button } from "components/ui/button";
import { Description, Typography } from "components/ui/Typography";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { PricingCard } from "./shared";

import {
  Interval,
  freeTrial,
  creatorPlanNewMonthly,
  creatorPlanNewYearly,
  proYearly,
  proMonthly,
  getPlanStatsByPricingMark,
  PlanName,
} from "client-server-shared/pricing/v2";
import { useUser } from "hooks/use-user";

import { useUserDetailsData } from "hooks/user-user-details-data";
import {
  Container,
  ResponsiveText,
} from "../marketing-pages/front-page/shared";
import { SourceType } from "utils/tracking/type";
import Switch from "components/ui/form/switch";
import { styled } from "@mui/material/styles";
import Stack from "components/ui/box/stack";
import {
  PircingPromotionBanner,
  PaidTrailBanner,
} from "modules/marketing-pages/popup-banner";
import { useSelector } from "hooks/use-selector";
import { selectUserCanHavePaidTrial } from "selectors/user";
import { usePricingContext } from "./context";
import { PricingCardV2 } from "./promotional";
import { useIsChrismasBannerActive } from "modules/marketing-pages/banners/utils";

interface CommonProps {
  page: SourceType.PricingPage | SourceType.BillingPage;
}

const CheckMarkIcon = () => {
  return (
    <CheckIcon
      sx={{
        marginRight: "14px",
        color: "text.white",
      }}
      fontSize="small"
    />
  );
};
const NotIncludedIcon = () => {
  return (
    <NotInterestedIcon
      sx={{
        marginRight: "14px",
        color: "#99a8a8",
      }}
      fontSize="small"
    />
  );
};

export const MonthlyPlan = ({
  page,
  sx = {},
}: CommonProps & { sx?: BoxProps }) => {
  const { selectedInterval, customValue } = usePricingContext();

  const monthlySelected = selectedInterval === Interval.monthly;
  const isChrismas = useIsChrismasBannerActive();

  const selectedProPlan = React.useMemo(() => {
    const extra = getPlanStatsByPricingMark(
      PlanName.pro,
      selectedInterval,
      customValue
    );
    return { ...proMonthly, ...extra };
  }, [selectedInterval, customValue]);

  if (!monthlySelected) {
    return null;
  }

  if (isChrismas) {
    return (
      <>
        <PricingCardV2 {...freeTrial} sx={sx} page={page} />
        <PricingCardV2 {...creatorPlanNewMonthly} sx={sx} page={page} />
        <PricingCardV2 {...selectedProPlan} sx={sx} page={page} />
      </>
    );
  }

  return (
    <>
      {/*
        <PricingCard {...standardMonthly} />
        */}
      <PricingCard {...creatorPlanNewMonthly} sx={sx} page={page} />
      <PricingCard {...selectedProPlan} sx={sx} page={page} />
    </>
  );
};

export const YearlyPlan = ({
  page,
  sx = {},
}: CommonProps & { sx?: BoxProps }) => {
  const { selectedInterval, customValue } = usePricingContext();
  const annualSelected = selectedInterval === Interval.yearly;

  const isChrismas = useIsChrismasBannerActive();

  const selectedProPlan = React.useMemo(() => {
    const extra = getPlanStatsByPricingMark(
      PlanName.pro,
      selectedInterval,
      customValue
    );
    return { ...proYearly, ...extra };
  }, [selectedInterval, customValue]);

  if (!annualSelected) {
    return null;
  }
  if (isChrismas) {
    return (
      <>
        <PricingCardV2
          annualSelected={annualSelected}
          {...creatorPlanNewYearly}
          sx={sx}
          page={page}
        />
        <PricingCardV2
          annualSelected={annualSelected}
          {...selectedProPlan}
          sx={sx}
          page={page}
        />
      </>
    );
  }
  return (
    <>
      {/*
        <PricingCard {...standardYearly} />
        */}
      <PricingCard {...creatorPlanNewYearly} sx={sx} page={page} />
      <PricingCard {...selectedProPlan} sx={sx} page={page} />
    </>
  );
};

const IOSSwitch = styled((props: any) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, fontRatio }) => ({
  width: 42 * fontRatio,
  height: 26 * fontRatio,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2 * fontRatio,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: `translateX(${16 * fontRatio}px)`,
      color: "#fff!important",
      "& + .MuiSwitch-track": {
        backgroundColor: "#B85FD7",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#B85FD7",
      border: `${6 * fontRatio}px solid #fff`,
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22 * fontRatio,
    height: 22 * fontRatio,
  },
  "& .MuiSwitch-track": {
    borderRadius: (26 / 2) * fontRatio,
    backgroundColor: "#B85FD7!important",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const Verctor = ({ sx = {} }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      style={sx}
    >
      <path
        d="M15.9363 1.27344C15.9363 2.02605 15.9771 2.78559 15.7999 3.52397C15.092 6.47377 12.9084 8.00431 10.651 9.84365C8.95297 11.2272 7.12849 12.5502 5.17241 13.5491C3.84761 14.2256 2.58093 14.863 1.41016 15.7996"
        stroke="#B85FD7"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1.18555 15.8787C1.23843 15.7663 1.27677 15.6856 1.35375 15.5822C1.51772 15.3618 1.67752 15.1376 1.83841 14.915C2.04214 14.6332 2.17243 14.3438 2.31451 14.0312"
        stroke="#B85FD7"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M1.31445 15.8594C1.9217 15.8594 2.52835 15.885 3.1362 15.885"
        stroke="#B85FD7"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const IntervalSwitcher = ({ sx = {}, page }) => {
  const { onIntervalChange, selectedInterval } = usePricingContext();
  const annualSelected = selectedInterval === Interval.yearly;
  const inUpgradePopup = page === SourceType.UpgradePopup;
  const fontRatio = inUpgradePopup ? 0.8 : 1;

  return (
    <>
      <Box
        sameLine
        sx={{
          justifyContent: "center",
          marginBottom: "72px",
          marginTop: {
            xs: "48px",
            sm: "48px",
            md: "48px",
            lg: "48px",
          },
          ...sx,
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: `${fontRatio * 16}px`,
              fontWeight: annualSelected ? 400 : 500,
              color: annualSelected ? "#848484" : "#000000",
            }}
          >
            {"Pay Monthly"}
          </Typography>
          <IOSSwitch
            defaultChecked
            fontRatio={fontRatio}
            checked={annualSelected}
            onChange={() =>
              onIntervalChange(
                annualSelected ? Interval.monthly : Interval.yearly
              )
            }
            inputProps={{ "aria-label": "Switch Pricing plan" }}
          />
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Typography
              sx={{
                fontSize: `${fontRatio * 16}px`,
                fontWeight: !annualSelected ? 400 : 500,
                color: !annualSelected ? "#848484" : "#000000",
              }}
            >
              {"Pay yearly"}
            </Typography>
            <Box
              sx={{
                top: "-28px",
                right: "-48px",
                position: "absolute",
                whiteSpace: "nowrap",
              }}
            >
              <Box
                sx={{
                  background: "#F5E9F9",
                  borderRadius: "13px",
                  padding: "1px 12px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#B85FD7",
                    fontWeight: 500,
                  }}
                >
                  {"20%+ off"}
                </Typography>
              </Box>
              <Verctor
                sx={{
                  margin: "auto",
                }}
              />
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export const Pricing = React.memo(({ page }: CommonProps) => {
  const bannerActive = useIsChrismasBannerActive();
  const userCanHavePaidTrial = useSelector(selectUserCanHavePaidTrial);

  return (
    <Box
      id="pricing"
      sx={{
        padding: "24px 0 0 0",
        borderRadius: "22px",
      }}
    >
      {!bannerActive && userCanHavePaidTrial ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "12px",
          }}
        >
          <ResponsiveText
            variant="h1"
            decrement="medium"
            textAlign="center"
            fontSize={40}
            sx={{
              color: "#333",
              margin: "auto",
              fontWeight: 500,
              width: {
                xs: "100%",
                sm: `100%`,
                md: `100%`,
                xl: `100%`,
                lg: "100%",
              },
              "& > span": {
                fontSize: "inherit",
              },
            }}
          >
            Choose Your Plan and Automate Your Content Creation
          </ResponsiveText>
          {userCanHavePaidTrial ? (
            <Container maxWidth="md">
              <PaidTrailBanner />
            </Container>
          ) : null}
        </Box>
      ) : (
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "12px",
          }}
        >
          <ResponsiveText
            variant="h1"
            decrement="medium"
            textAlign="center"
            fontSize={40}
            sx={{
              color: "#333",
              margin: "auto",
              fontWeight: 600,
              maxWidth: "700px",
              width: {
                xs: "100%",
                sm: `100%`,
                md: `100%`,
                xl: `100%`,
                lg: "100%",
              },
              "& > span": {
                fontSize: "inherit",
              },
            }}
          >
            Choose Your Plan and Automate Your Content Creation
          </ResponsiveText>
        </Container>
      )}
      {bannerActive && (
        <Container
          maxWidth="md"
          sx={{
            margin: "12px auto",
          }}
        >
          <PircingPromotionBanner />
        </Container>
      )}
      <IntervalSwitcher />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          rowGap: "24px",
          columnGap: "24px",
          paddingBottom: "24px",
          padding: {
            xs: "0 30px",
            sm: "0 30px",
            md: "0 30px",
            xl: "0",
            lg: "0",
          },
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            xl: "row",
            lg: "row",
          },
        }}
      >
        <>
          <MonthlyPlan page={page} />
          <YearlyPlan page={page} />
        </>
      </Box>
    </Box>
  );
});
