import {
  generateEndingWord,
  streamJsonSeparator,
  streamVariationSeparator,
} from "client-server-shared/constants";
import { ErrorJson, getReadableStreamErrorWithFetch } from "./error-parsing";

export interface StreamManyReadOptions {
  t: string;
  i: number;
}

export const onStreamOnceTakeFirst = async (response: Response) => {
  let data = "";
  let error = null;

  const onRead = (value: StreamManyReadOptions) => {
    data = value.t;
  };
  await onStreamTakeMany(
    response,
    onRead,
    () => {},
    (e) => {
      error = e;
    }
  );

  return {
    data: data,
    error: error,
  };
};

export const onStreamTakeMany = async (
  response: Response,
  onRead: (options: StreamManyReadOptions) => void,
  onDone: (options: StreamManyReadOptions) => void,
  onError: (error?: ErrorJson) => void
) => {
  const data = response.body;
  if (!response.ok || !response.body) {
    const errorJson = await getReadableStreamErrorWithFetch(response);
    onError(errorJson);
    return;
  }

  if (!data) {
    onError();
    return;
  }
  const reader = data.getReader();
  const decoder = new TextDecoder();
  let done = false;

  const groupByIndex: Record<number, string> = {};

  let buffer = "";

  while (!done) {
    const { value, done: doneReading } = await reader.read();
    done = doneReading;
    const chunkValue = decoder.decode(value);

    buffer += chunkValue;

    let separatorIndex = buffer.indexOf(streamJsonSeparator);

    while (separatorIndex !== -1) {
      const completeChunk = buffer.slice(0, separatorIndex);
      buffer = buffer.slice(separatorIndex + streamJsonSeparator.length);
      const group = extractJsonFromString(completeChunk);
      Object.keys(group).forEach((key) => {
        const index = Number(key);
        const newText = group[index];

        if (!groupByIndex[index]) {
          groupByIndex[index] = "";
        }

        let text = groupByIndex[index];
        let shouldFinishEarly = false;
        if (newText.includes(generateEndingWord)) {
          text += newText.replace(generateEndingWord, "");
          shouldFinishEarly = true;
        } else {
          text += newText;
        }

        groupByIndex[index] = text;
        onRead({ t: text, i: index });
        if (shouldFinishEarly) {
          onDone({ t: text, i: index });
        }
      });

      separatorIndex = buffer.indexOf(streamJsonSeparator);
    }
  }

  if (buffer) {
    let finalSeparatorIndex = buffer.indexOf(streamJsonSeparator);
    if (finalSeparatorIndex !== -1) {
      const completeChunk = buffer.slice(0, finalSeparatorIndex);
      const group = extractJsonFromString(completeChunk);
      Object.keys(group).forEach((key) => {
        const index = Number(key);
        const newText = group[index];

        if (!groupByIndex[index]) {
          groupByIndex[index] = "";
        }

        let text = groupByIndex[index];
        let shouldFinishEarly = false;
        const bufferText = newText + text;
        if (bufferText.includes(generateEndingWord)) {
          text = bufferText.replace(generateEndingWord, "");
          shouldFinishEarly = true;
        } else {
          text += newText;
        }

        groupByIndex[index] = text;
        onRead({ t: text, i: index });
        if (shouldFinishEarly) {
          onDone({ t: text, i: index });
        }
      });
    }
  }
};

const extractJsonFromString = (text: string) => {
  const groupByIndex: Record<number, string> = {};
  const [index, t] = text.split(streamVariationSeparator).filter(Boolean);

  if (typeof index !== "undefined" && typeof t !== "undefined") {
    const i = Number(index);
    if (!groupByIndex[i]) {
      groupByIndex[i] = "";
    }

    groupByIndex[i] =
      groupByIndex[i] +
      t.replace(streamVariationSeparator, "").replace(streamJsonSeparator, "");
  }

  return groupByIndex;
};

export const onStream = async (
  response: Response,
  onRead: (text: string) => void,
  onDone: (text: string) => void,
  onError: (error?: ErrorJson) => void
) => {
  if (!response.ok) {
    const errorJson = await getReadableStreamErrorWithFetch(response);
    onError(errorJson);
    return;
  }
  const data = response.body;

  if (!data) {
    onError();
    return;
  }

  const reader = data.getReader();
  const decoder = new TextDecoder();
  let done = false;
  let hasFinishedEarly = false;
  let lastMessage = "";

  while (!done) {
    const { value, done: doneReading } = await reader.read();
    done = doneReading;
    const chunkValue = decoder.decode(value);
    if (chunkValue.includes(generateEndingWord)) {
      done = true;
      hasFinishedEarly = true;
      const remaining = chunkValue.replace(generateEndingWord, "");
      lastMessage = lastMessage + remaining;
      onDone(lastMessage);
      return;
    }

    lastMessage = lastMessage + chunkValue;

    onRead(lastMessage);
  }

  if (hasFinishedEarly) {
    return;
  }
  if (done) {
    onDone(lastMessage);
  }
};

export const onStreamOnce = async (response: Response) => {
  let data = "";
  let error = null;
  const onRead = (text: string) => {
    data = text;
  };

  await onStream(
    response,
    onRead,
    (t) => {
      data = t;
    },
    (e) => {
      error = e;
    }
  );

  return {
    data: data,
    error: error,
  };
};
