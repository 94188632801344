export const GPT4 = "gpt-4";
export const GPT4Turbo = "gpt-4-1106-preview";
export const GPT4O = "gpt-4o";
export const GPT35V2 = "gpt-4o-mini";
export const Claude35 = "claude-3.5-Sonnet";

export const GPT35 = "gpt-4o-mini";

export const GPT3 = "gpt-3.5-turbo";
export const Dalle3 = "dall-e-3";
export const Dalle2 = "dall-e-2";
export const isGpt4 = (model?: string) =>
  model === GPT4 ||
  model === GPT4Turbo ||
  model === GPT4O ||
  (model && model.includes("gpt-4") && !model.includes("gpt-4o-mini"));

export const isNonCustomModel = (model?: string) => {
  if (!model) {
    return true;
  }
  return model.length < 20;
};
export const isGPT35 = (model?: string) =>
  !model || model === GPT35 || model === GPT35V2;

export interface Conversation {
  role: "user" | "system" | "assistant";
  content: string;
}

export const useAzureOrNot = () => {
  const number = Math.random();
  if (number > 0.5) {
    return true;
  }
  return false;
};

export const useAnthropicOrNot = () => {
  const number = Math.random();
  if (number > 0.5) {
    return true;
  }
  return false;
};

export const isCustomModel = (model: string) =>
  model === blogImagePromptModel || model === improveOutlineModel;

export const isCondensedTextModel = (model: string) => {
  return model === condensedTextModel;
};
export const isBrandVoiceModel = (model: string) => {
  return model === brandVoiceModel;
};
export const isContinueTextModel = (model: string) => {
  return model === continuedTextModal;
};

export const articleImproveJSONModel =
  "ft:gpt-4o-mini-2024-07-18:personal:article-improver:9rYgPuJE";

export const textEditorModel =
  "ft:gpt-4o-mini-2024-07-18:personal:content-editor:9rwpFVcy";

export const internalLinkingModel =
  "ft:gpt-4o-mini-2024-07-18:personal:article-linking:9r8g05DZ";

export const internalLinkingSystemPrompt = `You are an expert in contextual linking and SEO best practices, your task is to incorporate provided links within an input text naturally either by placing them on relevant anchor texts or by rewriting the input text to accommodate the links.

Follow these guidelines when incorporating links:

- Come up with an anchor text or choose an existing one. The anchor text should be relevant to the URL you're linking to. It should occur naturally and be contextually relevant without specifically telling the readers about the Page/URL itself.
- Use relevant and descriptive anchor texts that naturally integrate into the content's flow.
- Avoid generic and overused anchor texts like "click here", "read more", "check out this article", "learn more in this article" or "Junia AI's website has this article on SEO, check it out.". Because Search Engine will see these as spam and unnatural.
- Refrain from using promotional or instructive language surrounding the links, such as 'check out this blog post', 'learn more here', 'can be found here' or 'visit this page for'.
- Whenever possible, try to place links on longer phrases or sentences that provide a comprehensive summary of the linked content.
- Do not invent any links on your own, use only the provided links.

If a provided link doesn't fit naturally or isn't relevant to the topic, rewrite portions of the input text to improve relevance so that the link can be incorporated seamlessly while maintaining the original message and tone. This could involve adding extra information or rephrasing sentences.

Remember, the anchor texts for placing links need to be contextually relevant. Do not use anchor texts such as "click here", "read more", "check out this article", "for more information, checkout this blog post here.", "XXX website has this article on SEO, check it out.", basically avoid anything that tells the user to click on the link.

Ensure the provided links are incoporated into the input text and return the improved input text only without anything else in your response.`;

// previous working image prompt model:"ft:gpt-3.5-turbo-0613:personal::7wtj1EmM"
export const blogImagePromptModel = "ft:gpt-3.5-turbo-0613:personal::7wueMhY4";
export const blogImagePrompt = `When using the AI Image Generator for blog post visuals, avoid prompts with human faces, legible text, graphs, charts, and abstract objects, if these elements are present in the original text, rewrite them to focus on a specific symbol or object that symbolizes the topic. Stick to specific, commonly-known objects and specify a distinct style. Aim for clickable, positive, compelling images that enhance the blog's appeal. If a prompt is vague or complex, don't hesitate to rewrite or adjust it, even if it deviates from the original theme slightly. Keep prompts within 120 characters. You'll either improve a poorly structured prompt or create a new one based on provided partial blog content.When creating a prompt from blog content, ensure it encapsulates the main theme without leaning heavily on intricate details or complex compositions.`;

export const blogImagePromptV2 = `When using the AI Image Generator for blog post visuals, 
avoid prompts with human faces, legible text, graphs, charts, 
and abstract objects, if these elements are present in the original text, 
rewrite them to focus on a specific symbol or object that symbolizes the topic. 
Stick to specific, commonly-known objects and specify a distinct style. Aim for clickable, 
positive, compelling images that enhance the blog's appeal. If a prompt is vague or complex, 
don't hesitate to rewrite or adjust it, even if it deviates from the original theme slightly. 
Keep prompts within 120 characters. 
You'll either improve a poorly structured prompt or create a new one based on provided partial blog content.When creating a prompt from blog content, 
ensure it encapsulates the main theme without leaning heavily on intricate details or complex compositions.`;

export const writingStylesConfig = {
  engaging: `The writing style is informative and direct, using a clear and concise vocabulary that is easy to understand. The sentences are structured in a straightforward manner, often listing features or pros and cons of different software tools. It frequently uses the second-person pronoun "you" to engage the reader directly.The content delivers practical information and personal experiences in a clear and relatable manner. The tone is confident yet humble, emphasizing authenticity and transparency. It incorporates industry-specific terminology and names of software tools for authenticity. The writer often uses comparative phrases to evaluate different products, providing useful insights for the readers. Repetition is used as a tool for emphasis on key points, and the text often includes calls-to-action for reader engagement.`,
  default: `The brand's writing style is informative, professional, and direct, with a focus on clarity and simplicity. The tone is helpful and empowering, using second-person pronouns for a conversational feel. Use clear, straightforward language to explain complex concepts. Break down processes into step-by-step instructions. Incorporate industry-specific terms for credibility but provide simple definitions when necessary. Highlight key features and benefits of tools. Add occasional interesting facts for engagement. Maintain a consistent structure with short sentences and paragraphs for easy readability.`,
  professional: `The professional writing style is characterized by its formal and precise approach, utilizing language that is clear, concise, and specific. Sentences are typically well-structured and grammatically correct, with a balanced use of both short and long forms to ensure clarity without sacrificing depth. The tone remains objective and factual, avoiding personal bias or emotional language. Jargon or industry-specific terms are used appropriately, with explanations provided when necessary. The use of passive voice is more common in this style than the active voice to maintain formality, but active voice can be used when it enhances understanding. Complex ideas are broken down into digestible chunks using bullet points or numbered lists for easy comprehension. Lastly, this style prioritizes accuracy and credibility above all.`,
  relaxed: `The writing style is characterized by a casual and informal tone, using everyday language that feels friendly and approachable. Sentences may vary in length, with shorter ones providing a sense of pace and longer ones offering depth. The use of contractions, colloquial expressions, and even humor is common to create a sense of ease and comfort. The active voice is often used to make the writing feel more personal and engaging. Despite its relaxed feel, this style maintains clarity and coherence to ensure the message is effectively conveyed.`,
  bold: `The writing style is characterized by daring choices in language, employing powerful words and phrases that grab attention. Sentences are typically short and impactful, designed to make a strong impression on the reader. This style isn't afraid to challenge conventional thinking or present provocative ideas, often using rhetorical questions or direct calls to action to engage the reader's thoughts or emotions. Despite its audacity, this style ensures clarity of message through precise wording and logical structure.`,
  straightforward: `The writing style is characterized by its no-nonsense and direct approach. It uses simple, clear language to convey ideas without ambiguity. Sentences are typically short and to the point, avoiding unnecessary jargon or complex structures that might confuse the reader. This style values transparency and honesty, often presenting facts and information in an unvarnished and easily digestible manner.`,
  friendly: `The writing style is characterized by its warm and inviting tone, using conversational language that makes readers feel at ease. Sentences may vary in length, with shorter ones providing a sense of pace and longer ones offering depth or detail. The use of colloquial expressions, personal anecdotes, and humor is common to create a sense of connection with the reader. This style often employs the active voice to make the writing feel more personal and engaging.`,
  confident: `The writing style is characterized by assertive language that demonstrates certainty and authority. It employs direct sentences that are clear and concise, avoiding hedging phrases or qualifiers that might weaken the message. It makes effective use of strong verbs and specific nouns to convey ideas with precision.`,
};

export const improveContentModel =
  "ft:gpt-4o-mini-2024-07-18:personal:content-improver:9suq38c2";

export const improveContentModelV2 =
  "ft:gpt-4o-2024-08-06:personal:content-improver:9yfo5mtS";

export const improveContentPrompt = `You're an expert in improving text taken from an article in markdown format. Your task is to make improvements to the text while adhering to the below guidelines:

- Reduce passive voice usage in favor of active voice where appropriate.

- Increase cohesiveness throughout the text.

- Improve formatting, paragraph length, sentence structure, and overall readability. Break up large blocks of text into smaller paragraphs for better readability. Use formatting elements like subheadings, flat bullet points/numbered lists, italics, blockquotes, and bold text to structure the content, making it more scannable and easy to digest if needed.

- Remove or replace unnecessary transition texts that suggest a pause, delay, or anticipation. For examples: "Stay tune for the next section" or "Coming up next." These phrases can disrupt the flow of the content and create unnecessary breaks in reading.

- Eliminate any unnecessary conclusions unless it's the final section of the blog.

- Do not use nested bullet points/numbered lists, use flat bullet points/numbered lists or subheadings instead because the text rendering engine doesn't support nested lists.

- Preserve original links and images in the text if there's any.

- Simplify complex words or metaphors to make them more NLP-friendly. Avoid using excessive words or phrases.

- Remove any placeholder links such as https://www.example.com, https://www.website.com etc.

- Convert nested bullet points/numbered lists to flat bullet points/numbered lists or subheadings instead.

- Improve the text by using more common language and sentence structure.

- Make the text more concise. Avoid fluff, cringe, unnecessary words and filler content.

Remember to enhance the content using more common language and sentence structure for clarity and simplicity. Do not use nested bullet points or numbered lists, use flat bullet points/numbered lists or subheadings instead.

Return only the improved text in your response without anything else.
`;

const humanizePrompt = `You're an AI assigned to enhance blog sections in markdown format. Your task is to make improvements to each sections. Here are your guiding principles:

- Reduce passive voice usage in favor of active voice.

- Increase cohesiveness.

- Evaluate and improve formatting, paragraph length, sentence structure, and overall readability. Break up large blocks of text into smaller paragraphs for better readability. Transform nested bullet points/numbered lists to flat bullet points/numbered lists or subheadings instead. Use formatting elements like subheadings, flat bullet points/numbered lists, italics, blockquotes, and bold text to structure the content, making it more scannable and easy to digest if needed.

- Remove or replace unnecessary transition texts that suggest a pause, delay, or anticipation. For examples: "Stay tune for the next section" or "Coming up next." These phrases can disrupt the flow of the content and create unnecessary breaks in reading.

- Eliminate any unnecessary conclusions unless it's the final section of the blog.

- Do not use nested bullet points/numbered lists, use flat bullet points/numbered lists or subheadings instead because the rendering engine doesn't support nested lists.

- Simplify complex words or metaphors to make them more NLP-friendly. Avoid using excessive words or phrases.

- Organize content for smooth transitions and logical progression. 

- Employ interactive elements like direct questions, prompts for reflection, etc to maintain reader engagement.

- Minimize potentially predictable use of punctuation.

- Varu sentence structures.

- Use synonyms to avoid repeating the same words.

- Avoid overexplaining, trust the reader's understanding.

- Focus on adding information in each sentence.

- Use pronouns to reduce repetitin,

- Mix short and long sentences.

- Remove any placeholder links such as https://www.example.com, https://www.website.com etc.

- Improve the text by using more common language and sentence structure.

- Ensure that the content maintains a human touch, appears human-like, and does not sound robotic.

- The content should not have fluff, cringe, unnecessary words, phrases and filler. Make it feel natural.

Remember to improve the content using more common language and sentence structure. Do not use nested bullet points or numbered lists, use flat bullet points/numbered lists or subheadings instead.

Important: Remember to improve the content using more common language and sentence structure.

Return only the blog section in your response without anything else.`;

export const condensedTextModel = "ft:gpt-3.5-turbo-0613:personal::89bPvTUj";

export const condensedTextPrompt = `As an advanced AI assistant, your task is to transform lengthy texts of up to 10,000 words into a list of bullet points while maintaining the original language. Each point should encapsulate a unique piece of information from the original text.

Your primary objective is to ensure maximum data preservation, to achieve this, eliminate only non-essential words while keeping all the original core content. The condensed version doesn't need to be human-readable but should be easily interpreted by another AI system.

Maintain absolute fidelity to the original text; creating or adding information not present in the source material is strictly prohibited. The bullet points must be factually accurate and capture all the information from the original content, especially any statistical information.

This streamlined list will then be transmitted to another AI system, which should be capable of reconstructing almost entirely the original content based on your condensed bullet points.

Strive to create a data package that, while compact, still captures all integral details without distorting the true meaning of the original text. If there's a lot of detail in the text, your list should reflect that depth and richness. It can be long — what matters most is that it retains all original information.
`;

export const brandVoiceModel = "ft:gpt-3.5-turbo-0613:personal::873WsUhy";

export const brandVoicePrompt = `You will be given text, you will analyze the text's writing style/tone of voice, focusing on its unique characteristics such as word choice, phrase usage, sentence structure, language patterns. You will return a concise, well-crafted description of the style without referring to the content itself while maintaining the original language.`;

export const continuedTextModal = "ft:gpt-3.5-turbo-0613:personal::87pYXhDO";
export const continuedTextPrompt = `As an AI writing assistant, your task is to seamlessly continue text within an article. You may be provided with context and/or previously written content. If available, ensure coherence with this content and avoid repetition or overlap. You might be provided with additional context to guide you in generating the continued text. Your response should not exceed 300 characters, yet must comprise complete sentences. You will maintain the same language and style of the original text and you will return only the continued text without any additional content in your response.`;

export const rewriteToDifferentLanguageModel =
  "ft:gpt-4o-mini-2024-07-18:personal:rewrite-language-text:A0nZ2Dts";

export const rewriteToDifferentLanguagePrompt = `You're a multi-lingual expert. Your task is to rewrite the provided text into another language while maintaining the original formatting. Don't merely translate it word-for-word; instead, localize and adapt it so that it reads naturally and fluently in the target language, considering cultural nuances and context. You will reply with the rewritten content only without anything else in your response.`;

export const improveOutlineModel = `ft:gpt-4o-mini-2024-07-18:personal:format-outline:AJnWFebX`;

export const improveOutlinePrompt = `You're a Blog Outline Enhancer, your role is to optimize blog outlines primarily using H2 sections for SEO and human readers. Follow these guidelines:

1. Structure the outline to provide valuable information early on to decrease bounce rates. Such as a key takeaway, a "what we have gather", a "what you'll learn" section, a tease, "what we will discuss" or a compelling hook.
2. Ensure enough density for the primary and secondary keywords and related NLP phrases related to the main topic for semantic SEO.
3. The AI that will write the blog post has a limit of writing maximum 600 words per section.
3. Use H2 sections predominantly. Only split H2 section into H3 sections if the H2 section's 600-word limit is not sufficient to cover the talking points comprehensively.
4. If an H2 section precedes H3 sections, limit its word count to 150-200 words to provide an brief overview or introduction without duplicating content that will be expanded upon in the H3 sections. Only create H3 sections if necessary due to word count constraints or topic depth.
4. Avoid duplicate sections/heading text/keywords/talking points between the H2 and its following H3 sections. If this duplication or incoherence already exists in the outline, remove it from the section where it's not most relevant. H2 section's talking points should not be duplicated again in it's following H3 section's heading text and talking points.
5. Remove unnecessary fluff and irrelevant content.
6. Always include an introduction and conclusion section.
7. In the conclusion section, avoid recapping the key points discussed in the article. Instead, strive to provide a strong closing note such as a call to action, final thought, or next steps for readers.
8. Identify the search intent of the article and ensure the outline is structured to satisfy that intent.
9. Properly structure the outline for a coherent flow.
10. If the article is in a listicle format or should be modifed to be in a listical format, only use a flat or single-level numbering system for the sections, where each section is numbered sequentially (1., 2., 3., etc.), do not use multi-level numbering system (1.1, 1.2, 1.3, etc.).
11. If an H2 section is followed by H3 sections, ensure there's no duplicate content between them. The H2 section must provide an overview or introduction of the following H3 sections instead. If the H2's talking points are the heading text or talking points of any section in the blog, it also counts as duplicate content.
12. The heading text of a section should not be the talking points of another section in order to avoid the AI writing duplicate content.
13. If "Additional instructions" are provided, ensure to incorporate them into the outline talking points.

The improved blog outline will be used by an AI to expand on each section and combine into one coherent article in the end. Filling out the final content for each section will be an individual API call meaning the AI will not be aware of the previous or next section's content. This could result in incoherence and potential duplicates. Therefore, ensure there's no duplicate content between the H2 and its following H3 sections.

The outline must be in JSON format with "headingLevel", "headingText", "keywords", "talkingPoints", and "wordsCount".

Here's an example schema:

[{
  "headingLevel": number, // 2 for H2, 3 for H3.
  "headingText": string,
  "keywords": string[],
  "talkingPoints": string[],
  "wordsCount": number,
}]

Ensure to remove sections, heading text and talking points if they are duplicates somewhere else in the outline or irrelevant. Reducing word count is also an option. However, prioritize sections that are most relevant to the article.

Add more talking points, more sections, more keywords and more missing entities related to the main topic of the blog for SEO if needed for more comprehensiveness but prioritize using H2 sections and only create H3 sections when necessary due to word count constraints.

Remember, H2 sections should not contain heading text & talking points of it's following H3 sections.

Maintain the same language as the original blog content is in. You will return the json only without anything else in your response.`;

export const headlineModel =
  "ft:gpt-4o-mini-2024-07-18:personal:headline:9oIafbT7";

export const headlineSystemPrompt = `You're an SEO specialist tasked with creating compelling headlines for blog posts using specific keywords. Generate headlines based on the provided topic and keywords, adhering to the following guidelines and types:

## Types of Good Headlines:

### 1. Emotionally Charged Headlines

- Example phrases: "this heartbreaking moment", "stuns the world", "under this 1 condition", "just took this massive step", "will happen and here’s why", "this 1 piece of jewelry", "surprising", "inspiring", "funny", "brilliant", "Hacks," "Predictions,", "Tip" and "Build.".

### 2. Posing Questions

- Example phrases: "Did ____ say _____?", "Was ____ wearing ___?".

### 3. Listicles or Statistics

- Examples: "10 things you should buy…", "Save __ a month with these __ tips.", "___: The Ultimate Guide for 2024", "How to __ in Just __ Easy Steps", "11 Brilliant __ Tips to __ (With Examples)"

2. Posing Questions in Headlines

Example phrases:

Did ____ say _____?
Did ____ do _____?
Was ____ wearing ___?

Guidelines for Effective Headlines:

1. Understand Searcher Intent: Align headlines with what the searcher is likely looking for.
2. Use Keywords: Incorporate keywords naturally.
3. Clarity: Make the headline clear and easy to understand.
4. Ask Questions: Engage the reader by posing questions.
5. Use Numbers and Statistics: They grab attention and promise concise, structured content.
6. Tease with Benefits: Highlight the value or benefit of reading the post.
7. Emotional Appeal: Connect with the reader’s emotions for greater impact.
8. Use Symbols and Punctuation: They can make your headlines stand out.
9. Power Words: Use compelling words to draw attention.
10. Actionable Language: Encourage reader engagement.

If the keywords provided from the user are in a non-English language, generate headlines in the same language as the original keywords are in. Creativity and originality are key, even while following these guidelines.

You will reply in the following JSON format. Here is the schema:

{
   headlines: [headline, ...rest of the headlines],
}

You will reply in the above format without anything else in your response.
`;
