import { Box, BoxProps } from "components/ui/box";
import { Description, Typography } from "components/ui/Typography";
import React from "react";

import { useUser } from "hooks/use-user";
import { PlanType, PlanName } from "client-server-shared/pricing/v2";
import { useCheckout } from "hooks/use-checkout";
import { dashboardRouteConfig } from "client-server-shared/config/routes";
import {
  ButtonWithLoading,
  OutlineGreyButton,
} from "components/ui/button/button-with-loading";
import { trackClickOnPricing } from "utils/tracking/payment";
import { useRouter } from "next/router";
import { InlineSpan } from "components/as-inline-span";
import { useSelector } from "hooks/use-selector";
import { selectUserCanHavePaidTrial } from "selectors/user";
import { Divider } from "components/ui/divider";
import Slider from "components/ui/form/slider";
import { useShortId } from "hooks/use-short-id";
import { Interval } from "client-server-shared/pricing/v2";

import { SourceType } from "utils/tracking/type";
import Switch from "components/ui/form/switch";
import { styled } from "@mui/material/styles";
import Stack from "components/ui/box/stack";
import { usePricingContext } from "./context";
import { FeatureListItem } from "./shared";
import { useIsChrismasBannerActive } from "modules/marketing-pages/banners/utils";

const marks = [
  {
    value: 0,
  },
  {
    value: 35,
  },
  {
    value: 70,
  },
  {
    value: 100,
  },
];

const IOSSwitch = styled((props: any) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, fontRatio }) => ({
  width: 42 * fontRatio,
  height: 26 * fontRatio,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2 * fontRatio,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: `translateX(${16 * fontRatio}px)`,
      color: "#fff!important",
      "& + .MuiSwitch-track": {
        backgroundColor: "#B85FD7",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#B85FD7",
      border: `${6 * fontRatio}px solid #fff`,
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22 * fontRatio,
    height: 22 * fontRatio,
  },
  "& .MuiSwitch-track": {
    borderRadius: (26 / 2) * fontRatio,
    backgroundColor: "#B85FD7!important",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const IntervalSwitcherV2 = ({ sx = {}, page }) => {
  const { onIntervalChange, selectedInterval } = usePricingContext();
  const annualSelected = selectedInterval === Interval.yearly;
  const inUpgradePopup = page === SourceType.UpgradePopup;
  const fontRatio = inUpgradePopup ? 0.8 : 1;
  return (
    <Box
      sx={{
        justifyContent: "center",
        marginBottom: "48px",
        marginTop: {
          xs: "48px",
          sm: "48px",
          md: "48px",
          lg: "48px",
        },
        ...sx,
      }}
    >
      <Box
        sameLine
        sx={{
          justifyContent: "center",
        }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: `${fontRatio * 16}px`,
              fontWeight: annualSelected ? 400 : 500,
              color: annualSelected ? "#848484" : "#000000",
            }}
          >
            {"Pay Monthly"}
          </Typography>
          <IOSSwitch
            defaultChecked
            fontRatio={fontRatio}
            checked={annualSelected}
            onChange={() =>
              onIntervalChange(
                annualSelected ? Interval.monthly : Interval.yearly
              )
            }
            inputProps={{ "aria-label": "Switch Pricing plan" }}
          />
          <Typography
            sx={{
              fontSize: `${fontRatio * 16}px`,
              fontWeight: !annualSelected ? 400 : 500,
              color: !annualSelected ? "#848484" : "#000000",
            }}
          >
            {"Pay yearly"}
          </Typography>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginTop: "12px",
        }}
      >
        <Box
          sx={{
            background: "rgba(24, 24, 27, 0.1)",
            borderRadius: "13px",
            padding: "1px 12px",
          }}
        >
          <Description
            sx={{
              color: "rgb(24, 24, 27)",
              fontWeight: 600,
            }}
          >
            BLACK FRIDAY: 50% OFF ANNUAL PLANS (6 MONTHS FREE)
          </Description>
        </Box>
      </Box>
    </Box>
  );
};

export const PricingCardV2 = ({
  planName,
  description,
  priceIds,
  currency = "USD",
  unit_amount,
  features,
  page,
  label,
  info,
  sx = {},
  annualSelected,
}: PlanType & {
  sx?: BoxProps;
  page:
    | SourceType.BillingPage
    | SourceType.PricingPage
    | SourceType.UpgradePopup;
}) => {
  const user = useUser();
  const isFreeTiralCard = planName === PlanName.free;
  const isPro = planName === PlanName.creator;
  const isAdvancedPlan = planName === PlanName.pro;
  const { handleCheckout, isLoading, handleStartingFreeTrial } = useCheckout();
  const priceString = new Intl.NumberFormat("en-US", {
    currency: currency,
    minimumFractionDigits: 0,
  }).format((unit_amount || 0) / 100);
  const isChrismas = useIsChrismasBannerActive();
  const { customValue, setCustomValue } = usePricingContext();
  const currencyLabel = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 0,
  })
    .format(0)
    .replace(/[0-9.,\s]/g, "");
  const inUpgradePopup = page === SourceType.UpgradePopup;
  const userCanHavePaidTrial = useSelector(selectUserCanHavePaidTrial);
  const usePaidTrail = userCanHavePaidTrial && planName === PlanName.creator;
  const fontRatio = inUpgradePopup ? 0.8 : 1;
  const sliderTitleId = useShortId();
  const sliderId = useShortId();
  if (isFreeTiralCard && user) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexBasis: {
          xs: "100%",
          sm: "100%",
          md: "40%",
          lg: "30%",
          xl: "30%",
        },
        borderRadius: "12px",
        position: "relative",
        background: "#FFFFFF",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "24px 24px 12px 24px",
        }}
      >
        {annualSelected && (
          <Box
            sx={{
              position: "absolute",
              background: "rgba(233, 67, 67, 0.992)",
              padding: "3px 12px",
              top: "16px",
              right: "16px",
              borderRadius: "4px",
              marginLeft: "10px",
            }}
          >
            <Typography
              fontSize={12 * fontRatio}
              fontWeight={600}
              sx={{
                whiteSpace: "nowrap",
              }}
              variant="body2"
              color="text.white"
            >
              Limited Time Offer
            </Typography>
          </Box>
        )}
        <Box padding={10}>
          <Box
            sameLine
            alignCenter
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h4"
              fontWeight={500}
              sx={{
                fontSize: `${24 * fontRatio}px`,
              }}
              color={"text.black"}
            >
              {label || planName}
            </Typography>
          </Box>
          <Typography
            variant="caption2"
            color={"#9C9C9E"}
            sx={{
              display: "flex",
              fontSize: `${14 * (inUpgradePopup ? 0.9 : 1)}px`,
              maxWidth: "330px",
            }}
          >
            {description ? description : ""}
          </Typography>

          <Typography
            fontWeight={400}
            variant="h4"
            textAlign={"center"}
            color="text.white"
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <InlineSpan
              sx={{
                fontSize: `${36 * (inUpgradePopup ? 0.7 : 1)}px`,
                color: "#C0CBD9",
              }}
            >
              {currencyLabel}
            </InlineSpan>
            <InlineSpan
              sx={{
                fontSize: `${36 * (inUpgradePopup ? 0.7 : 1)}px`,
                color: "#000000",
                fontWeight: 600,
              }}
            >
              {priceString}
            </InlineSpan>
            {isChrismas && annualSelected && !isFreeTiralCard && (
              <InlineSpan
                sx={{
                  color: "#9C9C9E",
                  fontSize: `${16 * fontRatio}px`,
                  textDecorationLine: "line-through",
                  marginLeft: "6px",
                }}
              >
                {currencyLabel}
                {Number(priceString) / 0.5}
              </InlineSpan>
            )}
            <InlineSpan
              sx={{
                color: "#9C9C9E",
                fontSize: `${16 * fontRatio}px`,
                marginLeft: "6px",
              }}
            >
              {"/month"}
            </InlineSpan>
          </Typography>

          {!annualSelected && (
            <Description
              sx={{
                color: "rgb(24, 24, 27)",
                fontWeight: 600,
                marginBottom: "16px",
              }}
            >
              New year deal: Save ${Number(priceString) * 12 * 0.5} (50% off) on
              annual plan
            </Description>
          )}

          {/*
          {!isFreeTiralCard && (
            <Typography
              fontWeight={400}
              variant="body2"
              textAlign={"center"}
              color="text.black"
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              margin="10px 10px"
            >
              {wordsPerMonth} words/month
            </Typography>
          )}
              */}
          <ButtonWithLoading
            variant="contained"
            onClick={() => {
              trackClickOnPricing({
                source: page,
                register: !!user,
              });
              if (isFreeTiralCard) {
                handleStartingFreeTrial();
              } else {
                handleCheckout(priceIds[0], {
                  paidTrial: usePaidTrail,
                });
              }
            }}
            loading={isLoading}
            capitalize
            sx={{
              width: "100%",
              display: "flex",
              margin: "auto",
              borderRadius: "4px",
              boxShadow: "none",
              background: "#111827",
              color: "text.white",
              "&:hover": {
                background: "#111827",
                opacity: 0.8,
              },
            }}
          >
            {user && !isFreeTiralCard
              ? usePaidTrail
                ? "Start for $2,99"
                : "Upgrade Now"
              : "Get started"}
          </ButtonWithLoading>

          <Box
            sx={{
              marginTop: "24px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Typography
              marginBottom={14}
              color={"text.black"}
              variant="body2"
              sx={{
                fontWeight: 600,
              }}
            >
              {isFreeTiralCard
                ? "What's included?"
                : isPro
                ? !!user
                  ? "What's included?"
                  : "Everything in Free, plus:"
                : "Everything in Creator, plus:"}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "12px",
              }}
            >
              {features
                .filter((feature) => {
                  if (feature.loginedOnly && !user) {
                    return false;
                  }
                  return true;
                })
                .map((feature) => {
                  return (
                    <FeatureListItem
                      fontRatio={inUpgradePopup ? 0.9 : 1}
                      key={feature.description}
                      description={feature.description}
                      info={feature.info}
                      isFreeTiralCard={isFreeTiralCard}
                      isPro={isPro}
                      isAdvancedPlan={isAdvancedPlan}
                      disabled={feature.disabled}
                      learnMoreQuery={feature.learnMoreQuery}
                    />
                  );
                })}
            </Box>
          </Box>
        </Box>
      </Box>
      {isAdvancedPlan && (
        <Box
          sx={{
            padding: "0 24px 12px 24px",

            "& .MuiSlider-thumb": {
              height: "16px !important",
              width: "16px !important",
            },
            "& .MuiSlider-track": {
              backgroundColor: "rgba(184, 95, 215, 1)",
            },
          }}
        >
          <Divider
            sx={{
              marginBottom: "12px",
            }}
          />
          <Typography
            id={sliderTitleId}
            marginBottom={14}
            color={"rgba(0, 0, 0, 0.6)"}
            variant="body2"
            sx={{
              fontWeight: 600,
              marginBottom: "12px",
            }}
          >
            CUSTOMIZE YOUR PLAN
          </Typography>
          <Slider
            id={sliderId}
            aria-labelledby={sliderTitleId}
            step={null}
            marks={marks}
            value={customValue}
            onChange={(event, newValue) => {
              setCustomValue(newValue as number);
            }}
            sx={{
              color: "rgba(0, 0, 0, 0.3)",
              height: 4,
            }}
            valueLabelDisplay="off"
            aria-label="Customize plan slider"
          />
        </Box>
      )}
      <OutlineGreyButton
        onClick={() => {
          const element = document.getElementById("pricing-table");
          if (element) {
            setTimeout(() => {
              element.scrollIntoView({ behavior: "smooth" });
            }, 100);
          } else {
            if (user) {
              window.open(
                `${dashboardRouteConfig.billing}#pricing-table`,
                "_blank"
              );
            }
          }
        }}
        sx={{
          marginTop: "auto",
          border: 0,
          padding: "24px 0",
          color: "text.black",
          background: "rgb(237 241 244)",
          borderTopLeftRadius: "0px",
          borderTopRightRadius: "0px",
          borderBottomLeftRadius: "12px",
          borderBottomRightRadius: "12px",
          "&:hover": {
            background: "rgb(237 241 244)",
            border: 0,
            opacity: 0.8,
          },
        }}
      >
        {"See all features"}
      </OutlineGreyButton>
    </Box>
  );
};
