import React from "react";
import startCase from "lodash/startCase";

import IconButton from "@mui/material/IconButton";

import styles from "./post-metadata.module.scss";
import { Tooltip } from "components/ui/tooltip";
import { Box } from "components/ui/box";
import type { BoxProps } from "@mui/material/Box";
import { Post } from "client-server-shared/types/types";
import { useSelector } from "hooks/use-selector";
import { selectPostByCollectionIdAndPostId } from "selectors/collections";
import RefreshIcon from "@mui/icons-material/Refresh";
import { Title } from "modules/selections/common";
import { PrimaryInput } from "components/input";
import { TypingLoading } from "components/ui/loading/typing-loading";
import { absoluteCenter } from "modules/themes/styleUtils";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { CharCounts } from "components/char-counts";

interface TextFieldContainerProps {
  children: React.ReactNode;
  name: string;
  value?: string | string[];
  infoComponent?: React.ReactElement<any, any>;
  infoTooltipText?: string;
  disableGenerate?: boolean;
  extraChildren?: React.ReactNode;
  boxProps?: Partial<BoxProps>;
  label?: string;
}

interface TextInputProps {
  onChange: (value: string | string[]) => void;
  value?: string;
  placeholder?: string;
}

export const TextInput = ({ value, onChange, placeholder }: TextInputProps) => {
  return (
    <PrimaryInput
      fullWidth
      size="small"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      type="text"
      multiline
    />
  );
};

const GenerateButton = ({
  onGenerate,
  label,
  collectionId,
  postId,
}: {
  label: string;
  onGenerate: (post: Post) => void;
}) => {
  const post = useSelector((state) =>
    selectPostByCollectionIdAndPostId(state, collectionId, postId)
  );
  if (!post) {
    return null;
  }
  return (
    <Tooltip title="Generate">
      <IconButton
        sx={{
          position: "absolute",
          right: 0,
          top: "50%",
          transform: "translateY(-50%)",
        }}
        onClick={() => onGenerate(post)}
        aria-label={label}
      >
        <RefreshIcon
          sx={{
            height: "16px",
            width: "16px",
            fill: "rgba(0, 0, 0, 0.3)",
          }}
          fontSize="small"
        />
      </IconButton>
    </Tooltip>
  );
};

export const TextFieldContainer = ({
  name,
  value,
  label,
  children,
  infoComponent,
  infoTooltipText,
  disableGenerate,
  extraChildren,
  onGenerate,
  isLoading,
  boxProps = {},
  characterCount,
  onClearAll,
  collectionId,
  postId,
}: TextFieldContainerProps & { onClearAll?: () => void }) => {
  return (
    <Box {...boxProps} className={styles["metadata-field-root"]}>
      <header className={styles["metadata-field-header"]}>
        <Box
          sx={{
            marginBottom: "8px",
            alignItems: "center",
            display: "flex",
            width: "100%",
          }}
          className={styles["metadata-field-header-left"]}
        >
          <Title>{label || startCase(name)}</Title>
          {infoTooltipText ? (
            <Tooltip placement="top" title={infoTooltipText}>
              {infoComponent || (
                <InfoOutlinedIcon
                  sx={{
                    marginLeft: "3px",
                    height: "15px",
                    width: "15px",
                    fill: "rgba(0, 0, 0, 0.3)",
                  }}
                />
              )}
            </Tooltip>
          ) : null}
          {typeof characterCount === "number" && (
            <CharCounts
              count={characterCount}
              sx={{
                marginLeft: "auto",
              }}
            />
          )}
        </Box>
        {extraChildren && extraChildren}
      </header>
      <Box
        sx={{
          position: "relative",
        }}
      >
        {children}
        {isLoading && (
          <TypingLoading
            boxProps={{
              sx: absoluteCenter,
            }}
            dotSize={5}
          />
        )}
        {disableGenerate ? null : (
          <Tooltip
            placement="top"
            title={
              typeof value !== "undefined" && value
                ? `Regenerate ${name}`
                : `Generate ${name}`
            }
          >
            <GenerateButton
              collectionId={collectionId}
              postId={postId}
              onGenerate={onGenerate}
              label={
                typeof value !== "undefined"
                  ? `Regenerate ${name}`
                  : `Generate ${name}`
              }
            />
          </Tooltip>
        )}
      </Box>
    </Box>
  );
};
