import React from "react";
import { tooManyRequestEventEmitter } from "utils/rate-limit";
import {
  OtherLimitReachedConfig,
  otherLimitEventEmitter,
  otherLimitEventId,
} from "utils/other-limit";
import { useOpenPopup } from "context/popup-context";
import { LimitReachedType } from "client-server-shared/types/limit";
import { useRateLimitModal } from "hooks/use-rate-limit-modal";

export const TooManyRequestListener = React.memo(() => {
  const { onRateLimitHit } = useRateLimitModal();
  const onOpenPopup = useOpenPopup();
  const onTooManyRequest = React.useCallback(() => {
    onRateLimitHit();
  }, []);

  const onOtherLimitReached = React.useCallback(
    (type: LimitReachedType, config?: OtherLimitReachedConfig) => {
      onOpenPopup("creditLimitReached", {
        type: type,
        description1: config?.description1 ? config?.description1 : undefined,
        description2: config?.description2 ? config?.description2 : undefined,
      });
    },
    []
  );

  React.useEffect(() => {
    otherLimitEventEmitter.on(otherLimitEventId, onOtherLimitReached);
    tooManyRequestEventEmitter.on("too-many-request", onTooManyRequest);

    return () => {
      try {
        tooManyRequestEventEmitter.off("too-many-request", onTooManyRequest);
        otherLimitEventEmitter.off(otherLimitEventId, onOtherLimitReached);
      } catch (e) {}
    };
  }, []);

  return null;
});
