import keyBy from "lodash/keyBy";

import clientTemplates from "templates/client-templates/templates";

const isDefined = (str?: string) => {
  return str && str.trim();
};

/*

const buildJson = (templates) => {
  const json: Record<string, string> = {};

  templates.forEach((template) => {
    rootFieldsToTranslate.forEach((rootField) => {
      const value = template[rootField];
      if (isDefined(value)) {
        json[value] = value;
      }
    });

    for (const key in template.fields) {
      const field = template.fields[key];

      fieldFieldsToTranslate.forEach((fieldField) => {
        const value = field[fieldField];
        if (isDefined(value)) {
          json[value] = value;
        }
      });
      const autoCompleteSuggestions = field.autoCompleteSuggestions;
      if (autoCompleteSuggestions && autoCompleteSuggestions.length > 0) {
        autoCompleteSuggestions.forEach((suggestion) => {
          json[suggestion.label] = suggestion.label;
        });
      }
    }
  });

  console.log("json", json);
};
buildJson(clientTemplates);
*/
const clientTemplatesById = keyBy(clientTemplates, "id");

export default clientTemplatesById;
