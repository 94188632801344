import { writingStylesConfig } from "client-server-shared/ai-config";
import { templateCategory, TemplateType } from "../types";

import {
  canGeneratePostContent,
  isDescriptionDefined,
  commonOnInValid,
  isKeywordsDefined,
  isPartialContentDefined,
} from "../validation";

const blogTemplate: TemplateType = {
  id: "blog-TeHqM",
  value: "blog",
  label: "Blog",
  slug: "blog",
  categories: [templateCategory.blog],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "blog",
  title: "Blog Post",
  contentTypeLabel: "blog",
  helpText: "Choose at least one keyword, description, or title to begin",
  defaultCollectionName: "Blog post collection",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your blog",
  defaultPostName: "Untitled Post",
  toGeneratorPrompt: "Need to generate more blog posts?",
  cardDescription:
    "Generate one or multiple SEO-optimized blog posts in seconds - all we need is some keywords",
  shortDescription: "All fields are <strong>optional</strong>",
  caption:
    "You can perform SEO optimization once the content of the articles are generated.",
  requiredFields: ["title"],
  fields: {
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a title",
      onValidate: (post) => {
        return !!(
          isDescriptionDefined(post) ||
          isPartialContentDefined(post) ||
          isKeywordsDefined(post)
        );
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt = "Generate a compelling title for an article";

        if (description) {
          prompt += ` with the description of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }

        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (content) {
          prompt += `. Here's some partial content from the article: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    writingStyle: {
      label: "Writing Style",
      fieldName: "writingStyle",
      optional: true,
      placeholder: "Eg: Informative, conversational",
    },
    description: {
      label: "Topic",
      fieldName: "description",
      required: true,
      description: " ",
      placeholder:
        "Example: 'You won't believe how easy it is to use AI to drive more traffic to a website'. \nFocus on the search intent of 'How to use AI to boost sales'",
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Friendly, formal",
    },
    targetAudience: {
      label: "Target audience",
      fieldName: "targetAudience",
      optional: true,
      placeholder: "Marketers, Students, Home owners, Parents etc",
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return canGeneratePostContent(post);
      },
      calculateCost: (config) => config.wordsCount || 800,
      onInValid: commonOnInValid,
      buildSystemPrompt: (post, func, bg) => {
        let prompt = `${
          bg
            ? `Here are some background info related to the content you're generating: "${bg}". `
            : ""
        }You will reply in Markdown formatting. Your reply will be uploaded onto Wordpress. You avoid using transitional words or phrases such as 'overall', "moreover", 'in conclusion', 'furthermore', 'finally' etc, instead you use other techniques to create a smooth flow of information between sections. You use formatting elements such as bullet points/numbered lists, italic, bold, blockquotes, and other type of proper formatting (eg: headings, subheadings) to organize your content for readability.`;
        return prompt;
      },
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const keywords = post.keywords;
        const title = post.title;
        let prompt = "Create a high-quality article";

        if (description) {
          prompt += `, whose description is "${description}"`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, with the writing style of "${
            writingStyle || writingStylesConfig.default
          }"`;
        }

        if (toneOfVoice) {
          prompt += `, with the tone of "${
            toneOfVoice || writingStylesConfig.default
          }"`;
        }

        if (keywords && keywords.length > 0) {
          prompt += `, incorporating the following keywords: "${keywords.join(
            ", "
          )}"`;
        }

        if (language) {
          prompt += `, write in ${language} language`;
        }

        if (wordsCount) {
          prompt += `. The article should be approximately ${wordsCount} words long`;
        }

        prompt += `\nAvoid using transitional words or phrases such as 'overall', "moreover", 'in conclusion', 'furthermore', 'finally' etc, instead you use other techniques to create a smooth flow of information between sections. Use formatting elements such as bullet points/numbered lists, italic, bold, blockquotes, and other type of proper formatting (eg: headings, subheadings) to organize your content for readability`;

        prompt +=
          "\nReturn the content in Markdown format without anything else in your response.";

        return prompt;
      },
    },
  },
  excludedFields: [],
};

export { blogTemplate };
