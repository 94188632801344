import { TemplateFieldType, TemplateType, templateCategory } from "../types";

import { templateCreator } from "../template-builder";
import { isPlainObject } from "client-server-shared/utils/lodash-methods";
import { GPT35 } from "client-server-shared/ai-config";

const createdTemplate = templateCreator({
  value: "summarizer",
  label: "summary",
  categories: [
    templateCategory.all,
    templateCategory.blog,
    templateCategory.custom,
    templateCategory.socialMedia,
  ],
  defaultWordsCount: 400,
  titleName: "title",
});

export const summarizerTemplate: TemplateType = {
  ...createdTemplate,
  contentType: "summary",
  contentTypeLabel: "summary",
  cardDescription:
    "Summarize up to 10,000 words of text into a list of bullet points.",
  shortDescription:
    "Summarize up to 10,000 words of text into a list of bullet points.",
  caption: "Summarize up to 10,000 words of text into a list of bullet points.",
  newStructure: true,
  tokenCount: 1000,
  freeLimit: 3,
  asTool: true,
  excludedFields: ["keywords"],
  addtionalFields: [],
  fields: {
    samples: {
      label: "Text",
      required: true,
      fieldName: "samples",
      wordsLimit: 10000,
      description: "Text to be summarized",
      placeholder: `Enter original text`,
      type: TemplateFieldType.TextArea,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        if (
          post.samples &&
          isPlainObject(post.samples) &&
          typeof post.samples !== "string"
        ) {
          return !!(
            (post.samples.text && post.samples.text.length > 0) ||
            (post.samples.sourceIds && post.samples.sourceIds.length > 0)
          );
        }
        return false;
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: () => {},
      getAIConfig: (config = {}) => {
        return {
          model: GPT35,
          temperature: 1,
        };
      },
      buildSystemPrompt: async (post) => {
        return "";
      },
      buildPrompt: async (post, getInfobaseContentBySourceIds) => {
        return "";
      },
    },
  },
};
