const variationsEnv = {
  dev: {
    proPlanYearly: [
      "price_1QPQcPHdDOhW2fAWlCd4Un8d",
      "price_1Oa3CGBbfgqwjqSrOjSVYNfv",

      "price_1QPQdUHdDOhW2fAWt6uOvOJv",
      "price_1Oa3EzBbfgqwjqSrp3J01bSV",

      "price_1QPQeLHdDOhW2fAWHbmjln4X",
      "price_1Oa3GtBbfgqwjqSrV8U2Mw8X",
    ],
    proPlanMonthly: [
      "price_1QPQbsHdDOhW2fAW5VibnaET",
      "price_1Oa2vWBbfgqwjqSrlkwJFSJg",

      "price_1QPQdDHdDOhW2fAWsf0MchIZ",
      "price_1Oa3ETBbfgqwjqSrztHja1J4",

      "price_1QPQe2HdDOhW2fAW5JF09wlI",
      "price_1Oa3GZBbfgqwjqSrvpvemSBz",
    ],
  },
  prod: {
    proPlanYearly: [
      "price_1QPQelHdDOhW2fAWFs2eq5sT", // new
      "price_1QKmG5BbfgqwjqSr0fsjvKNm",
      "price_1QPQelHdDOhW2fAW54OMPeIr", // new
      "price_1Oa3CbBbfgqwjqSrTrLRBHbw",
      "price_1OzROXBbfgqwjqSr7qy0iTHx", // for juliana@cannstrategy.com
      "price_1QPTUFHdDOhW2fAWloNHWMmA", // for juliana@cannstrategy.com

      "price_1QPQeoHdDOhW2fAW0WygVo4T", // new
      "price_1QKmK3BbfgqwjqSr2FF9Pn9o",
      "price_1Oa3FFBbfgqwjqSraYAeo63t",

      "price_1QPQeqHdDOhW2fAWK1uyalhQ", // new
      "price_1QKmLsBbfgqwjqSrccDbvBGO",
      "price_1Oa3GwBbfgqwjqSrzdpfokXg",
    ],
    proPlanMonthly: [
      "price_1QPQelHdDOhW2fAWfGh3wFyB", // new
      "price_1Oa3CbBbfgqwjqSrfCyRH9tB",

      "price_1QPQeoHdDOhW2fAWDI0vsfCx", // new
      "price_1Oa3rPBbfgqwjqSrwF8O9glL",

      "price_1QPQeqHdDOhW2fAW34acA0KF", // new
      "price_1Oa3GwBbfgqwjqSrfkfqFmPF",
    ],
  },
};
export const variationsPricesById: {
  proPlanYearly: string[];
  proPlanMonthly: string[];
} = variationsEnv[process.env.NEXT_PUBLIC_ENV_NAME];

const pricesEnv = {
  dev: {
    proPlanYearly: [
      "price_1QPQbKHdDOhW2fAWImSaAnGE",
      "price_1QG4ZhBbfgqwjqSr2nYbZceQ",
      "price_1ODW8zBbfgqwjqSrQD8hVOg5",
      ...variationsPricesById.proPlanYearly,
    ],
    proPlanMonthly: [
      "price_1QPQb0HdDOhW2fAWIoqJNWg8",
      "price_1QG4Z1BbfgqwjqSrpWxjctbv",
      "price_1ODW5GBbfgqwjqSr7k5QMkTM",
      ...variationsPricesById.proPlanMonthly,
    ],
    creatorPlanMonthlyNew: [
      "price_1QPQWKHdDOhW2fAWWJrQqYrU",

      "price_1QG4POBbfgqwjqSr1IfuPNdL",
      "price_1ODWeCBbfgqwjqSr5XMRQPoH",
    ],
    creatorPlanYearlyNew: [
      "price_1QPQWdHdDOhW2fAWnuXNzlD3",

      "price_1QG4QTBbfgqwjqSrTD6DygYY",
      "price_1ODWfCBbfgqwjqSrXP3unJpX",
    ],
    creatorPlanMonthly: ["price_1NmKF6BbfgqwjqSr0cGScqil"],
    proMonthlyLegacy: ["price_1NRFvDBbfgqwjqSrk1FUtzQg"],
    creatorYearly: ["price_1NmKFPBbfgqwjqSr4fyktUnS"],
    creatorYearlyLegacy: ["price_1NRFvDBbfgqwjqSrp1ZInCWJ"],
    unlimitedMonthly: ["price_1NmL98BbfgqwjqSrw7g9kwMK"],
    unlimitedMonthlyLegacy: [
      "price_1NRFWbBbfgqwjqSrLS04L4DJ",
      "price_1QPNftHdDOhW2fAWNANRoCGp",
      "price_1MqP1tBbfgqwjqSrN06SpKXE",
    ],
    unlimitedYearly: ["price_1NmLCOBbfgqwjqSr1JqlaBZF"],
    unlimitedYearlyLegacy: [
      "price_1NRFWbBbfgqwjqSrFMptpDyq",
      "price_1QPNgQHdDOhW2fAWk0P4Cd5J",
      "price_1MqP1tBbfgqwjqSrbAt2Ph8I",
    ],
    credits: [
      "price_1QPNi1HdDOhW2fAWGMSaZ9Id",
      "price_1N33zBBbfgqwjqSrW40MhxUh",
    ],
    workflows: [
      "price_1QPQZ3HdDOhW2fAWIl8ZONmf",
      "price_1NjgQ1BbfgqwjqSrj99GPHUi",
    ],
    trialFee: [
      "price_1QPQXVHdDOhW2fAW4x07Ik89",
      "price_1NaLt5BbfgqwjqSr0SHD1fzW",
    ],
    appSumoTier1: ["juniaai_tier1"],
    appSumoTier2: ["juniaai_tier2"],
    appSumoTier3: ["juniaai_tier3"],
    appSumoTier4: ["juniaai_tier4"],
  },
  prod: {
    proPlanYearly: [
      "price_1QPQejHdDOhW2fAWEz0yYX1O",
      "price_1QKmAFBbfgqwjqSrwYYJNq28",
      "price_1QG4ajBbfgqwjqSrecV0tOXM",
      "price_1QPQejHdDOhW2fAWdAUxffS5",
      "price_1ODW9NBbfgqwjqSrcUzKYg8o",
      ...variationsPricesById.proPlanYearly,
    ],
    proPlanMonthly: [
      "price_1QPQejHdDOhW2fAWFoJec34y",
      "price_1QG4aHBbfgqwjqSrX36VXQqj",
      "price_1QPQejHdDOhW2fAWH9gYW48u",
      "price_1ODW9NBbfgqwjqSr9g0kHt3j",
      ...variationsPricesById.proPlanMonthly,
    ],
    creatorPlanMonthlyNew: [
      "price_1QPQecHdDOhW2fAWBGyjr76m",
      "price_1QG4WEBbfgqwjqSrzJl0lO2H",
      "price_1QPQecHdDOhW2fAWwhGeNPxT",
      "price_1ODt1FBbfgqwjqSraQRtu2NT",
    ],
    creatorPlanYearlyNew: [
      "price_1QPQecHdDOhW2fAWcOqXGudP",
      "price_1QKmDtBbfgqwjqSriTKBTJgA",
      "price_1QG4XBBbfgqwjqSrTW7cGRh0",
      "price_1QPQecHdDOhW2fAWCy6gGUEz",
      "price_1ODt27BbfgqwjqSrSje4Wehp",
    ],
    creatorPlanMonthly: [
      "price_1QPtm2HdDOhW2fAWGKhsrYwO",
      "price_1NmKBSBbfgqwjqSrOXHiF84U",
    ],
    proMonthlyLegacy: [
      "price_1QPtnSHdDOhW2fAWzlQHFNZ7",
      "price_1NSKVwBbfgqwjqSrMRO4CGmr",
    ],
    creatorYearly: [
      "price_1QPtmoHdDOhW2fAWMPjE7VMY",
      "price_1NmKCyBbfgqwjqSrMmqBIYLO",
    ],
    creatorYearlyLegacy: [
      "price_1QPtobHdDOhW2fAWNQbfiWIH",
      "price_1NSKVwBbfgqwjqSrNkzI9ld8",
    ],
    unlimitedMonthly: ["price_1NmLNZBbfgqwjqSrnt0h2Gp2"],
    unlimitedMonthlyLegacy: [
      "price_1QPQeZHdDOhW2fAW82jbBjs9",
      "price_1QPQeUHdDOhW2fAWkqm14Bxm",
      "price_1NSKVdBbfgqwjqSrj0gJPb3Q",
      "price_1MriCuBbfgqwjqSrhwhUV1aq",
    ],
    unlimitedYearly: [
      "price_1QPQeZHdDOhW2fAWVOwgpL50",
      "price_1NmLOTBbfgqwjqSrv4ExUYEG",
    ],
    unlimitedYearlyLegacy: [
      "price_1QPQeUHdDOhW2fAWq2vyLJVN",
      "price_1NSKVdBbfgqwjqSrQsZAwRf9",
      "price_1MriCuBbfgqwjqSrJtvw9XaV",
    ],
    workflows: [
      "price_1QPQehHdDOhW2fAWMImeuUSg",
      "price_1NjiPPBbfgqwjqSr4MHVX6yz",
    ],
    credits: [
      "price_1QPQeXHdDOhW2fAW9Dyap9A9",
      "price_1N33xzBbfgqwjqSr1bwafoV9",
    ],
    trialFee: [
      "price_1QPQeeHdDOhW2fAWzy7LdJq1",
      "price_1NangWBbfgqwjqSrr4kwUQ7i",
    ],
    appSumoTier1: ["juniaai_tier1"],
    appSumoTier2: ["juniaai_tier2"],
    appSumoTier3: ["juniaai_tier3"],
    appSumoTier4: ["juniaai_tier4"],
  },
};

interface ById {
  proPlanYearly: string[];
  proPlanMonthly: string[];
  creatorPlanMonthlyNew: string[];
  creatorPlanYearlyNew: string[];
  creatorPlanMonthly: string[];
  creatorYearly: string[];
  creatorYearlyLegacy: string[];
  proMonthlyLegacy: string[];
  unlimitedMonthly: string[];
  unlimitedYearly: string[];
  unlimitedMonthlyLegacy: string[];
  unlimitedYearlyLegacy: string[];
  credits: string[];
  workflows: string[];
  trialFee: string[];
  appSumoTier1: string[];
  appSumoTier2: string[];
  appSumoTier3: string[];
  appSumoTier4: string[];
}

export const pricesById: ById = pricesEnv[process.env.NEXT_PUBLIC_ENV_NAME];
