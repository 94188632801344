import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Description, Typography } from "components/ui/Typography";
import { PrimaryInput } from "components/input";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Section, SectionTitle } from "modules/selections/common";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import { usePublishToMedium } from "./shared";
import {
  selectActiveContentByCollectionAndPostId,
  selectPostByCollectionIdAndPostId,
} from "selectors/collections";
import { useSelector } from "hooks/use-selector";
import protectedClientApi from "utils/protected-client-api";
import { textEllipsis } from "modules/themes/styleUtils";
import { MenuItem } from "components/ui/menu";
import { Select } from "components/ui/select";
import { useMounted } from "hooks/use-mounted";
import { useLoading } from "hooks/use-loading";
import { useNotifications } from "hooks/use-notification";
import { selectIsUserAdmin, selectUserDetails } from "selectors/user";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useCloseSelectedPopup } from "context/popup-context";
import { secondaryScrollbar } from "modules/themes/sytle-constants";
import { Tooltip } from "components/ui/tooltip";
import { Chip } from "components/ui/chip";
import truncate from "lodash/truncate";
import { SearchbarWithButton } from "components/search-bar";
import { InlineSpan } from "components/as-inline-span";
import { MetaDataField } from "client-server-shared/config/fieldMapping";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CircularProgress } from "components/ui/loading";
import {
  getContextText,
  strippedHtml,
} from "client-server-shared/utils/text-utils";
import { useActivePaidPlanPrompt } from "hooks/use-active-paid-plan-prompt";
import { IntegrationContextProvider, useIntegrationContext } from "../context";
import { IntegrationType } from "client-server-shared/constants";
import { IntegrationLayout } from "../layout";
import { PurplePinkButton } from "components/purple-button";
import SchedulePublish from "../schedule-publish";
import { SchedulePublishConfig } from "client-server-shared/types/workflow";

import { SourceType } from "utils/tracking/type";
import { useUserHasFeatures } from "hooks/use-features";
import { useRefreshCollection } from "hooks/use-load-collection";
import UpgradeRequired, { mediumBenefits } from "../upgrade-required";

const emptyArr = [];

const MediumPublishModal = ({
  postId,
  collectionId,
}: {
  postId: string;
  collectionId: string;
}) => {
  const onClose = useCloseSelectedPopup();

  const { config, onConfigChange } = usePublishToMedium();
  const dispatch = useAppDispatch();
  const userDetails = useSelector(selectUserDetails);
  const post = useSelector((state) =>
    selectPostByCollectionIdAndPostId(state, collectionId, postId)
  );
  const categories = post ? post[MetaDataField.Category] : emptyArr;
  const [tagValue, setTagValue] = React.useState("");
  const { onRefreshCollection } = useRefreshCollection();
  const publishing = useLoading();
  const categoriesLoading = useLoading();
  const { addSuccessNotification, addFailureNotification } = useNotifications();
  const activeContent = useSelector((state) =>
    selectActiveContentByCollectionAndPostId(state, collectionId, postId)
  );
  const [schedulePublishConfig, setSchedulePublishConfig] =
    React.useState<SchedulePublishConfig>({
      enabled: false,
      amount: 1,
    });
  const {
    updateIntegration,
    integrations,
    selectedIntegration,
    activeIntegrationId,
  } = useIntegrationContext();
  const userAdmin = useSelector(selectIsUserAdmin);

  const { userInActivePaidPlan, showPrompt } = useActivePaidPlanPrompt();

  const [publishConfig, setConfig] = React.useState({
    status: "public",
    tags: categories || emptyArr,
  });
  const { safeRunAsync, safeRun } = useMounted();

  const { hasIntegrationFeature } = useUserHasFeatures();

  const onPublishConfigChange = React.useCallback((key, value) => {
    setConfig((previous) => {
      return {
        ...previous,
        [key]: value,
      };
    });
  }, []);

  React.useEffect(() => {
    if (!activeIntegrationId) {
      setConfig({
        status: "draft",
        tags: categories || emptyArr,
      });
    }
  }, [activeIntegrationId]);

  const onPublish = async () => {
    if (!userInActivePaidPlan) {
      showPrompt();
      return;
    }
    if (publishing.isLoading) {
      return;
    }

    await safeRunAsync(async () => {
      publishing.onLoading();

      try {
        const updatedIntegration = await updateIntegration({
          name: config.name,
          medium_access_token: config.token,
        });
        const url = await protectedClientApi.publishToMedium({
          content: activeContent,
          mode: publishConfig.status,
          tags: publishConfig.tags,
          integrationId: updatedIntegration.id,
          postTitle: post?.title || "",
          postId: postId,
          schedulePublishConfig,
        });

        safeRun(() => {
          if (!schedulePublishConfig.publishedDate && url) {
            addSuccessNotification("Successfully published to Medium!");
            window.open(url, "_blank");
          } else {
            addSuccessNotification(
              "Successfully scheduled publishing to Medium!"
            );
          }
          publishing.onIdle();
          onClose();
        });
        await onRefreshCollection(collectionId);
      } catch (e) {
        addFailureNotification(
          "There's an error publishing to Medium. Please check if your access token is still valid."
        );
        publishing.onIdle();
      }
    });
  };

  const onGenerateCategories = async () => {
    if (categoriesLoading.isLoading) {
      return;
    }
    try {
      categoriesLoading.onLoading();
      const data = await protectedClientApi.generateCategories({
        content: strippedHtml(getContextText(activeContent, 700)),
      });
      onPublishConfigChange("tags", data);
      categoriesLoading.onIdle();
    } catch (e) {
      categoriesLoading.onIdle();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={integrations.length === 0 ? "sm" : "lg"}
      open
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          padding: "24 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                marginRight: "24px",
              }}
            >
              Publish to Medium
            </Typography>
          </Box>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Description
          sx={{
            maxWidth: "350px",
          }}
        >
          You can connect to multiple medium accounts and choose which medium
          blog to publish to. Note: Medium has a limit of 15 articles per day.
        </Description>
      </DialogTitle>
      <DialogContent>
        {hasIntegrationFeature ? (
          <IntegrationLayout>
            <Section>
              <SectionTitle
                description={`Give this integration a name so you can easily identify it.`}
              >
                Integration Name
              </SectionTitle>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "12px",
                }}
              >
                <PrimaryInput
                  size="small"
                  fullWidth
                  value={config.name}
                  onChange={(e) => onConfigChange("name", e.target.value)}
                  placeholder="Eg: Author Name's Medium Account"
                />
              </Box>
            </Section>
            <Section>
              <SectionTitle
                description={`You can find your medium access token via https://medium.com/me/settings/security and select the "Integration tokens" option to generate a token.`}
              >
                Access Token
              </SectionTitle>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "12px",
                }}
              >
                <PrimaryInput
                  size="small"
                  fullWidth
                  value={config.token}
                  onChange={(e) => onConfigChange("token", e.target.value)}
                  placeholder="Paste the generated access token token here."
                />
              </Box>
            </Section>
            <Section>
              <SectionTitle required>
                <InlineSpan inherit>Tags</InlineSpan>
                <InlineSpan
                  inherit
                  sx={{
                    color: (theme) =>
                      publishConfig.tags.length > 3
                        ? theme.palette.error.main
                        : "inherit",
                  }}
                >
                  ({publishConfig.tags.length}/3)
                </InlineSpan>
              </SectionTitle>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  columnGap: "6px",
                  rowGap: "6px",
                  maxHeight: "200px",
                  paddingBottom: "12px",
                  overflowY: "auto",
                  ...secondaryScrollbar,
                }}
              >
                {(publishConfig.tags || []).map((tag, index) => {
                  return (
                    <Tooltip
                      placement="left-start"
                      key={index}
                      title={tag.length > 50 ? tag : ""}
                    >
                      <Box>
                        <Chip
                          sx={{
                            background: "#E6E6FA",
                            userSelect: "auto",
                            cursor: "text",
                            "& svg": {
                              fill: "#9B4E97",
                            },
                          }}
                          onDelete={() => {
                            onPublishConfigChange(
                              "tags",
                              publishConfig.tags?.filter(
                                (currentTag) => tag !== currentTag
                              )
                            );
                          }}
                          label={
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: "12px",
                              }}
                            >
                              <InlineSpan
                                sx={{
                                  color: "#9B4E97",
                                  fontSize: "12px",
                                }}
                              >
                                {truncate(tag, {
                                  length: 50,
                                  separator: "...",
                                })}
                              </InlineSpan>
                            </Box>
                          }
                          size="small"
                        />
                      </Box>
                    </Tooltip>
                  );
                })}
              </Box>
              <SearchbarWithButton
                multiline
                variant="primary"
                tooltip="Generate"
                maxRows={5}
                onChange={(e) => {
                  setTagValue(e.target.value);
                }}
                value={tagValue}
                onButtonClick={(e, value) => {
                  if (value && value.trim()) {
                    onPublishConfigChange("tags", [
                      value,
                      ...(publishConfig.tags || []),
                    ]);
                    setTagValue("");
                  }
                }}
                icon={
                  <IconButton
                    sx={{
                      padding: 0,
                    }}
                    onClick={() => onGenerateCategories()}
                  >
                    {categoriesLoading.isLoading ? (
                      <CircularProgress size={16} />
                    ) : (
                      <RefreshIcon
                        sx={{
                          height: "16px",
                          width: "16px",
                          fill: "rgba(0, 0, 0, 0.3)",
                        }}
                        fontSize="small"
                      />
                    )}
                  </IconButton>
                }
                buttonProps={{
                  sx: {
                    borderRadius: "4px",
                    padding: "2px 4px",
                  },
                }}
                sx={{
                  width: "100%",
                  "& textarea": {
                    ...secondaryScrollbar,
                  },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "12px",
                    "&:hover fieldset": {
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                    },
                  },
                }}
                placeholder="Eg: AI. Press Enter to add manually or generate with AI."
              />
            </Section>
            <Section>
              <SectionTitle>Mode</SectionTitle>
              <Select
                size="small"
                onChange={(e, newValue) => {
                  onPublishConfigChange("status", e.target.value);
                }}
                value={publishConfig.status}
              >
                {["public", "draft"].map((status) => {
                  return (
                    <MenuItem key={status} value={status}>
                      <Typography
                        variant="body2"
                        sx={{
                          ...textEllipsis,
                          maxWidth: "100px",
                          fontSize: "12px",
                        }}
                      >
                        {status}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </Section>

            <SchedulePublish
              singleArticle
              schedulePublishConfig={schedulePublishConfig}
              onChange={setSchedulePublishConfig}
            />

            {!selectedIntegration ? null : (
              <Description
                sx={{
                  marginBottom: "12px",
                }}
              >
                This will publish the article to {selectedIntegration.name}
              </Description>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <PurplePinkButton
                disabled={
                  !hasIntegrationFeature ||
                  Object.keys({ ...config, ...publishConfig }).some(
                    (key) => ({ ...config, ...publishConfig }[key].length === 0)
                  )
                }
                loading={publishing.isLoading}
                onClick={async () => {
                  onPublish();
                }}
              >
                Publish
              </PurplePinkButton>
            </Box>
          </IntegrationLayout>
        ) : (
          <UpgradeRequired
            source={SourceType.MediumIntegrationModal}
            benefits={mediumBenefits}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

const MediumPublishModalContainer = ({
  postId,
  collectionId,
}: {
  postId: string;
  collectionId: string;
}) => {
  return (
    <IntegrationContextProvider inPublishMode type={IntegrationType.Medium}>
      <MediumPublishModal postId={postId} collectionId={collectionId} />
    </IntegrationContextProvider>
  );
};

export default MediumPublishModalContainer;
