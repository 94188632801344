import { serverRoutes } from "client-server-shared/config/routes";
import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import React from "react";
import { SolidCallToAction } from "./call-to-action";
import { Container, ResponsiveText } from "./front-page/shared";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useRouter } from "next/router";
import { graidentTextStyle } from "modules/non-protected-page/heros/shared";
import { InlineSpan } from "components/as-inline-span";

import CountDownTimer from "components/coutdown/CountDownTimer";
import { blackFridayOneWeekAfter } from "client-server-shared/promotion";

const LightBulk = () => {
  return (
    <Box
      component="svg"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 19 35"
      fill="none"
      sx={{
        position: "absolute",
        height: "24px",
        width: "24px",
        left: "-30px",
        top: 0,
        display: {
          xs: "none",
          sm: "initial",
          md: "initial",
          lg: "initial",
        },
      }}
    >
      <path
        d="M7.59297 34.2008H5.69292L7.59297 20.9005H0.942815C-0.15921 20.9005 -0.140209 20.2925 0.220799 19.6464C0.581807 19.0004 0.315801 19.4944 0.353802 19.4184C2.80486 15.0863 6.49094 8.62619 11.3931 0H13.2931L11.3931 13.3003H18.0432C18.9742 13.3003 19.1072 13.9273 18.9362 14.2693L18.8032 14.5543C11.317 27.6456 7.59297 34.2008 7.59297 34.2008Z"
        fill="white"
      />
    </Box>
  );
};

export const PircingPromotionBanner = React.memo(() => {
  return (
    <Box
      sx={{
        borderTopLeftRadius: "10px",
        padding: {
          xs: "12px 12px",
          sm: "12px 34px",
          md: "12px 34px",
          lg: "12px 34px",
        },
        position: "relative",
        overflow: "hidden",
        columnGap: "48px",
        rowGap: "6px",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        },
        "&:after": {
          content: "''",
          position: "absolute",
          top: 0,
          background: "#39125F",
          left: 0,
          height: "100%",
          width: "100%",
          zIndex: 1,
          borderTopRightRadius: "15px",
          transform: "skewX(10deg)",
          transformOrigin: "100% 100%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          columnGap: "24px",
          position: "relative",
          zIndex: 2,
          padding: {
            xs: "0",
            sm: "0",
            md: "0",
            lg: "0 24px",
          },
          rowGap: "12px",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "6px",
            position: "relative",
          }}
        >
          <LightBulk />
          <ResponsiveText
            fontSize={16}
            sx={{
              fontWeight: 600,
              color: "text.white",
              maxWidth: "600px",
              marginRight: "auto",
            }}
          >
            <InlineSpan inherit>
              New Year Deal: 6 MONTHS FREE ON ANNUAL PLANS (50%
              OFF).
            </InlineSpan>
          </ResponsiveText>
        </Box>
      </Box>
    </Box>
  );
});

export const PaidTrailBanner = React.memo(() => {
  return (
    <Box
      sx={{
        borderTopLeftRadius: "10px",
        padding: {
          xs: "12px 12px",
          sm: "12px 34px",
          md: "12px 34px",
          lg: "12px 34px",
        },
        position: "relative",
        overflow: "hidden",
        columnGap: "48px",
        rowGap: "6px",
        flexDirection: {
          xs: "column",
          sm: "column",
          md: "column",
          lg: "row",
          xl: "row",
        },
        "&:after": {
          content: "''",
          position: "absolute",
          top: 0,
          background: "#39125F",
          left: 0,
          height: "100%",
          width: "100%",
          zIndex: 1,
          borderTopRightRadius: "15px",
          transform: "skewX(10deg)",
          transformOrigin: "100% 100%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "center",
          columnGap: "24px",
          position: "relative",
          zIndex: 2,
          padding: {
            xs: "0",
            sm: "0",
            md: "0",
            lg: "0 24px",
          },
          rowGap: "12px",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "6px",
            position: "relative",
          }}
        >
          <LightBulk />
          <ResponsiveText
            fontSize={16}
            sx={{
              fontWeight: 600,
              color: "text.white",
              maxWidth: "600px",
              marginRight: "auto",
            }}
          >
            <InlineSpan inherit>
              Try Junia with a 5-day trial for only{" "}
            </InlineSpan>
            <InlineSpan
              inherit
              sx={{
                color: "rgba(233, 98, 255, 1)",
              }}
            >
              $2,99
            </InlineSpan>
            <InlineSpan inherit>. Cancel anytime.</InlineSpan>{" "}
          </ResponsiveText>
        </Box>
      </Box>
    </Box>
  );
});

const PopupBanner2 = React.memo(() => {
  const [open, setOpen] = React.useState(true);
  const router = useRouter();
  const active = false;
  if (!active) {
    return null;
  }
  if (!open) {
    return null;
  }
  return (
    <Box
      sx={{
        background: "#111827",
        padding: "12px",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          position: "relative",
          alignItems: "center",
          paddingTop: "12px",
          borderRadius: "6px",
          paddingBottom: "12px",
          justifyContent: "space-between",
          columnGap: "48px",
          rowGap: "6px",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "column",
            lg: "row",
            xl: "row",
          },
          borderTopLeftRadius: "16px",
          borderTopRightRadius: "16px",
          background: "#E6E6FA",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "center",
            columnGap: "24px",
            padding: {
              xs: "0",
              sm: "0",
              md: "0",
              lg: "0 24px",
            },
            rowGap: "12px",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "6px",
            }}
          >
            <ResponsiveText
              fontSize={16}
              sx={{
                color: "text.black",
                marginRight: "auto",
              }}
            >
              <InlineSpan
                inherit
                sx={{
                  fontWeight: "700!important",
                }}
              >
                Special Black Friday Deal
              </InlineSpan>
              <InlineSpan inherit>
                {" "}
                – Limited time offer for total savings of{" "}
              </InlineSpan>

              <InlineSpan
                inherit
                sx={{
                  ...graidentTextStyle,
                  fontWeight: "700!important",

                  background:
                    "linear-gradient(to right, #D400BF, rgba(76, 56, 198, 0.85) 143.99%, rgba(8, 8, 8, 0) 100%)",
                }}
              >
                50% {""}
              </InlineSpan>
              <InlineSpan
                inherit
                sx={{
                  fontWeight: 400,
                }}
              >
                on Annual Plans. Using the code:{" "}
              </InlineSpan>
              <InlineSpan
                inherit
                sx={{
                  fontWeight: "700!important",
                }}
              >
                JUNIAFRIDAY
              </InlineSpan>
            </ResponsiveText>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "48px",
              paddingRight: "24px",
              justifyContent: "space-between",
              rowGap: "12px",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
            }}
          >
            <CountDownTimer targetDate={blackFridayOneWeekAfter} />
          </Box>
          {/*
            <PurplePinkButton
            component={Link}
            onClick={() => {
              setOpen(false);
            }}
            sx={{
              margin: 0,
              padding: "7px 24px",
              borderRadius: "22px",
              background: "#C326FA",
              marginRight: "24px",
              fontSize: "14px",
              fontWeight: 600,
            }}
            href={serverRoutes.pricing}
          >
            {t("Go Unlimited now")}
          </PurplePinkButton>
            */}
        </Box>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
          aria-label={"Close"}
          sx={{
            position: "absolute",
            padding: 0,
            border: ".5px solid #FFFFFF",
            opacity: 0.5,
            top: {
              xs: "initial",
              sm: "initial",
              md: "10px",
              lg: "10px",
              xl: "10px",
            },
            bottom: {
              xs: "10px",
              sm: "10px",
              md: "initial",
              lg: "initial",
              xl: "initial",
            },
            right: "10px",
            "&:hover": {
              border: ".5px solid transparent",
            },
          }}
        >
          <CloseOutlinedIcon
            sx={{
              height: "20px",
              width: "20px",
            }}
          />
        </IconButton>
      </Container>
    </Box>
  );
});

const PopupBanner = React.memo(() => {
  return <PopupBanner2 />;
});

export default PopupBanner;
