import { useGetFieldConfig } from "context/selected-template-context";
import {
  usePostMeta,
  usePostSourceIds,
} from "modules/collection-by-id/context/post-context";
import onGenerateContent from "redux-middlewares/generate-content";
import onGenerateMeta from "redux-middlewares/generate-meta";
import { selectPostByCollectionIdAndPostId } from "selectors/collections";
import {
  getContextText,
  strippedHtml,
} from "client-server-shared/utils/text-utils";
import { Post } from "client-server-shared/types/types";
import { useAppDispatch } from "./use-app-dispatch";
import { useFieldLoadingStatus } from "./use-generate-fields";
import { useNotifications } from "./use-notification";
import { useSelector } from "./use-selector";
import { usePostLayoutContext } from "modules/collection-by-id/context/layout-context";

export const buildGenearteConfig = (post: Post, content?: string) => {
  const config = {
    ...(post?.postConfig || {}),
    title: post.title,
  };
  if (content && content.length > 0) {
    config["content"] = strippedHtml(getContextText(content, 1500));
  } else {
    let firstContent =
      post.content && post.content.length > 0 ? post.content[0]?.value : "";
    config["content"] = strippedHtml(getContextText(firstContent, 1500));
  }
  return config;
};

export const useGeneratePostField = (fieldName: string) => {
  const { collectionId, postId } = usePostMeta();
  const { onValidate, onInValid } = useGetFieldConfig(fieldName);

  const notificationApi = useNotifications();
  const dispatch = useAppDispatch();
  const { isLoading } = useFieldLoadingStatus(postId, fieldName);

  const onGeneratePostField = (post: Post) => {
    if (isLoading) {
      return;
    }
    const valid = post && onValidate(buildGenearteConfig(post));
    if (!valid) {
      onInValid({
        post,
        notificationApi,
      });
      return;
    }

    dispatch(onGenerateMeta(collectionId, postId, fieldName));
  };

  return { onGeneratePostField, isLoading };
};

export const useGeneratePostContent = () => {
  const { collectionId, postId } = usePostMeta();
  const { sourceIds } = usePostSourceIds();
  const post = useSelector((state) =>
    selectPostByCollectionIdAndPostId(state, collectionId, postId)
  );
  const { setSelectedMobileTab } = usePostLayoutContext();

  const { isLoading } = useFieldLoadingStatus(postId, "content");
  const notificationApi = useNotifications();
  const dispatch = useAppDispatch();

  const { onValidate, onInValid } = useGetFieldConfig("content");

  const onGeneratePostContent = async () => {
    if (isLoading) {
      return;
    }
    const valid = post && onValidate(buildGenearteConfig(post));
    if (!valid) {
      onInValid({
        post,
        notificationApi,
      });
    } else {
      dispatch(
        onGenerateContent(collectionId, postId, {
          sourceIds,
        })
      );
      setSelectedMobileTab("editor");
    }
  };
  return { onGeneratePostContent, isLoading };
};
