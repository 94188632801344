export enum SidebarTab {
  General = "Generator",
  Seo = "SEO",
  Advanced = "Advanced",
  Templates = "Templates",
  AIImage = "AI Image",
  AIDetect = "AI Detector",
  Citation ="Citation",
  Improver = "Improver",
}
