export const removeQuotesFromText = (title?: string) => {
  if (!title) {
    return title;
  }
  let formatedTitle = title;
  if (title.startsWith('"') && title.endsWith('"')) {
    formatedTitle = formatedTitle.slice(1);
    formatedTitle = formatedTitle.slice(0, -1);
  } else if (title.startsWith("'") && title.endsWith("'")) {
    formatedTitle = formatedTitle.slice(1);
    formatedTitle = formatedTitle.slice(0, -1);
  }
  return formatedTitle;
};
