import { getStyleProps, passProps } from "modules/themes/styleUtils";

import MuiSwitch, { SwitchProps } from "@mui/material/Switch";

import React from "react";

const Switch = (props: SwitchProps) => {
  const sx = getStyleProps(props).sx;
  return (
    <MuiSwitch
      {...passProps(props)}
      sx={{
        ...sx,
        color: "rgb(16, 175, 171)!important",
        "& .Mui-checked": {
          color: "rgb(16, 175, 171)!important",
        },
        "& .MuiSwitch-track": {
          backgroundColor: "rgb(16, 175, 171)!important",
        },
      }}
    />
  );
};

export default Switch;
