import {
  getTypographicRuleByLanguage,
  languagesExampleByLanguage,
} from "client-server-shared/constants";
import {
  buildMetaDescriptionPrompt,
  buildMetaTitlePrompt,
} from "../server-templates/prompt-builder";
import { createOnInvalidFunc, isPartialContentDefined } from "./validation";

export const addMetaBuilder = () => {
  return {
    categories: {
      label: "Categories",
      fieldName: "categories",
      onValidate: isPartialContentDefined,
      onInValid: createOnInvalidFunc(),
      calculateCost: () => 500,
      buildPrompt: (post) => {
        const language = post.language;
        const title = post.title;
        const content = post.content;

        let prompt = "Categorize the following based on";

        if (content) {
          prompt += `, with the following partial content "${content}",`;
        }

        if (language) {
          const selectedExampleLanguage =
            language && languagesExampleByLanguage[language]
              ? `(${
                  languagesExampleByLanguage[language]
                })${getTypographicRuleByLanguage(language)}`
              : "";
          prompt += `, write in ${language} language${selectedExampleLanguage}`;
        }

        prompt += `. List the categories as an array of strings: ["category1", "category2", "category3", ...] without anything else in your response.`;

        return prompt;
      },
    },
    tags: {
      label: "Tags/Keywords",
      fieldName: "tags",
      placeholder: "Type and press enter to add",
      onValidate: isPartialContentDefined,
      onInValid: createOnInvalidFunc(),
      calculateCost: () => 100,
      buildPrompt: (post) => {
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;
        let prompt =
          "Extract a list of 5-10 important keywords from an article";

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content from the article: ${content}`;
        }
        prompt +=
          '. List the keywords as an array of strings: ["keyword1", "keyword2", "keyword3", ...].';
        return prompt;
      },
    },
    metaDescription: {
      label: "Meta Description",
      fieldName: "metaDescription",
      onValidate: isPartialContentDefined,
      onInValid: createOnInvalidFunc(),
      calculateCost: () => 200,
      buildPrompt: buildMetaDescriptionPrompt,
    },
    metaTitle: {
      label: "Meta Title",
      fieldName: "metaTitle",
      calculateCost: () => 150,
      onValidate: isPartialContentDefined,
      onInValid: createOnInvalidFunc(),
      buildPrompt: buildMetaTitlePrompt,
    },
    wordsCount: {
      label: "Words",
      fieldName: "wordsCount",
      info: "Keep in mind that the final output length may vary based on factors such as topic complexity and input provided",
    },
    date: {
      label: "Date",
      info: "Specifying a date for your content can help improve SEO by ensuring your content is considered up-to-date and relevant. This can reduce the chances of incurring penalties from Google for outdated or duplicate content.",
      fieldName: "date",
    },
    keywords: {
      label: "Keywords",
      fieldName: "keywords",
      optional: true,
      placeholder: "Type relevant keywords and press enter",
    },
  };
};
