let markdownToHtmlPromise: Awaited<
  typeof import("./get-text-as-markdown")
> | null = null;

const markdownToHtml = async (text: string) => {
  if (!markdownToHtmlPromise) {
    markdownToHtmlPromise = await import("./get-text-as-markdown");
  }
  return markdownToHtmlPromise.default(text);
};

export const getMarkdownToHtmlFunc = async () => {
  if (!markdownToHtmlPromise) {
    markdownToHtmlPromise = await import("./get-text-as-markdown");
  }
  return markdownToHtmlPromise.default;
};

export default markdownToHtml;
