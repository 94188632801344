import { templateCategory, TemplateType } from "../types";

import {
  isDescriptionDefined,
  createOnInvalidFunc,
  isPartialContentDefined,
  isPurposeDefined,
  isTitleDefined,
} from "../validation";

export const productPromotionTemplate: TemplateType = {
  id: "product-promotion-251dy",
  value: "product-promotion-251dy",
  label: "product-promotion",
  slug: "product-promotion",
  categories: [templateCategory.marketing, templateCategory.ecommerce],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "product promotion",
  title: "Product Promotion",
  defaultWordsCount: 300,
  contentTypeLabel: "product promotion",
  helpText: "Choose at least one post topic to begin",
  defaultCollectionName: "Product Promotion collection",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your Product Promotion",
  defaultPostName: "Untitled Product Promotion",
  toGeneratorPrompt: "Need to generate more product promotions?",
  cardDescription:
    "Generate eye-catching and engaging product promotions for your social media platforms or other channels",
  shortDescription: "All fields are <strong>optional</strong>",
  caption:
    "You can edit and customize the generated content to better suit your specific platform and audience.",
  addtionalFields: [
    {
      label: "Product description",
      fieldName: "purpose",
      required: true,
      placeholder:
        "Example: Junia AI is an all-in-one content creation platform, create a full-fledged article from a simple idea within minites",
    },
  ],
  requiredFields: ["title"],
  excludedFields: ["editorialImage", "startFromOutline", "writingStyle"],
  fields: {
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a title for your promotion",
      onValidate: (post) => {
        return !!(
          isDescriptionDefined(post) ||
          isPartialContentDefined(post) ||
          isPurposeDefined(post)
        );
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;
        const purpose = post.purpose;

        let prompt =
          "Generate a compelling title for a promotion for a product";

        if (purpose) {
          prompt += `, with the description of: ${purpose}`;
        }

        if (description) {
          prompt += `, some background info about the promotion: ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }
        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (content) {
          prompt += `. Here's some partial content from the post: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },

    description: {
      label: "Promotion Details",
      fieldName: "description",
      required: true,
      placeholder: `Example: Get 20% off on all orders above $50 with code XYZ20`,
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Excitement and Enthusiasm, Urgency and Scarcity",
      autoCompleteSuggestions: [
        "Excitement and Enthusiasm",
        "Urgency and Scarcity",
        "Personal and Relatable",
        "Humorous and Playful",
      ].map((v) => ({ label: v, value: v })),
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!(
          isTitleDefined(post) ||
          isPurposeDefined(post) ||
          isDescriptionDefined(post)
        );
      },
      calculateCost: (config) => config.wordsCount || 250,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const toneOfVoice = post.toneOfVoice;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const purpose = post.purpose;
        const targetAudience = post.targetAudience;
        let prompt =
          "Create a catchy and engaging product promotion based on the provided information";

        if (purpose) {
          prompt += `, with the description of: ${purpose}`;
        }

        if (description) {
          prompt += `, some background info about the promotion: ${description}`;
        }

        if (toneOfVoice) {
          prompt += `, using a ${toneOfVoice} tone of voice`;
        }

        if (targetAudience) {
          prompt += `, resonates with the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += `. The promotion should be approximately ${wordsCount} words long`;
        }

        prompt +=
          ". Please ensure it builds excitement, anticipation and it's shareable";

        return prompt;
      },
    },
  },
};
