import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";
const createdTemplate = templateCreator({
  value: "book idea",
  categories: [templateCategory.entertainment],
  defaultWordsCount: 500,
  titleName: "title",
});

export const bookIdeaTemplate = {
  ...createdTemplate,
  contentType: "book idea",
  contentTypeLabel: "book idea",
  helpText:
    "Provide a brief description of your book idea and its intended audience",
  cardDescription:
    "Generate a compelling and unique book idea to get you started on your next bestseller",
  shortDescription:
    "Create a unique and captivating book idea to kickstart your writing process",
  caption:
    "Develop a book idea that will capture the imagination of your readers and leave a lasting impact",
  excludedFields: ["toneOfVoice", "editorialImage", "startFromOutline"],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: `Enter or generate a title for your book idea`,
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;

        let prompt = `Generate a suitable title for your book idea based on the provided information:`;

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, aimed at ${targetAudience}`;
        }

        if (language) {
          prompt += `, written in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Details",
      fieldName: "description",
      description: " ",
      required: true,
      placeholder: `[Brief description of your book idea] Example: After a global pandemic wipes out most of humanity, a small group of survivors must navigate the new post-apocalyptic world while also uncovering the truth behind the virus and its origins. Along the way, they face dangerous obstacles, unexpected allies, and difficult moral dilemmas that challenge their beliefs and force them to question what it truly means to be human.`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 500,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;
        const title = post.title;
        const writingStyle = post.writingStyle;

        let prompt = `Develop a book idea further based on the provided details about the book below:`;

        if (description) {
          prompt += `Details: ${description}`;
        }

        if (targetAudience) {
          prompt += `. Intended audience: ${targetAudience}`;
        }
        if (writingStyle) {
          prompt += `, in a ${writingStyle} writing style`;
        }

        if (language) {
          prompt += `. Language: ${language}`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
