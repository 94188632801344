import React from "react";

import { Box } from "components/ui/box";
import { Menu, MenuItem } from "components/ui/menu";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";

import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useShortId } from "hooks/use-short-id";
import { WordPressIcon } from "client-server-shared/icons/word-press-icon";
import SelfHostWordPressConnectModal from "./self-host-wordpress-connect";
import { useStartWordpressOAuth } from "hooks/usewordpress";
import { Tooltip } from "components/ui/tooltip";

const ITEM_HEIGHT = 48;

const AddNewWordPressButton = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const menuId = useShortId();
  const menuId2 = useShortId();

  const [selfHostedOpen, setSelfHostedOpen] = React.useState(false);

  const { onInitAuthorizedProcess } = useStartWordpressOAuth();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: "12px",
      }}
    >
      <OutlineGreyButton
        aria-label="more"
        id={menuId}
        aria-controls={open ? menuId2 : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        startIcon={<AddOutlinedIcon />}
      >
        Connect To More WordPress Sites
      </OutlineGreyButton>

      <Menu
        id={menuId2}
        MenuListProps={{
          "aria-labelledby": menuId,
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {[
          {
            text: "WordPress.org",
            description: `Connect to your self-hosted WordPress site.`,
            icon: (
              <WordPressIcon
                sx={{
                  fill: "rgb(58, 80, 107)",
                  height: "17px",
                  width: "17px",
                }}
              />
            ),
            onClick: (e) => {
              setSelfHostedOpen(true);
              handleClose();
            },
          },
          {
            text: "WordPress.com",
            icon: (
              <WordPressIcon
                sx={{
                  fill: "rgb(30 111 212)",
                  height: "17px",
                  width: "17px",
                }}
              />
            ),
            onClick: (e) => {
              onInitAuthorizedProcess();
              handleClose();
            },
          },
        ].map((option) => (
          <MenuItem
            sx={{
              padding: 0,
              "& button": {
                textTransform: "none",
              },
            }}
            key={option.text}
            selected={false}
            onClick={handleClose}
          >
            <Tooltip title={option?.description || ""}>
              <OutlineGreyButton
                asText
                onClick={option.onClick}
                startIcon={option.icon}
                sx={{
                  width: "100%",
                  textTransform: "none",
                  justifyContent: "flex-start",
                }}
              >
                {option.text}
              </OutlineGreyButton>
            </Tooltip>
          </MenuItem>
        ))}
      </Menu>
      {selfHostedOpen && (
        <SelfHostWordPressConnectModal
          onClose={() => {
            setSelfHostedOpen(false);
          }}
        />
      )}
    </Box>
  );
};

export default AddNewWordPressButton;
