import { templateCategory, TemplateType } from "../types";
import {
  isDescriptionDefined,
  isPartialContentDefined,
  isPurposeDefined,
} from "../validation";

const customTemplate: TemplateType = {
  id: "custom-XSO6C",
  value: "custom-XSO6C",
  label: "Custom",
  slug: "custom",
  categories: [templateCategory.custom],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "content",
  title: "Freestyle",
  contentTypeLabel: "content",
  helpText: "Choose at least one keyword, description, or title to begin",
  defaultCollectionName: "Document collection",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your content",
  defaultPostName: "Untitled Document",
  toGeneratorPrompt: "Need to generate more content?",
  cardDescription: "Command Junia to generate anything for you",
  shortDescription: "All fields are <strong>optional</strong>",
  caption: "",
  addtionalFields: [
    {
      label: "What do you wanna generate?",
      fieldName: "purpose",
      required: true,
      placeholder: `Examples: 
- A short essay on the topic of climate change.
- A list of healthy meal prep ideas for busy professionals.
- A product description for my new line of sustainable clothing.`,
    },
  ],
  excludedFields: [
    "targetAudience",
    "keywords",
    "writingStyle",
    "editorialImage",
    "startFromOutline",
  ],
  fields: {
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a title",
      onValidate: (post) => {
        return !!(
          isDescriptionDefined(post) ||
          isPartialContentDefined(post) ||
          isPurposeDefined(post)
        );
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt = "Generate a compelling title for a piece of content";

        if (description) {
          prompt += ` with the description of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, with the writing style of ${writingStyle}`;
        }

        if (toneOfVoice) {
          prompt += `, with the tone of voice of ${toneOfVoice}`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }
        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },

    description: {
      label: "Background Information",
      fieldName: "description",
      placeholder: `Examples:
- Provides tips to improve workplace productivity
- Include statistics, success stories from other companies`,
      description: " ",
      optional: true,
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      placeholder: "Friendly, formal",
      optional: true,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!(isPurposeDefined(post) || isDescriptionDefined(post));
      },
      calculateCost: (config) => config.wordsCount || 800,
      onInValid: () => {},
      buildSystemPrompt: (fields, func, bg) => {
        let prompt = `As an AI, your task is to carefully interpret the user's request. Identify the type of content they want (e.g., ad, blog post, social media post) and the topic from their input. Then generate content appropriately formatted and with a suitable length for that type. 

For instance: 
If it's an ad, use persuasive language and a catchy format. 
If it's a blog post, write in markdown format with engaging headers and subheaders and aim for a comprehensive,long-form response. All other types of content should be in plain text.
If it's a social media post, keep it brief, engaging, and suitable for casual interaction on platforms like Facebook or Twitter.
If it's an email, maintain a professional tone with clear and concise sentences; ensure the message is polite and straightforward.
If it's a report, use formal language, include necessary details, and structure the content into sections with headers.
If it's a product description, highlight the features and benefits of the product using persuasive language.
If it's a review, provide detailed feedback about the product or service, including both positive aspects and areas for improvement.
If it's a script for video or play, write in dialogue format with character names followed by their lines.

You will write in markdown format.

The generated content will be uploaded straight onto the suitable platform.
${
  bg
    ? `\n Here are some background info related to the content you're generating: "${bg}".`
    : ""
}
        
You will reply the generated content only without anything in your response.
        `;
        return prompt;
      },
      buildPrompt: (post) => {
        const description = post.description;
        const toneOfVoice = post.toneOfVoice;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const purpose = post.purpose;

        let prompt = `Generate custom content based on the provided information.`;

        if (description) {
          prompt += `. Background information/Instructions: ${description}`;
        }

        if (purpose) {
          prompt += `. What to generate: ${purpose}`;
        }

        if (language && language !== "English") {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          //  prompt += `, the written content should aim for around ${wordsCount} words if appropriate.`;
        }

        //   prompt += `\nAvoid using transitional words or phrases such as 'overall', "moreover", 'in conclusion', 'furthermore', 'finally' etc, instead you use other techniques to create a smooth flow of information between sections. Use formatting elements such as blockquotes, bullet points/numbered lists, italic, bold, and other type of proper formatting (eg: headings, subheadings) to organize your content for readability`;

        prompt +=
          ". The generated content should not include any reference to the structure or formatting of this prompt. Return the content without anything else in your response.";

        return prompt;
      },
    },
  },
};

export { customTemplate };
