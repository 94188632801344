import React from "react";

import { useSelector } from "hooks/use-selector";
import { Box } from "components/ui/box";
import { Description, Typography } from "components/ui/Typography";

import { IconButton } from "components/ui/button";
import { Tooltip } from "components/ui/tooltip";
import { textEllipsis } from "../themes/styleUtils";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Divider } from "components/ui/divider";
import { useOpenPopup } from "context/popup-context";
import protectedClientApi from "utils/protected-client-api";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { captureException } from "utils/error-catching/lazy-sentry";
import { useIntegrationContext } from "./context";
import { IntegrationServer } from "client-server-shared/types/types";
import { integrationDeleted } from "reducers/user";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import { SectionTitle } from "modules/selections/common";
import { IconComponent } from "components/icon-component";
import { useShortId } from "hooks/use-short-id";
import { IntegrationType } from "client-server-shared/constants";
import { secondaryScrollbar } from "modules/themes/sytle-constants";
import AddNewWordPressButton from "./wordpress/add-new-wordpress";

const IntegrationRenderer = (integration: IntegrationServer) => {
  const onOpenPopup = useOpenPopup();
  const dispatch = useAppDispatch();
  const {
    setActiveIntegrationId,
    onCustomEditPost,
    activeIntegrationId,
    inPublishMode,
    type,
    disableEdit,
    ...rest
  } = useIntegrationContext();
  const isWordpress = type === IntegrationType.WordPress;
  const deleteButtonId = useShortId();

  const isSelfHostWordpress =
    isWordpress &&
    integration.wordpress_password &&
    integration.wordpress_username;

  const onDelete = async () => {
    if (activeIntegrationId === integration.id) {
      setActiveIntegrationId("");
    }

    dispatch(integrationDeleted(integration.id));

    try {
      await protectedClientApi.deleteIntegration(integration.id);
    } catch (e) {
      captureException(e);
    }
  };

  const openDeleteConfirmModal = () => {
    onOpenPopup("confirmDeletePopup", {
      name: integration.name,
      onDelete: onDelete,
      contentType: "Integration",
    });
  };
  const onEdit = () => {
    if (rest.onEdit) {
      rest.onEdit(integration.id);
    } else {
      setActiveIntegrationId(integration.id);
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            padding: "0 12px",
            borderRadius: "8px",
            "&:hover": {
              background: "#f6f7f9",
            },

            background:
              activeIntegrationId === integration.id ? "#f6f7f9" : "initial",
          }}
          onClick={(e) => {
            setActiveIntegrationId(integration.id);
            e.stopPropagation();
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Tooltip title={integration.name}>
              <Typography
                variant="body1"
                sx={{
                  ...textEllipsis,
                  maxWidth: {
                    xs: "150px",
                    sm: "150px",
                    lg:
                      (isWordpress || type === IntegrationType.Shopify) &&
                      !inPublishMode
                        ? "400px"
                        : "200px",
                    md: "150px",
                    xl:
                      (isWordpress || type === IntegrationType.Shopify) &&
                      !inPublishMode
                        ? "400px"
                        : "200px",
                  },
                }}
                fontWeight={600}
              >
                {integration.name}
              </Typography>
            </Tooltip>
          </Box>
          <Box sameLine alignCenter>
            {(isWordpress && !isSelfHostWordpress) || disableEdit ? null : (
              <Tooltip title={"Edit"}>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();

                    if (
                      onCustomEditPost &&
                      (isSelfHostWordpress ||
                        type === IntegrationType.Shopify ||
                        type === IntegrationType.Ghost)
                    ) {
                      onCustomEditPost(integration);
                    } else {
                      onEdit();
                    }
                  }}
                >
                  <IconComponent
                    sx={{
                      height: "17px",
                      width: "17px",
                    }}
                    icon={EditOutlinedIcon}
                  />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Delete">
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  openDeleteConfirmModal();
                }}
              >
                <IconComponent
                  sx={{
                    height: "17px",
                    width: "17px",
                  }}
                  icon={DeleteOutlineOutlinedIcon}
                />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

const ExsitingIntegrations = () => {
  const {
    integrations,
    inPublishMode,
    setActiveIntegrationId,
    activeIntegrationId,
    type,
    selectedIntegration,
    onAddNewIntegration,
    disableAdd,
  } = useIntegrationContext();
  const isWordpress = type === IntegrationType.WordPress;

  return (
    <Box>
      {integrations.length === 0 ? null : (
        <SectionTitle
          titleProps={{
            sx: {
              fontWeight: 700,
              color: "rgb(165, 47, 196)",
              fontSize: "18px",
            },
          }}
          rootProps={{
            sx: {
              marginBottom: "24px",
            },
          }}
        >
          {inPublishMode
            ? "Select an integration to use"
            : isWordpress
            ? "Your Connected WordPress Sites"
            : "Existing Integrations"}
        </SectionTitle>
      )}
      {integrations.length === 0 ? null : (
        <Box
          sx={{
            marginTop: "12px",
            display: "flex",
            flexDirection: "column",
            rowGap: "12px",
            maxHeight: "400px",
            overflowY: "auto",
            ...secondaryScrollbar,
          }}
        >
          {integrations.map((integration) => {
            return (
              <IntegrationRenderer {...integration} key={integration.id} />
            );
          })}
        </Box>
      )}
      {integrations.length === 0 ||
      (!activeIntegrationId &&
        !isWordpress &&
        type !== IntegrationType.Shopify &&
        type !== IntegrationType.Ghost) ||
      disableAdd ? null : isWordpress ? (
        <AddNewWordPressButton />
      ) : (
        <OutlineGreyButton
          sx={{
            margin: "24px auto 0 auto",
            display: "flex",
          }}
          onClick={() => {
            if (onAddNewIntegration) {
              onAddNewIntegration();
            } else {
              setActiveIntegrationId("");
            }
          }}
          startIcon={<AddOutlinedIcon />}
        >
          {"Add new integration"}
        </OutlineGreyButton>
      )}

      {integrations.length === 0 ? (
        <Box
          sx={{
            padding: "12px 24px",
            background: "#f6f7f9",
            marginTop: "12px",
            position: "relative",
          }}
        >
          <Typography textAlign={"center"} variant="body1" fontWeight={600}>
            Your existing integrations will appear here. You can connect to
            multiple integrations and switch between. This is useful if you have
            multiple sites to manage.
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default ExsitingIntegrations;
