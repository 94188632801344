import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Description, Typography } from "components/ui/Typography";
import { PrimaryInput } from "components/input";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Section, SectionTitle } from "modules/selections/common";
import React, { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { IconComponent } from "components/icon-component";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";
import { useUserHasFeatures } from "hooks/use-features";
import { InlineLinkSpan, InlineSpan } from "components/as-inline-span";
import { useSelector } from "hooks/use-selector";
import { selectUserDetails } from "selectors/user";
import { useNotifications } from "hooks/use-notification";
import { useLoading } from "hooks/use-loading";

import { integrationAdded, integrationModified } from "reducers/user";
import protectedClientApi from "utils/protected-client-api";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { IntegrationType } from "client-server-shared/constants";
import UpgradeRequired, { ghostBenefits } from "../upgrade-required";

export const GhostEditModal = ({
  onClose,
  url,
  apiKey,
  id,
  afterConnect,
}: {
  onClose: () => void;
  url?: string;
  apiKey?: string;
  id?: string;
  afterConnect?: () => void;
}) => {
  const loading = useLoading();
  const dispatch = useAppDispatch();

  const [config, setConfig] = React.useState<{
    url: string;
    apiKey: string;
  }>({
    url: url || "",
    apiKey: apiKey || "",
  });

  const onConfigChange = (key: string, value: string) => {
    setConfig((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const userDetails = useSelector(selectUserDetails);
  const { addSuccessNotification, addFailureNotification } = useNotifications();

  const onSaveGhostIntegration = React.useCallback(async () => {
    if (!userDetails) {
      return;
    }
    if (loading.isLoading) {
      return;
    }
    const cleanUrl = config.url.trim().endsWith(`/`)
      ? config.url.trim().substring(0, config.url.trim().length - 1)
      : config.url.trim();
    const params = {
      ghost_url: cleanUrl,
      ghost_api_key: config.apiKey,
      name: cleanUrl,
      type: IntegrationType.Ghost,
    };
    try {
      loading.onLoading();

      if (id) {
        const updated = await protectedClientApi.updateIntegration(id, params);
        dispatch(integrationModified(updated));

        loading.onIdle();
        addSuccessNotification("Connected to WordPress successfully");
      } else {
        const created = await protectedClientApi.saveIntegration(params);
        dispatch(integrationAdded(created));
      }

      loading.onSuccess();
      addSuccessNotification("Successfully connected to Ghost!");
      afterConnect?.();
    } catch (e) {
      addFailureNotification(
        `Failed to connect to Ghost. Please double-check your Ghost URL and API Key and try again.`
      );
      loading.onIdle();
    }
  }, [loading.isLoading, config]);

  const { hasIntegrationFeature } = useUserHasFeatures();

  return (
    <Dialog fullWidth maxWidth={"sm"} open onClose={onClose}>
      <DialogTitle
        sx={{
          padding: "24 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                marginRight: "24px",
              }}
            >
              {!id ? "Connect To Ghost" : "Edit Ghost Integration"}
            </Typography>
          </Box>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Description
          sx={{
            maxWidth: "350px",
          }}
        >
          You can connect to multiple Ghost blogs and choose which Ghost blog to
          publish to. Learn where to find your Ghost Admin API Key{" "}
          <InlineLinkSpan
            inherit
            href="https://www.junia.ai/docs/connect-to-ghost-blog"
          >
            here
          </InlineLinkSpan>
          .
        </Description>
      </DialogTitle>
      <DialogContent>
        {hasIntegrationFeature ? (
          <>
            {" "}
            <Section>
              <SectionTitle
                description={
                  "You can attain the Key via Ghost dashboard -> Integrations -> Add custom Integration"
                }
              >
                Admin API Key
              </SectionTitle>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "12px",
                }}
              >
                <PrimaryInput
                  size="small"
                  fullWidth
                  value={config.apiKey}
                  onChange={(e) => onConfigChange("apiKey", e.target.value)}
                  placeholder="Paste the generated api key here."
                />
              </Box>
            </Section>
            <Section>
              <SectionTitle
                description={`Enter the URL of your Ghost blog. It should be in the format https://your-ghost-blog-name.ghost.io`}
              >
                Ghost URL
              </SectionTitle>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "12px",
                }}
              >
                <PrimaryInput
                  size="small"
                  fullWidth
                  value={config.url}
                  onChange={(e) => onConfigChange("url", e.target.value)}
                  placeholder="Eg: https://junia.ghost.io"
                />
              </Box>
            </Section>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <OutlineGreyButton
                sx={{
                  margin: "auto",
                }}
                startIcon={id ? null : <AddOutlinedIcon />}
                loading={loading.isLoading}
                disabled={
                  !hasIntegrationFeature ||
                  Object.keys(config).some(
                    (key) => config[key].trim().length === 0
                  )
                }
                onClick={async (e) => {
                  await onSaveGhostIntegration();
                }}
              >
                {id ? "Update" : "Add"}
              </OutlineGreyButton>
            </Box>
          </>
        ) : (
          <UpgradeRequired
            source={SourceType.GhostIntegrationModal}
            benefits={ghostBenefits}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
