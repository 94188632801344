import { IconComponent } from "components/icon-component";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import { Description, Typography } from "components/ui/Typography";
import { Box } from "components/ui/box";
import { SourceType } from "utils/tracking/type";
import colors from "modules/themes/base/colors";
import { PurpleButton, PurplePinkButton } from "components/purple-button";
import CheckOutlined from "@mui/icons-material/CheckOutlined";

export const wordPressBenefits = [
  "Autoblogging with AI-powered content generation",
  "Bulk article generation and automatic publishing to WordPress",
  "Schedule posts publishing - how many posts per day",
  "Connect to unlimited WordPress sites",
  "Automatic internal & externallinking to boost SEO",
  "One-click publishing to multiple WordPress sites",
  "One-click improve existing articles with AI",
  "Automatic search engines indexing of your posts after publishing",
  "Automatic SEO Optimization",
  "No more manual copy-pasting",
];

export const webflowBenefits = [
  "Autoblogging with AI-powered content generation",
  "Automatic publishing to Webflow",
  "Schedule posts publishing - how many posts per day",
  "Connect to unlimited Webflow sites",
  "Automatic internal & external linking to boost SEO",
  "One-click publishing to multiple Webflow sites",
  "One-click improve existing articles with AI",
  "Automatic search engines indexing of your posts after publishing",
  "Automatic SEO Optimization",
  "No more manual copy-pasting",
];

export const ghostBenefits = [
  "Autoblogging with AI-powered content generation",
  "Automatic publishing to Ghost",
  "Connect to unlimited Ghost sites",
  "Automatic internal & external linking to boost SEO",
  "One-click publishing to multiple Ghost sites",
  "One-click improve existing articles with AI",
  "Automatic search engines indexing of your posts after publishing",
  "Automatic SEO Optimization",
  "No more manual copy-pasting",
];

export const mediumBenefits = [
  "Dominate Medium with our AI-powered autoblogging",
  "Bulk article generation and automatic publishing to Medium",
  "Schedule posts publishing - how many posts per day",
  "Connect to unlimited Medium sites",
  "Automatic internal & external linking to boost SEO",
  "One-click publishing to multiple Medium sites",
  "Automatic search engines indexing of your posts after publishing",
  "Automatic SEO Optimization",
  "No more manual copy-pasting",
];

export const redditBenefits = [
  "Automatic publishing to Reddit",
  "Automatic internal & external linking to boost SEO",
  "No more manual copy-pasting",
];

export const shopifyBenefits = [
  "Autoblogging with AI-powered content generation",
  "Automatic publishing to Shopify",
  "Connect to unlimited Shopify stores",
  "Automatic internal & external linking to boost SEO",
  "One-click publishing to multiple Shopify stores",
  "One-click improve existing articles with AI",
  "Automatic search engines indexing of your posts after publishing",
  "Automatic SEO Optimization",
  "No more manual copy-pasting",
];

export const pageBoosterBenefits = [
  "Save hours of manual research and link building",
];

export const UpgradeRequired = ({
  source,
  description,
  benefits = [],
}: {
  source?: SourceType;
  description?: string;
  benefits?: string[];
}) => {
  const { onUpgrade } = useClickUpgrade({
    source,
  });
  return (
    <Box
      sx={{
        border: `1px solid ${colors.borders.light}`,
        borderRadius: "10px",
        padding: "24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <Box
          sx={{
            backgroundColor: colors.background.other,
            padding: "8px",
            borderRadius: "10px",
            display: "flex",
          }}
        >
          <IconComponent
            sx={{
              height: "20px",
              width: "20px",
              fill: "#D831D1",
            }}
            icon={RocketLaunchOutlinedIcon}
          />
        </Box>

        <Box>
          <Typography
            variant="body2"
            sx={{
              fontWeight: 700,
              fontSize: "16px",
            }}
          >
            Upgrade to use this feature
          </Typography>
          {benefits.length > 0 ? null : (
            <>
              <Description
                sx={{
                  marginTop: "6px",
                }}
              >
                {description ||
                  "Ready to unlock more features and benefits with our paid plans?"}
              </Description>
              <PurplePinkButton
                onClick={onUpgrade}
                sx={{
                  marginTop: "12px",
                }}
              >
                Upgrade Now
              </PurplePinkButton>
            </>
          )}
        </Box>
      </Box>
      {benefits.length > 0 ? (
        <Box
          sx={{
            marginTop: "24px",
            display: "flex",
            flexDirection: "column",
            rowGap: "12px",
          }}
        >
          {benefits.map((benefit) => (
            <Box
              sx={{
                display: "flex",
                columnGap: "6px",
                alignItems: "center",
              }}
            >
              <IconComponent
                sx={{
                  height: "20px",
                  width: "20px",
                }}
                icon={CheckOutlined}
              />
              <Description
                sx={{
                  fontWeight: 500,
                }}
              >
                {benefit}
              </Description>
            </Box>
          ))}
        </Box>
      ) : null}
      <PurpleButton
        onClick={onUpgrade}
        sx={{
          width: "100%",
          borderRadius: "6px",
          marginTop: "24px",
        }}
      >
        Upgrade Now
      </PurpleButton>
    </Box>
  );
};

export default UpgradeRequired;
