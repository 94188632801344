import { getStyleProps, passProps } from "modules/themes/styleUtils";

import MuiCheckbox, { CheckboxProps } from "@mui/material/Checkbox";

import { styled } from "@mui/material/styles";
import React from "react";

const Checkbox = (props: CheckboxProps) => {
  const sx = getStyleProps(props).sx;
  return (
    <MuiCheckbox
      {...passProps(props)}
      sx={{
        ...sx,
        color: "rgb(86, 99, 108)!important",
      }}
    />
  );
};

export default Checkbox;
