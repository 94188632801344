import { Post } from "client-server-shared/types/types";
import { somePartialContentIsRequired } from "./constants";
import { GeneratePostConfig } from "./types";

export const createOnInvalidFunc = () => {
  return ({
    post,
    notificationApi,
  }: {
    post: Post;
    notificationApi: any;
  }) => {
    return commonOnInValid({
      post,
      notificationApi,
      message: somePartialContentIsRequired,
    });
  };
};

export const commonOnInValid = ({
  post,
  notificationApi,
  message = somePartialContentIsRequired,
}: {
  post: Post;
  message?: string;
  notificationApi: any;
}) => {
  notificationApi.addInfoNotification(message);
};

export const canGeneratePostContent = (post: GeneratePostConfig) => {
  if (
    (!post.title || post.title.trim().length === 0) &&
    !isDescriptionDefined(post) &&
    !isKeywordsDefined(post)
  ) {
    return false;
  }
  return true;
};

export const isKeywordsDefined = (post: GeneratePostConfig) => {
  return post?.keywords && post?.keywords.length > 0;
};

export const isPurposeDefined = (post: GeneratePostConfig) => {
  return post?.purpose && post?.purpose?.trim().length > 0;
};

export const isDescriptionDefined = (post: GeneratePostConfig) => {
  return post?.description && post?.description?.trim().length > 0;
};

export const isPartialContentDefined = (post: GeneratePostConfig) => {
  return post?.content && post?.content?.trim().length > 0;
};

export const isTitleDefined = (post: GeneratePostConfig) => {
  return post?.title && post?.title?.trim().length > 0;
};

export const isAnyCommonFieldDefined = (post: GeneratePostConfig) => {
  return !!(isPartialContentDefined(post) || isTitleDefined(post));
};
