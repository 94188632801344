import React from "react";
import { Box } from "components/ui/box";
import DateTimeDisplay from "./DateTimeDisplay";
import useCountDown from "./hooks/useCountDown";

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <Box
      sx={{
        padding: "0.5rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          columnGap: "12px",
        }}
      >
        <DateTimeDisplay
          isDanger={days <= 3}
          type="Days"
          value={days}
        />
        <DateTimeDisplay
          isDanger={true}
          type="Hours"
          value={hours}
        />
        <DateTimeDisplay
          isDanger={true}
          type="Mins"
          value={minutes}
        />
        <DateTimeDisplay
          isDanger={true}
          type="Secs"
          value={seconds}
        />
      </Box>
    </Box>
  );
};

const CountDownTimer = ({ targetDate }) => {
  const [days, hours, minutes, seconds] = useCountDown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return null;
  } else {
    return (
      <ShowCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountDownTimer;
