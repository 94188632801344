import { IconButton } from "components/ui/button";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Description, Typography } from "components/ui/Typography";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

import { useCloseSelectedPopup } from "context/popup-context";
import { Box } from "./ui/box";
import {
  IntervalSwitcher,
  MonthlyPlan,
  YearlyPlan,
} from "modules/payment/pricing";
import { PricingProvider } from "modules/payment/context";
import { SourceType } from "utils/tracking/type";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "hooks/use-selector";
import { selectUserCanHavePaidTrial } from "selectors/user";
import { PaidTrailBanner, PircingPromotionBanner } from "modules/marketing-pages/popup-banner";
import { Container } from "./ui/container";
import { useIsChrismasBannerActive } from "modules/marketing-pages/banners/utils";

const UpgradePopup = () => {
  const onClosePopup = useCloseSelectedPopup();

  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const userCanHavePaidTrial = useSelector(selectUserCanHavePaidTrial);
  const isChrismas = useIsChrismasBannerActive();
  return (
    <Dialog
      PaperProps={{
        sx: {
          borderRadius: "10px",
          background: "#F4F8FB",
          ...(matches
            ? {
                maxHeight: "initial",
                height: "100%",
                width: "100%",
                margin: 0,
                maxWidth: "initial",
                borderRadius: 0,
              }
            : {}),
        },
      }}
      fullWidth
      maxWidth="md"
      open
      onClose={onClosePopup}
    >
      <DialogTitle
        sx={{
          padding: "24px 24px 12px 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              marginRight: "12px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                fontSize: "18px",
              }}
            >
              Automate Your Content Creation
            </Typography>
            <Description
              sx={{
                fontSize: "14px",
                maxWidth: "500px",
              }}
            >
              Gain access to human-readable articles, auto-publishing, auto
              keyword research and more.
            </Description>
          </Box>
          <IconButton
            aria-label={"Close"}
            onClick={onClosePopup}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <PricingProvider page={SourceType.UpgradePopup}>
          {userCanHavePaidTrial && <PaidTrailBanner />}
          {isChrismas && (
            <Container
              maxWidth="md"
              sx={{
                margin: "12px auto",
                marginBottom: "24px",
              }}
            >
              <PircingPromotionBanner />
            </Container>
          )}
          <IntervalSwitcher
            page={SourceType.UpgradePopup}
            sx={{
              margin: userCanHavePaidTrial ? "48px 0" : "24px 0",
              marginTop: userCanHavePaidTrial ? "48px" : "24px 0",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              rowGap: "24px",
              columnGap: "24px",
              paddingBottom: "24px",
              padding: {
                xs: "0 30px 24px 0",
                sm: "0 30px 24px 0",
                md: "0 30px",
                xl: "0 0 24px 0",
                lg: "0 0 24px 0",
              },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                xl: "row",
                lg: "row",
              },
            }}
          >
            <>
              <MonthlyPlan
                sx={{
                  flexBasis: {
                    xs: "100%",
                    sm: "100%",
                    md: "45%",
                    lg: "45%",
                    xl: "45%",
                  },
                }}
                page={SourceType.UpgradePopup}
              />
              <YearlyPlan
                sx={{
                  flexBasis: {
                    xs: "100%",
                    sm: "100%",
                    md: "45%",
                    lg: "45%",
                    xl: "45%",
                  },
                }}
                page={SourceType.UpgradePopup}
              />
            </>
          </Box>
        </PricingProvider>
      </DialogContent>
    </Dialog>
  );
};

export default UpgradePopup;
