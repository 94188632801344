import React from "react";
import { Button, IconButton } from "./ui/button";
import { Dialog, DialogContent, DialogTitle } from "./ui/dialog";
import CloseIcon from "@mui/icons-material/Close";
import { Description, Typography } from "./ui/Typography";
import { Box } from "./ui/box";
import { useTheme } from "@mui/material/styles";
import { useCloseSelectedPopup } from "context/popup-context";
import { capitalize } from "client-server-shared/utils/lodash-methods";
import { OutlineGreyButton } from "./ui/button/button-with-loading";
const ConfirmDeleteModal = ({
  contentType,
  name,
  onDelete,
  slots,
}: {
  onDelete: () => void;
  contentType: string;
  name: string;
  slots?: {
    extraDescription?: string;
  };
}) => {
  const onClosePopup = useCloseSelectedPopup();
  const theme = useTheme();
  return (
    <Dialog fullWidth maxWidth="xs" open onClose={onClosePopup}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClosePopup}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6">Delete {capitalize(contentType)}</Typography>
      </DialogTitle>
      <DialogContent>
        <Description>
          Are you sure you want to delete {name}?
          {slots?.extraDescription ? `${" "}${slots.extraDescription}` : ""}
        </Description>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            columnGap: "12px",
            marginTop: "24px",
          }}
        >
          <OutlineGreyButton onClick={onClosePopup}>
            {"Cancel"}
          </OutlineGreyButton>
          <OutlineGreyButton
            sx={{
              backgroundColor: theme.palette.error.main,
              "&:hover": {
                backgroundColor: theme.palette.error.main,
              },
              color: "text.white",
            }}
            onClick={() => {
              onClosePopup();
              onDelete();
            }}
          >
            {"Confirm"}
          </OutlineGreyButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmDeleteModal;
