import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Description, Typography } from "components/ui/Typography";
import { PrimaryInput } from "components/input";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Section, SectionTitle } from "modules/selections/common";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";
import { usePublishToReddit } from "./shared";
import {
  selectActiveContentByCollectionAndPostId,
  selectPostByCollectionIdAndPostId,
} from "selectors/collections";
import { useSelector } from "hooks/use-selector";
import protectedClientApi from "utils/protected-client-api";
import { useMounted } from "hooks/use-mounted";
import { useLoading } from "hooks/use-loading";
import { useNotifications } from "hooks/use-notification";
import { selectUserDetails } from "selectors/user";
import { setUserDetails } from "reducers/user";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { useCloseSelectedPopup } from "context/popup-context";
import { secondaryScrollbar } from "modules/themes/sytle-constants";
import { Tooltip } from "components/ui/tooltip";
import { Chip } from "components/ui/chip";
import truncate from "lodash/truncate";
import { SearchbarWithButton } from "components/search-bar";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";
import { HighlightedSpan, InlineSpan } from "components/as-inline-span";
import { htmlToMarkdown } from "client-server-shared/utils/parser";
import { getH1, removeH1 } from "utils/get-h1";
import { useActivePaidPlanPrompt } from "hooks/use-active-paid-plan-prompt";
import { IconComponent } from "components/icon-component";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";
import { useUserHasFeatures } from "hooks/use-features";
import UpgradeRequired, { redditBenefits } from "../upgrade-required";

const emptyArr = [];

const RedditPublishModal = ({
  postId,
  collectionId,
}: {
  postId: string;
  collectionId: string;
}) => {
  const onClose = useCloseSelectedPopup();

  const { userReddit, onSaveReddit, loading } = usePublishToReddit();
  const [config, setConfig] = React.useState(userReddit);
  const [subredditsValue, setSubredditsValue] = React.useState("");
  const onConfigChange = (key: string, value: string) => {
    setConfig((previous) => {
      return {
        ...previous,
        [key]: value,
      };
    });
  };
  const dispatch = useAppDispatch();
  const userDetails = useSelector(selectUserDetails);
  const post = useSelector((state) =>
    selectPostByCollectionIdAndPostId(state, collectionId, postId)
  );
  const publishing = useLoading();
  const { addSuccessNotification, addFailureNotification } = useNotifications();
  const activeContent = useSelector((state) =>
    selectActiveContentByCollectionAndPostId(state, collectionId, postId)
  );

  const [postTitle, setPostTitle] = React.useState(
    getH1(activeContent) || post?.title || ""
  );

  const [flair, setFlair] = React.useState("");

  const { userInActivePaidPlan, showPrompt } = useActivePaidPlanPrompt();

  const { safeRunAsync, safeRun } = useMounted();

  const canSubmit = !Object.keys(config).some((key) => {
    const val = config[key];
    if (Array.isArray(val)) {
      return val.length === 0;
    }
    return val.trim().length === 0;
  });

  const onPublish = async () => {
    if (!userInActivePaidPlan) {
      showPrompt();
      return;
    }
    if (publishing.isLoading) {
      return;
    }

    await safeRunAsync(async () => {
      publishing.onLoading();
      const toMarkdown = await htmlToMarkdown(removeH1(activeContent));
      try {
        await protectedClientApi.publishToReddit({
          ...config,
          content: toMarkdown,
          postTitle: postTitle,
          flairText: flair,
        });
        dispatch(
          setUserDetails({
            ...userDetails,
            ...config,
          })
        );
        safeRun(() => {
          publishing.onIdle();
          addSuccessNotification("Successfully published to Reddit!");
          onClose();
        });
      } catch (e) {
        addFailureNotification(
          "There's an error publishing to Reddit. Please check if your credentials are still valid."
        );
        publishing.onIdle();
      }
    });
  };
  const { onUpgrade } = useClickUpgrade({
    source: SourceType.RedditIntegrationModal,
  });
  const { hasIntegrationFeature } = useUserHasFeatures();

  return (
    <Dialog fullWidth maxWidth="md" open onClose={onClose}>
      <DialogTitle
        sx={{
          padding: "24 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                marginRight: "24px",
              }}
            >
              {`Publish to Reddit`}
            </Typography>
          </Box>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "6px",
          }}
        >
          <Description
            sx={{
              marginTop: "6px",
              maxWidth: "400px",
            }}
          >
            Learn where to find the ID and Secret{" "}
            <HighlightedSpan inherit>
              <a
                href="https://www.junia.ai/docs/connect-to-reddit"
                target="_blank"
              >
                here
              </a>
            </HighlightedSpan>
          </Description>
        </Box>
      </DialogTitle>
      <DialogContent>
        {hasIntegrationFeature ? (
          <>
            <Section>
              <SectionTitle>Id</SectionTitle>{" "}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "12px",
                }}
              >
                <PrimaryInput
                  size="small"
                  fullWidth
                  value={config.reddit_id}
                  onChange={(e) => onConfigChange("reddit_id", e.target.value)}
                  placeholder="Eg: gMt_c-zuuOAXI-ovdfvg"
                />
              </Box>
            </Section>
            <Section>
              <SectionTitle>Secret</SectionTitle>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "12px",
                }}
              >
                <PrimaryInput
                  size="small"
                  fullWidth
                  value={config.reddit_secret}
                  onChange={(e) =>
                    onConfigChange("reddit_secret", e.target.value)
                  }
                  placeholder=""
                />
              </Box>
            </Section>
            <Section>
              <SectionTitle>Username</SectionTitle>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "12px",
                }}
              >
                <PrimaryInput
                  size="small"
                  fullWidth
                  value={config.reddit_username}
                  onChange={(e) =>
                    onConfigChange("reddit_username", e.target.value)
                  }
                  placeholder="Your reddit username"
                />
              </Box>
            </Section>
            <Section>
              <SectionTitle>Password</SectionTitle>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "12px",
                }}
              >
                <PrimaryInput
                  size="small"
                  fullWidth
                  type="password"
                  placeholder="Your reddit password"
                  value={config.reddit_password}
                  onChange={(e) =>
                    onConfigChange("reddit_password", e.target.value)
                  }
                />
              </Box>
            </Section>
            <Section>
              <SectionTitle>
                <InlineSpan inherit>Subreddits</InlineSpan>
              </SectionTitle>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  columnGap: "6px",
                  rowGap: "6px",
                  maxHeight: "200px",
                  paddingBottom: "12px",
                  overflowY: "auto",
                  ...secondaryScrollbar,
                }}
              >
                {(config.subreddits || []).map((tag, index) => {
                  return (
                    <Tooltip
                      placement="left-start"
                      key={index}
                      title={tag.length > 50 ? tag : ""}
                    >
                      <Box>
                        <Chip
                          sx={{
                            background: "#E6E6FA",
                            userSelect: "auto",
                            cursor: "text",
                            "& svg": {
                              fill: "#9B4E97",
                            },
                          }}
                          onDelete={() => {
                            onConfigChange(
                              "subreddits",
                              config.subreddits?.filter(
                                (currentTag) => tag !== currentTag
                              )
                            );
                          }}
                          label={
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                columnGap: "12px",
                              }}
                            >
                              <InlineSpan
                                sx={{
                                  color: "#9B4E97",
                                  fontSize: "12px",
                                }}
                              >
                                {truncate(tag, {
                                  length: 50,
                                  separator: "...",
                                })}
                              </InlineSpan>
                            </Box>
                          }
                          size="small"
                        />
                      </Box>
                    </Tooltip>
                  );
                })}
              </Box>
              <SearchbarWithButton
                multiline
                variant="primary"
                tooltip="Enter"
                maxRows={5}
                onChange={(e) => {
                  setSubredditsValue(e.target.value);
                }}
                value={subredditsValue}
                onButtonClick={(e, value) => {
                  if (value && value.trim()) {
                    onConfigChange("subreddits", [
                      subredditsValue,
                      ...(config.subreddits || []),
                    ]);
                    setSubredditsValue("");
                  }
                }}
                icon={
                  <ArrowForwardOutlinedIcon
                    sx={{
                      height: "17px",
                      width: "17px",
                    }}
                  />
                }
                buttonProps={{
                  sx: {
                    borderRadius: "4px",
                    padding: "2px 4px",
                  },
                }}
                sx={{
                  width: "100%",
                  "& textarea": {
                    ...secondaryScrollbar,
                  },
                  "& .MuiOutlinedInput-root": {
                    fontSize: "12px",
                    "&:hover fieldset": {
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                    },
                    "&.Mui-focused fieldset": {
                      border: "1px solid rgba(0, 0, 0, 0.23)",
                    },
                  },
                }}
                placeholder="Enter names of the subreddit for your post. Press Enter to add multiple. Eg: selfpromotion"
              />
            </Section>
            <Section>
              <SectionTitle
                required
                description="Check the rules of your subreddits to see if they require the post name to be in a certain format or contain certain text."
              >
                <InlineSpan inherit>Post Name</InlineSpan>
              </SectionTitle>
              <PrimaryInput
                size="small"
                fullWidth
                onChange={(e) => {
                  setPostTitle(e.target.value);
                }}
                value={postTitle}
              />
            </Section>
            <Section>
              <SectionTitle
                optional
                description="Check the rules of your subreddits to see if they require a flair"
              >
                <InlineSpan inherit>Flair</InlineSpan>
              </SectionTitle>
              <PrimaryInput
                fullWidth
                size="small"
                placeholder="Eg: Blog"
                onChange={(e) => {
                  setFlair(e.target.value);
                }}
                value={flair}
              />
            </Section>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <OutlineGreyButton
                sx={{
                  marginLeft: "auto",
                }}
                loading={publishing.isLoading}
                disabled={!hasIntegrationFeature || !canSubmit}
                onClick={async (e) => {
                  if (!canSubmit) {
                    return;
                  }
                  await onPublish();
                  onClose();
                }}
              >
                Publish
              </OutlineGreyButton>
            </Box>
          </>
        ) : (
          <UpgradeRequired
            source={SourceType.RedditIntegrationModal}
            benefits={redditBenefits}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RedditPublishModal;
