import * as React from "react";
import { useSearchParams } from "react-router-dom";

import { SidebarTab } from "../side-bar/types";
import { usePostLayout } from "./post-context";

interface usePostLayoutContextValue {
  selectedTab: SidebarTab | null;
  setSelectedTab: (value: SidebarTab) => void;
  selectedMobileTab: "editor" | "template";
  setSelectedMobileTab: (value: "editor" | "template") => void;
  sideTabExpand: boolean;
  setSideTabExpand: (value: boolean) => void;
  toggleSideTabExpand: () => void;
}

const PostLayoutContext = React.createContext<usePostLayoutContextValue | null>(
  null
);

export const usePostLayoutContext = () => {
  const context = React.useContext(PostLayoutContext);
  if (!context) {
    throw new Error(
      "PostLayoutContextProvider needs to be used with PostLayoutContextProvider"
    );
  }
  return context;
};

export const PostLayoutContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { canShowTemplate, toggleGeneratorOpen, isGeneratorOpen } =
    usePostLayout();

  const [selectedMobileTab, setSelectedMobileTab] = React.useState(
    canShowTemplate ? "template" : "editor"
  );

  const [sideTabExpand, setSideTabExpand] = React.useState(false);

  const [params, setSearchParams] = useSearchParams();

  let initialTab: SidebarTab | null = null;

  if (canShowTemplate) {
    initialTab = SidebarTab.General;
  } else if (isGeneratorOpen) {
    initialTab = SidebarTab.Seo;
  }

  const isArticleImproverOpen = params.get("article-improver");
  React.useEffect(() => {
    if (isArticleImproverOpen) {
      toggleGeneratorOpen(true);
      setSearchParams({});
    }
  }, []);

  if (isArticleImproverOpen) {
    initialTab = SidebarTab.Improver;
  }

  const [selectedTab, setSelectedTab] = React.useState(initialTab);

  const toggleSideTabExpand = React.useCallback(() => {
    setSideTabExpand((previous) => !previous);
  }, []);

  const values = {
    selectedTab,
    setSelectedTab,
    selectedMobileTab,
    setSelectedMobileTab,
    sideTabExpand,
    setSideTabExpand,
    toggleSideTabExpand,
  };
  return (
    <PostLayoutContext.Provider value={values}>
      {children}
    </PostLayoutContext.Provider>
  );
};

export const useSelectSideTabExpand = () =>
  usePostLayoutContext().sideTabExpand;
export const useToggleSideTabExpand = () =>
  usePostLayoutContext().toggleSideTabExpand;
export const useSetSideTabExpand = () =>
  usePostLayoutContext().setSideTabExpand;
export const useSelectedTab = () => usePostLayoutContext().selectedTab;
export const useSetSelectedTab = () => usePostLayoutContext().setSelectedTab;
