import { templateCategory, TemplateType } from "../types";

import {
  isDescriptionDefined,
  createOnInvalidFunc,
  isAnyCommonFieldDefined,
  isPartialContentDefined,
  isPurposeDefined,
} from "../validation";

export const benefitStatementTemplate: TemplateType = {
  id: "benefit-statement-PmeQS",
  value: "benefit-statement-PmeQS",
  label: "benefit-statement",
  slug: "benefit-statement",
  categories: [templateCategory.ecommerce],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "statement",
  title: "Benefit Statement",
  defaultWordsCount: 200,
  contentTypeLabel: "statement",
  helpText: "Provide the main benefit and the offer's unique selling points",
  defaultCollectionName: "Benefit statements",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your benefit statement",
  defaultPostName: "Untitled Statement",
  cardDescription:
    "Craft a clear and powerful statement that highlights the key benefits of your offer, capturing the attention of your target audience",
  shortDescription: "",
  caption:
    "You can edit and customize the generated content to better suit your specific audience and preferences.",
  addtionalFields: [],
  excludedFields: [
    "toneOfVoice",
    "keywords",
    "editorialImage",
    "startFromOutline",
  ],
  fields: {
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a title for your benefit statement",
      onValidate: (post) => {
        return !!(isDescriptionDefined(post) || isPartialContentDefined(post));
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const language = post.language;
        const content = post.content;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;

        let prompt = "Generate a title for a benefit statement";

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content from the statement: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Details",
      fieldName: "description",
      placeholder: `- [Main benefit]: eg: Ultimate comfort
-[Unique Selling Points] Example: Premium memory foam mattresses, sleep better, wake up refreshed, enjoy a pain-free morning`,
      description: "Enter the details of your offer",
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Friendly, formal",
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 200,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;

        let prompt =
          "Write a clear and powerful statement that highlights the main benefit of an offer based on the provided information";

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the statement to ${wordsCount} words.`;
        }

        prompt += ".";

        return prompt;
      },
    },
    categories: {
      label: "Categories",
      fieldName: "categories",
      onValidate: (post) => !!isPartialContentDefined(post),
      onInValid: createOnInvalidFunc(
        "Some partial statement is required to categorize your offer statement"
      ),
      calculateCost: () => 100,
      buildPrompt: (post) => {
        const language = post.language;
        const title = post.title;
        const content = post.content;

        let prompt = "Categorize the following based on";

        if (content) {
          prompt += `the following partial content "${content}",`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        prompt += `. List the categories as an array of strings: ["category1", "category2", "category3", ...].`;

        return prompt;
      },
    },
  },
};
