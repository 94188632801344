import { siteConfig } from "client-server-shared/config/siteConfig";
import { NextLink } from "components/next-link";
import { Typography } from "components/ui/Typography";
import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import React from "react";

const graidentTextStyle = {
  background: "linear-gradient(90.48deg, #435b8c 8.97%, #D4E4FC 53.35%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};

const Affilate = () => {
  return (
    <Box component="section">
      <Box>
        <IconButton
          component={NextLink}
          href={siteConfig.affiliate_signup}
          target="__blank"
          rel="noopener noreferrer nofollow"
          sx={{
            display: "flex",
            color: "text.white",
            margin: "24px auto 48px auto",
            borderRadius: "50px",
            padding: "12px 24px",
            width: "100%",
            fontSize: {
              xs: "1.2rem",
              sm: `1.2rem`,
              md: `1.5rem`,
              xl: `1.5rem`,
            },
            background: "none",
            "&:hover": {
              background: "none",
            },
          }}
        >
          <Typography
            sx={{
              ...graidentTextStyle,
              fontSize: "20px",
              transition: "background 1s ease-out",
              background:
                "linear-gradient(45deg,#c300ff 36%,#1e43ff 47%,#30b6ff 67%,#00f0ff 76%,#1e43ff)",
              "&:hover": {
                ...graidentTextStyle,
                background:
                  "linear-gradient(45deg,#ff51d7 100%,#c300ff 36%,#c300ff 47%,#30b6ff 67%,#00f0ff 76%,#1e43ff)",
              },
              border: "1px solid rgb(44 51 73)",
              padding: "12px 24px",
              borderRadius: "22px",
            }}
          >
            {`Earn 30% commission as a Junia Affiliate Partner →`}
          </Typography>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Affilate;
