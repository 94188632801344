export const parseOutput = (output: string): any => {
  let jsonOutput = output.trim();
  if (jsonOutput.includes("```json")) {
    jsonOutput = jsonOutput.split("```json")[1].trimStart();
  } else if (jsonOutput.includes("```")) {
    const firstIndex = jsonOutput.indexOf("```");
    jsonOutput = jsonOutput.slice(firstIndex + 3).trimStart();
  }
  const lastIndex = jsonOutput.lastIndexOf("```");
  if (lastIndex !== -1) {
    jsonOutput = jsonOutput.slice(0, lastIndex).trimEnd();
  }

  return JSON.parse(jsonOutput);
};

export const fixJsonOutput = (output: string): string => {
  return output.replaceAll("```json", "").replaceAll("```", "");
};

export const getParseOutoutFallback = (output: string): any => {
  let jsonOutput = output.trim();
  if (jsonOutput.includes("```json")) {
    jsonOutput = jsonOutput.split("```json")[1].trimStart();
  } else if (jsonOutput.includes("```")) {
    const firstIndex = jsonOutput.indexOf("```");
    jsonOutput = jsonOutput.slice(firstIndex + 3).trimStart();
  }
  const lastIndex = jsonOutput.lastIndexOf("```");
  if (lastIndex !== -1) {
    jsonOutput = jsonOutput.slice(0, lastIndex).trimEnd();
  }

  return jsonOutput;
};

export const createMarkdownFixer = () => {
  let hasBrokenFormat = false;

  const getHasBrokenFormat = () => {
    return hasBrokenFormat;
  };

  return (output: string, fixType?: "start" | "end" | "both") => {
    let newOutput = output;

    if (fixType === "start") {
      const res = fixMarkdownStart(newOutput);
      newOutput = res.fixedOutput;
      hasBrokenFormat = res.hasBrokenFormat;
    } else if (fixType === "end") {
      const res = fixMarkdownEnd(newOutput);
      newOutput = res.fixedOutput;
      hasBrokenFormat = res.hasBrokenFormat;
    } else if (fixType === "both") {
      const res = fixMarkdownStart(newOutput);
      const resEnd = fixMarkdownEnd(res.fixedOutput);
      newOutput = resEnd.fixedOutput;
      hasBrokenFormat = res.hasBrokenFormat;
    }
    newOutput = newOutput.replaceAll("```markdown", "").replaceAll("```", "");

    const markdownSymbolsToFix = ["#", "##", "###", "####", "#####", "######"];

    markdownSymbolsToFix.forEach((symbol) => {
      const fullSymbol = `\\${symbol}`;
      newOutput = newOutput.replaceAll(fullSymbol, "");
    });

    return { newOutput, getHasBrokenFormat };
  };
};

const fixMarkdownEnd = (output: string) => {
  let fixedOutput = output;
  let hasBrokenFormat = false;
  if (!fixedOutput) {
    return { fixedOutput, hasBrokenFormat };
  }
  if (fixedOutput.endsWith(`\\`)) {
    fixedOutput = fixedOutput.substring(0, fixedOutput.length - 1);
  }
  if (fixedOutput.endsWith(`"`)) {
    fixedOutput = fixedOutput.substring(0, fixedOutput.length - 1);
    hasBrokenFormat = true;
  }

  if (fixedOutput.endsWith("```markdown")) {
    fixedOutput = fixedOutput.slice(0, fixedOutput.length - 12);
    hasBrokenFormat = true;
  } else if (fixedOutput.endsWith("```")) {
    fixedOutput = fixedOutput.slice(0, fixedOutput.length - 4);
    hasBrokenFormat = true;
  }

  return { fixedOutput, hasBrokenFormat };
};

const fixMarkdownStart = (output: string) => {
  let fixedOutput = output;
  let hasBrokenFormat = false;
  if (!fixedOutput) {
    return { fixedOutput, hasBrokenFormat };
  }
  if (fixedOutput.startsWith(`\\`)) {
    fixedOutput = fixedOutput.substring(1);
  }
  if (fixedOutput.startsWith(`"`)) {
    fixedOutput = fixedOutput.substring(1);
    hasBrokenFormat = true;
  }

  if (fixedOutput.startsWith("```markdown")) {
    fixedOutput = fixedOutput.slice(12);
    hasBrokenFormat = true;
  } else if (fixedOutput.startsWith("```")) {
    fixedOutput = fixedOutput.slice(4);
    hasBrokenFormat = true;
  }

  return { fixedOutput, hasBrokenFormat };
};
