import { useSelector } from "hooks/use-selector";
import * as React from "react";
import {
  selectContentBlockByIndexByCollectionAndPostId,
  selectContentIndexByCollectionAndPostId,
  selectIsSidebarOpenByCollectionAndPostId,
  selectPostByCollectionIdAndPostId,
  selectTemplateIdByCollectionAndPostId,
} from "selectors/collections";
import { useAppDispatch } from "hooks/use-app-dispatch";
import { togglePostGeneratorOpenByCollectionAndPostId } from "reducers/collections/actions";
import { noTemplateId } from "templates/client-templates/default-template-id";
import { languageOptions } from "client-server-shared/constants";

interface PostContextValue {
  collectionId: string;
  postId: string;
  sourceIds: string[];
  setSourceIds: (ids: string[]) => void;
}

const PostContext = React.createContext<PostContextValue | null>(null);

const usePostContext = () => {
  const context = React.useContext(PostContext);
  if (!context) {
    throw new Error("PostContext needs to be used with PostContextProvider");
  }
  return context;
};

export const PostContextProvider = React.memo(
  ({
    children,
    postId,
    collectionId,
  }: {
    children: React.ReactNode;
    postId: string;
    collectionId: string;
  }) => {
    const [sourceIds, setSourceIds] = React.useState<string[]>([]);

    const values = {
      postId,
      collectionId,
      sourceIds,
      setSourceIds,
    };
    return (
      <PostContext.Provider value={values}>{children}</PostContext.Provider>
    );
  }
);

export const usePostSourceIds = () => {
  const value = usePostContext();
  return React.useMemo(() => {
    return {
      sourceIds: value.sourceIds,
      setSourceIds: value.setSourceIds,
    };
  }, [value.setSourceIds, value.sourceIds]);
};

export const usePostMeta = () => {
  const value = usePostContext();
  return React.useMemo(() => {
    return {
      postId: value.postId,
      collectionId: value.collectionId,
    };
  }, [value.postId, value.collectionId]);
};

export const useSelectedPostContent = () => {
  const { postId, collectionId } = usePostMeta();

  const activeContentIndex = useSelector((state) =>
    selectContentIndexByCollectionAndPostId(state, collectionId, postId)
  );

  const activeContent = useSelector((state) =>
    selectContentBlockByIndexByCollectionAndPostId(
      state,
      collectionId,
      postId,
      activeContentIndex
    )
  );
  return activeContent;
};

export const useSelectedPost = () => {
  const { postId, collectionId } = usePostMeta();
  const post = useSelector((state) =>
    selectPostByCollectionIdAndPostId(state, collectionId, postId)
  );
  return post;
};

export const useSelectPostLanguage = () => {
  const post = useSelectedPost();
  return post?.postConfig?.language || languageOptions[0].value;
};

export const usePostLayout = () => {
  const { collectionId, postId } = usePostMeta();
  const isGeneratorOpen = useSelector((state) =>
    selectIsSidebarOpenByCollectionAndPostId(state, collectionId, postId)
  );

  const templateId = useSelector((state) =>
    selectTemplateIdByCollectionAndPostId(state, collectionId, postId)
  );
  const dispatch = useAppDispatch();
  const toggleGeneratorOpen = React.useCallback((status?: boolean) => {
    dispatch(
      togglePostGeneratorOpenByCollectionAndPostId({
        collectionId,
        postId,
        status,
      })
    );
  }, []);

  const canShowTemplate = templateId !== noTemplateId;

  return {
    isGeneratorOpen,
    toggleGeneratorOpen,
    canShowTemplate,
  };
};
