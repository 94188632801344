import { AppDispatch, AppThunk, RootState } from "reducers/types";
import {
  addNewContentBlockIfNeeded,
  editPostPropertiesByCollectionIdAndPostId,
  updateContentByCollectionIdAndPostId,
} from "reducers/collections/actions";
import { processingLogUtils } from "hooks/use-loading";
import { notificationUtils } from "hooks/use-notification";
import {
  selectCollectionDataByCollectionId,
  selectPostByCollectionIdAndPostId,
} from "selectors/collections";
import protectedClientApi from "utils/protected-client-api";
import {
  emitContentByPostId,
  getFieldProcessingStatus,
} from "hooks/use-generate-fields";
import {
  onStream,
  onStreamOnce,
  onStreamTakeMany,
  StreamManyReadOptions,
} from "client-server-shared/utils/handle-stream";
import { ErrorJson } from "client-server-shared/utils/error-parsing";
import { ErrorCode } from "server/errors/utils";
import { buildGenearteConfig } from "hooks/use-generate";
import { isPlainObject } from "client-server-shared/utils/lodash-methods";
import { captureException } from "utils/error-catching/lazy-sentry";
import { getTemplateByTemplateId } from "templates/client-templates/templates-util";
import { PostConfig } from "client-server-shared/types/types";
import { selectFeatures } from "selectors/user";
import { getMarkdownToHtmlFunc } from "client-server-shared/utils/lazy-markdown-to-html";
import { onOtherLimitReached } from "utils/other-limit";
import { LimitReachedType } from "client-server-shared/types/limit";
import { createMarkdownFixer } from "client-server-shared/utils/output-parser";
import { removeQuotesFromText } from "client-server-shared/utils/removeQuotesFromText";
interface ExtraOptions {
  postConfig?: Partial<PostConfig>;
  sourceIds?: string[];
}

const onGenerateContent = (
  collectionId: string,
  postId: string,
  options?: ExtraOptions
): AppThunk => {
  const extraPostConfig = options?.postConfig || {};
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const collection = selectCollectionDataByCollectionId(state, collectionId);
    const post = selectPostByCollectionIdAndPostId(state, collectionId, postId);
    const features = selectFeatures(state);
    if (!post) {
      return;
    }

    if (!collection?.templateId && !post.templateId) {
      return;
    }
    const { addFailureNotification } = notificationUtils(dispatch);

    const { addLog, onIdle } = processingLogUtils(
      getFieldProcessingStatus(postId, "content")
    )(dispatch);

    const outputs = post?.postConfig?.variationsCount || 1;

    let postTitle = post?.title;

    const onRead = (data: StreamManyReadOptions) => {
      const markdownFixer = createMarkdownFixer();

      const { t, i } = data;
      const text = t ? markdownFixer(t, "start").newOutput : t;
      doneIndexes[i] = {
        completed: false,
        text: text,
      };
      emitContentByPostId(postId, text, i);
    };

    let doneIndexes: Record<
      number,
      {
        completed: boolean;
        text: string;
      }
    > = {};

    for (let i = 0; i < outputs; i++) {
      doneIndexes[i] = {
        completed: false,
        text: "",
      };
    }

    const markdownToHmtlFunc = await getMarkdownToHtmlFunc();

    const onDone = (data: StreamManyReadOptions) => {
      const { t, i } = data;
      const markdownFixer = createMarkdownFixer();

      const text = t ? markdownFixer(t, "both").newOutput : t;
      const toHTML = markdownToHmtlFunc(text);

      onIdle();
      if (toHTML) {
        dispatch(
          updateContentByCollectionIdAndPostId({
            postId,
            collectionId,
            content: toHTML,
            index: i,
          })
        );
      }
      doneIndexes[i] = {
        completed: true,
        text: toHTML,
      };
      try {
        setTimeout(() => {
          emitContentByPostId(postId, "", i);
        }, 2000);
      } catch (e) {}
    };
    const markAllAsDone = () => {
      Object.keys(doneIndexes).forEach((i) => {
        const index = Number(i);

        if (!doneIndexes[index]?.completed) {
          const text = doneIndexes[index]?.text;
          doneIndexes[index] = {
            completed: true,
            text: text,
          };
          onDone({
            t: text,
            i: index,
          });
        }
      });
    };

    const onError = (e?: ErrorJson) => {
      let errorMesssage =
        "There's an error generating content, sorry about that! Please contact us if it keeps happening";

      onIdle();
      if (isPlainObject(e) && e?.code === ErrorCode.NotEnoughCredits) {
        onOtherLimitReached(LimitReachedType.wordsLimitReached);
      } else if (isPlainObject(e) && e?.code === ErrorCode.FreeLimitReached) {
        onOtherLimitReached(LimitReachedType.requiredPaidPlan, {
          description1:
            "To continue using this template, you'll need to be on a paid plan.",
        });
      } else {
        addFailureNotification(errorMesssage);
      }

      try {
        setTimeout(() => {
          markAllAsDone();
        }, 4000);
      } catch (e) {}
    };

    try {
      addLog();

      const selectedTemplate = getTemplateByTemplateId(
        post.templateId || collection.templateId
      );
      const { requiredFields = [] } = selectedTemplate;
      if (!post?.title && requiredFields.includes("title")) {
        const res = await protectedClientApi.generateFields("title", {
          templateId: post.templateId || collection.templateId,
          fields: buildGenearteConfig({
            ...post,
            postConfig: { ...post.postConfig, ...extraPostConfig },
          }),
        });
        const { data, error } = await onStreamOnce(res);
        if (error) {
          onError(error);
          return;
        }
        postTitle = removeQuotesFromText(data);
        dispatch(
          editPostPropertiesByCollectionIdAndPostId({
            collectionId,
            postId: post?.clientId,
            update: {
              title: postTitle,
            },
          })
        );
      }

      dispatch(
        addNewContentBlockIfNeeded({
          collectionId: collection.clientId,
          postId: post.clientId,
          amount: outputs,
        })
      );

      const response = await protectedClientApi.generateFields("content", {
        templateId: post.templateId || collection.templateId,
        fields: buildGenearteConfig({
          ...post,
          postConfig: { ...post.postConfig, ...extraPostConfig },
          title: postTitle,
        }),
        sourceIds: options?.sourceIds,
        features,
      });

      if (outputs === 1) {
        await onStream(
          response,
          (text) => {
            onRead({ t: text, i: 0 });
          },
          (text) => {
            onDone({ t: text, i: 0 });
          },
          onError
        );
      } else {
        await onStreamTakeMany(response, onRead, onDone, onError);
      }

      markAllAsDone();
      onIdle();
    } catch (e) {
      captureException(e);
      onError(e);
    } finally {
      onIdle();
    }
  };
};

export default onGenerateContent;
