import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";

const createdTemplate = templateCreator({
  value: "client proposal",
  categories: [templateCategory.ecommerce, templateCategory.email],
  defaultWordsCount: 1000,
  titleName: "title",
});

export const clientProposalTemplate = {
  ...createdTemplate,
  contentType: "client proposal",
  contentTypeLabel: "client proposal",
  helpText:
    "Provide an overview of the project or service you are proposing, including the objectives, timeline, and any other relevant information",
  cardDescription:
    "Generate persuasive and detailed client proposals to showcase the value of your products, services, or solutions and win more business",
  shortDescription:
    "Create a comprehensive client proposal based on the provided information",
  caption:
    "Win over potential clients with a well-crafted proposal that highlights your unique offerings and expertise",
  excludedFields: [
    "writingStyle",
    "toneOfVoice",
    "editorialImage",
    "startFromOutline",
  ],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a title for your client proposal",
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;
        let prompt = `Generate a suitable title for a client proposal based on the provided information:`;

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Details",
      fieldName: "description",
      required: true,
      description: " ",
      placeholder: `- [Project/Service Overview and Objectives] Example: Design and build a responsive and SEO friendly website for a big retail client \n- [Timeline] Example: 3 months \n- [Other Relevant Information] Example: Any future change of requirements will be charged separately`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;
        const title = post.title;
        let prompt =
          "Create a comprehensive client proposal for a project or service with the following information";

        if (description) {
          prompt += `Details: ${description}`;
        }
        if (targetAudience) {
          prompt += `, should be tailored to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
