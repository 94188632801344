import { InlineSpan } from "components/as-inline-span";
import { PrimaryInput } from "components/input";
import { Description } from "components/ui/Typography";
import { FormControlLabel } from "components/ui/form/form-control-label";
import Switch from "components/ui/form/switch";
import { Section, SectionTitle } from "modules/selections/common";
import React from "react";
import pluralize from "pluralize";
import { Box } from "components/ui/box";
import { useSelector } from "hooks/use-selector";
import {
  selectIsUserAdmin,
  selectUserInActiveUnlimitedPlan,
} from "selectors/user";
import DateStartEndTimePicker from "components/ui/date-selector";
import { Button } from "components/ui/button";
import { dayjs } from "client-server-shared/utils/dayjs";
import { indexingCost } from "client-server-shared/constants";

const SchedulePublish = ({
  onChange,
  singleArticle,
  schedulePublishConfig = {},
  disableIndexer,
}) => {
  const userAdmin = useSelector(selectIsUserAdmin);
  const userInUnlimitedPlan = useSelector(selectUserInActiveUnlimitedPlan);
  const safeDate =
    schedulePublishConfig.publishedDate &&
    dayjs(schedulePublishConfig.publishedDate).isValid()
      ? dayjs(schedulePublishConfig.publishedDate)
      : null;
  let titleDescription =
    "If disabled, articles will be published immediately after creation. The order of publication follows the sequence in which the titles/URLs were entered by you.";
  let titleInfo = `Once confirmed, this cannot be changed. If you want to cancel the scheduling, please delete your integration and create a new one. This will prevent our system from publishing ${
    singleArticle ? "the article" : "articles"
  } to your website. We will send you an email notification after publishing the ${
    singleArticle ? "article" : "articles"
  }.`;

  return (
    <>
      {singleArticle ? null : (
        <Section>
          <SectionTitle description={titleDescription} info={titleInfo}>
            Schedule Publish
          </SectionTitle>
          <FormControlLabel
            control={
              <Switch
                onChange={() => {
                  onChange({
                    ...schedulePublishConfig,
                    enabled: !schedulePublishConfig.enabled,
                  });
                }}
                checked={schedulePublishConfig.enabled}
              />
            }
            sx={{
              marginRight: 0,
              whiteSpace: "nowrap",
            }}
            componentsProps={{
              typography: {
                variant: "body2",
                color: "rgb(99, 115, 129)",
                fontSize: "12px",
              },
            }}
            labelPlacement="end"
            label="Enable Schedule Publishing"
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "6px",
            }}
          >
            <PrimaryInput
              type="number"
              onChange={(e) => {
                onChange({
                  ...schedulePublishConfig,
                  amount: e.target.value,
                });
              }}
              size="small"
              value={schedulePublishConfig.amount}
            />
            <InlineSpan inherit>
              <Description>
                {pluralize("article", schedulePublishConfig.amount)} per day
              </Description>
            </InlineSpan>
          </Box>
        </Section>
      )}
      <Section>
        <SectionTitle
          info={
            singleArticle
              ? `Please test the integration beforehand to avoid any issues by publishing a test article immediately with this option disabled. ${titleInfo}`
              : `It is recommended to leave this field empty. However, it can be useful in certain scenarios. For instance, you can set the date for tomorrow; in this case, you can still edit and review the generated articles, but publishing will automatically occur tomorrow.`
          }
          description={
            singleArticle
              ? "If left empty, the article will be published immediately. "
              : "If set, the article's creation will still occur immediately, but the publishing, including scheduled publishing, will be delayed until the effective date."
          }
          extraChildren={
            <Button
              variant="text"
              capitalize
              sx={{
                marginLeft: "auto",
                color: "rgb(156 163 175)",
              }}
              onClick={() => {
                onChange({
                  ...schedulePublishConfig,
                  publishedDate: null,
                });
              }}
              padding={0}
            >
              Clear
            </Button>
          }
        >
          {singleArticle ? "Publish Date" : "Effective Date"}
        </SectionTitle>
        <DateStartEndTimePicker
          value={safeDate || null}
          onChange={(value) => {
            onChange({
              ...schedulePublishConfig,
              publishedDate: value.toISOString(),
            });
          }}
        />
      </Section>
      {userInUnlimitedPlan && !userAdmin ? null : disableIndexer ? null : (
        <Section>
          <SectionTitle
            optional
            descriptionProps={{
              sx: {
                marginBottom: 0,
              },
            }}
            info={`The URLs of published articles are sent to one of the most well-known indexers. However, indexing is not guaranteed. For example, if your website has a high spam score, indexing may not occur. On average, it takes 0-7 days for the articles to be indexed.`}
            description={`Notify search engines about the published articles for it to appear in search results. Each article will cost extra ${indexingCost} words.`}
          >
            Submit To Indexer
          </SectionTitle>
          <FormControlLabel
            control={
              <Switch
                onChange={() => {
                  onChange({
                    ...schedulePublishConfig,
                    autoIndex: !schedulePublishConfig.autoIndex,
                  });
                }}
                checked={schedulePublishConfig.autoIndex}
              />
            }
            sx={{
              marginRight: 0,
              whiteSpace: "nowrap",
            }}
            componentsProps={{
              typography: {
                variant: "body2",
                color: "rgb(99, 115, 129)",
                fontSize: "12px",
              },
            }}
            labelPlacement="end"
            label="Enable submit to indexer"
          />
        </Section>
      )}
    </>
  );
};

export default SchedulePublish;
