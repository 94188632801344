export function extractUrlsFromString(text: string): string[] {
  if (!text) {
    return [];
  }
  const urlPattern = new RegExp(
    "\\b((?:https?://|www\\.)[-a-zA-Z0-9@:%._\\+~#=]{2,256}(\\.[a-z]{2,6})?([-a-zA-Z0-9@:%_\\+.~#?&//=]*))",
    "gi"
  );

  const urls = [];
  let match;

  while ((match = urlPattern.exec(text)) !== null) {
    urls.push(match[0]);
  }

  return urls;
}

export const getEffectiveUrls = (urls: string[]): string[] => {
  const effectiveUrls = urls
    .map((url) => {
      const urls = extractUrlsFromString(url);
      if (urls && urls.length > 0) {
        return urls[0];
      }
      return null;
    })
    .filter(Boolean);
  return effectiveUrls as string[];
};
