import { countWordsV2 } from "client-server-shared/utils/countWords";
import { Typography, TypographyProps } from "./ui/Typography";

export const CharCounts = ({
  count,
  ...rest
}: TypographyProps & { count?: number }) => {
  return (
    <>
      {typeof count === "number" && (
        <Typography
          {...rest}
          sx={{
            fontSize: "12px",
            color: "#A3A2A2",
            ...(rest.sx || {}),
          }}
        >
          {count} characters
        </Typography>
      )}
    </>
  );
};
export const WordsCounts = ({
  words,
  ...rest
}: TypographyProps & { words?: string }) => {
  return (
    <Typography
      {...rest}
      sx={{
        fontSize: "12px",
        color: "#A3A2A2",
        ...(rest.sx || {}),
      }}
    >
      {countWordsV2(words || "")} words
    </Typography>
  );
};
