import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";

const createdTemplate = templateCreator({
  value: "petition text",
  categories: [templateCategory.marketing, templateCategory.custom],
  defaultWordsCount: 500,
  titleName: "title"
});

export const petitionTextTemplate = {
  ...createdTemplate,
  contentType: "petition text",
  contentTypeLabel: "petition text",
  helpText:
    "Provide the background, context, and specific ask of your petition to generate a compelling petition text",
  cardDescription:
    "Generate compelling and effective petition texts to mobilize people and create change",
  shortDescription:
    "Create a petition text with a clear ask and actionable steps",
  caption: "",
  excludedFields: ["writingStyle", "editorialImage", "startFromOutline"],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    description: {
      label: "Description",
      fieldName: "description",
      description: " ",
      required: true,
      placeholder: `- [Issue or campaign details] Example: Stop the discrimination against nurses \n- [Action required] Example: 30% increase in salary, better work equipment, working overtime will ensure fair compensation`,
    },
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: `Enter or generate a title for your petition text`,
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const content = post.content;
        const language = post.language;

        let prompt = `Generate a suitable title for a petition text based on the provided information:`;

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const toneOfVoice = post.toneOfVoice;
        const writingStyle = post.writingStyle;
        const wordsCount = post.wordsCount;

        let prompt =
          "Create a compelling and actionable petition text for your campaign, cause, or issue with the following information:";

        if (description) {
          prompt += ` Details: ${description}`;
        }
        if (targetAudience) {
          prompt += `, should appeal to the target audience: ${targetAudience}`;
        }
        if (writingStyle) {
          prompt += `, in a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, with ${toneOfVoice} tone of voice`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words`;
        }

        return prompt;
      },
    },
  },
};
