import React from "react";
import { useSelector } from "hooks/use-selector";
import { selectUserInActiveProPlan } from "selectors/user";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";
import { LimitReachedType } from "client-server-shared/types/limit";
import LimitReachModal from "./limit-reached-modal";
import { useOpenPopup } from "context/popup-context";
import { OutlineGreyButton } from "components/ui/button/button-with-loading";

const LimitReachModalContainer = ({
  type = LimitReachedType.wordsLimitReached,
  action,
  ...rest
}: {
  description1?: string;
  description2?: string;
  action?: () => void | null;
  type?:
    | LimitReachedType.wordsLimitReached
    | LimitReachedType.imagesLimitReached
    | LimitReachedType.requiredPaidPlan
    | LimitReachedType.workflowsLimitReached;
}) => {
  const { onUpgrade } = useClickUpgrade({
    source: SourceType.LimitReachedPopup,
  });
  const onOpenPopup = useOpenPopup();
  let actionText = "Upgrade My Plan";

  let leftActionText = "";

  const inProPlan = useSelector(selectUserInActiveProPlan);

  let description1 = "You don't have enough words to use this feature.";
  let description2 =
    "Ready to unlock more features and benefits with our paid plans?";

  if (type === LimitReachedType.imagesLimitReached) {
    description1 =
      "You've used up all your AI images for this month. Great work!";
    description2 =
      "Would you like to upgrade to a higher plan with more AI image credits?";
  } else if (type === LimitReachedType.requiredPaidPlan) {
    description1 = "To access this feature, you'll need to be on a paid plan.";
  }

  if (type === LimitReachedType.workflowsLimitReached) {
    description1 =
      "You’ve used all the workflow runs for article generation allocated to your plan.";
    description2 =
      "Would you like to buy more workflow runs, or upgrade to a higher plan with more workflow runs and access to premium features?";
    if (inProPlan) {
      description2 =
        "Would you like to buy more workflow runs, or upgrade to a higher plan with more workflow runs?";
    }
    leftActionText = "Buy More Runs";
  }

  if (type === LimitReachedType.wordsLimitReached) {
    description1 = "You don't have enough words to use this feature.";
    description2 =
      "Would you like to buy more words or upgrade to a higher plan with more words and access to premium features?";
    if (inProPlan) {
      description2 =
        "Would you like to buy more words or upgrade to a higher plan with more words?";
    }
    leftActionText = "Buy More Words";
  }

  if (rest.description1) {
    description1 = rest.description1;
  }
  if (rest.description2) {
    description2 = rest.description2;
  }

  const enabledLeftAction =
    type === LimitReachedType.wordsLimitReached ||
    type === LimitReachedType.workflowsLimitReached;

  return (
    <LimitReachModal
      action={() => {
        action ? action() : onUpgrade(true);
      }}
      actionText={actionText}
      slot={{
        leftAction: !enabledLeftAction ? null : (
          <OutlineGreyButton
            onClick={() => {
              onOpenPopup("addonModal", {
                type:
                  type === LimitReachedType.wordsLimitReached
                    ? "credits"
                    : "workflows",
              });
            }}
          >
            {leftActionText}
          </OutlineGreyButton>
        ),
      }}
      description1={description1}
      description2={description2}
    />
  );
};

export default LimitReachModalContainer;
