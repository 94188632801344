import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";

const createdTemplate = templateCreator({
  value: "travel itinerary",
  categories: [templateCategory.entertainment, templateCategory.custom],
  defaultWordsCount: 800,
  titleName: "title",
});

export const travelItineraryTemplate = {
  ...createdTemplate,
  contentType: "travel itinerary",
  contentTypeLabel: "travel itinerary",
  helpText:
    "Provide the destination, trip duration, and any specific points of interest or activities you want to include in the itinerary",
  cardDescription:
    "Generate personalized and detailed travel itineraries that cater to various preferences and interests to help travelers make the most of their trips",
  shortDescription:
    "Create a well-planned travel itinerary based on the provided information",
  caption:
    "Plan the perfect getaway with a custom travel itinerary that highlights the best attractions and experiences at your destination",
  excludedFields: [
    "writingStyle",
    "toneOfVoice",
    "editorialImage",
    "startFromOutline",
  ],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a title for your travel itinerary",
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;

        let prompt = `Generate a suitable title for a travel itinerary based on the provided information:`;

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Details",
      fieldName: "description",
      description: " ",
      required: true,
      placeholder: `- [Destination]
- [Trip Duration]
- [Points of Interest or Activities]`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 1000,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;
        const title = post.title;
        let prompt =
          "Create a well-planned travel itinerary based on the following information";

        if (description) {
          prompt += `. Details: ${description}`;
        }
        if (targetAudience) {
          prompt += `, should be suitable for the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
