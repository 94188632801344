import { templateCategory } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

import { templateCreator } from "../template-builder";

const createdTemplate = templateCreator({
  value: "user manual",
  categories: [templateCategory.ecommerce],
  defaultWordsCount: 800,
  titleName: "title"
});

export const userGuideManualTemplate = {
  ...createdTemplate,
  contentType: "user manual",
  contentTypeLabel: "user manual",
  helpText:
    "Provide a brief description of the product, service, or software for which you want to create a user guide/manual",
  cardDescription:
    "Generate user-friendly guides or manuals for your products, services, or software to help users understand and make the most out of their experience",
  shortDescription:
    "Create a comprehensive and easy-to-follow user guide/manual based on the provided description",
  caption:
    "Ensure a seamless user experience by providing clear instructions and guidance with a well-structured user guide/manual",
  excludedFields: [
    "toneOfVoice",
    "writingStyle",
    "editorialImage",
    "startFromOutline",
  ],
  addtionalFields: [],
  fields: {
    ...createdTemplate.fields,
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: `Enter or generate a title for your user guide/manual`,
      onValidate: (post) =>
        !!isPartialContentDefined(post) || !!isDescriptionDefined(post),
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const language = post.language;
        const content = post.content;

        let prompt = `Generate a suitable title for a user guide/manual based on the provided information:`;

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    description: {
      label: "Details",
      fieldName: "description",
      description: " ",
      placeholder: `- [What is it? What does it do?]
- [Additional Details]`,
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 400,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const targetAudience = post.targetAudience;
        const title = post.title;

        let prompt =
          "Create a user guide/manual for a product, service, or software with the following information";

        if (description) {
          prompt += `Details: ${description}`;
        }
        if (targetAudience) {
          prompt += `, should be made understandable to the target audience: ${targetAudience}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += ` Limit the response to ${wordsCount} words`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
