import { AppDispatch } from "reducers/types";
import { editPostPropertiesByCollectionIdAndPostId } from "reducers/collections/actions";
import { processingLogUtils } from "hooks/use-loading";
import { notificationUtils } from "hooks/use-notification";
import {
  selectActiveContentByCollectionAndPostId,
  selectCollectionDataByCollectionId,
  selectPostByCollectionIdAndPostId,
} from "selectors/collections";
import protectedClientApi from "utils/protected-client-api";
import { getFieldProcessingStatus } from "hooks/use-generate-fields";
import { onStreamOnce } from "client-server-shared/utils/handle-stream";
import { slugify } from "client-server-shared/utils/text-utils";
import { ErrorJson } from "client-server-shared/utils/error-parsing";
import { ErrorCode } from "server/errors/utils";
import { buildGenearteConfig } from "hooks/use-generate";
import {
  capitalize,
  isPlainObject,
} from "client-server-shared/utils/lodash-methods";
import { captureException } from "utils/error-catching/lazy-sentry";
import { selectFeatures } from "selectors/user";
import { onOtherLimitReached } from "utils/other-limit";
import { LimitReachedType } from "client-server-shared/types/limit";
import { getContextText } from "client-server-shared/utils/text-utils";
import { strippedHtml } from "client-server-shared/utils/text-utils";
import { getH1 } from "utils/get-h1";
import { removeQuotesFromText } from "client-server-shared/utils/removeQuotesFromText";

const onGenerateMeta = (
  collectionId: string,
  postId: string,
  field: string
) => {
  return async (dispatch: AppDispatch, getState) => {
    const state = getState();
    const collection = selectCollectionDataByCollectionId(state, collectionId);
    const post = selectPostByCollectionIdAndPostId(state, collectionId, postId);
    const postContent = selectActiveContentByCollectionAndPostId(
      state,
      collectionId,
      postId
    );
    const features = selectFeatures(state);

    if (!collection?.templateId && !post?.templateId) {
      return;
    }

    if (field === "slug") {
      const h1 = getH1(postContent);
      if (h1 || post?.title) {
        const slug = slugify(h1 || post.title);
        dispatch(
          editPostPropertiesByCollectionIdAndPostId({
            collectionId: collectionId,
            postId: post.clientId,
            update: {
              slug: slug,
            },
          })
        );
      }

      return;
    }

    const { addFailureNotification } = notificationUtils(dispatch);

    const { addLog, onIdle } = processingLogUtils(
      getFieldProcessingStatus(postId, field)
    )(dispatch);

    const onError = (e?: ErrorJson) => {
      let errorMesssage = `There's an error generating ${field}, sorry about that! Please contact us if it keeps happening`;

      onIdle();
      if (isPlainObject(e) && e?.code === ErrorCode.NotEnoughCredits) {
        onOtherLimitReached(LimitReachedType.wordsLimitReached);
      } else {
        addFailureNotification(errorMesssage);
      }
    };

    try {
      addLog();

      if (field === "categories") {
        try {
          const res = await protectedClientApi.generateCategories({
            content: strippedHtml(getContextText(postContent, 700)),
          });
          dispatch(
            editPostPropertiesByCollectionIdAndPostId({
              collectionId,
              postId: post?.clientId,
              update: {
                categories: res,
              },
            })
          );
          onIdle();
        } catch (e) {
          onIdle();
          onError();
        }

        return;
      }

      const res = await protectedClientApi.generateFields(field, {
        templateId: post.templateId || collection.templateId,
        fields: buildGenearteConfig(post, postContent),
        features,
      });
      const { data, error } = await onStreamOnce(res);
      if (error) {
        onError(error);
        return;
      }
      let value;

      if (field === "tags") {
        const exsitingTags = post?.tags || [];
        try {
          const parseValue = JSON.parse(data);
          if (
            Array.isArray(parseValue) &&
            parseValue.every((val) => typeof val === "string")
          ) {
            value = [
              ...new Set(
                [...exsitingTags, ...parseValue].map((tag) => capitalize(tag))
              ),
            ];
          }
        } catch (e) {
          value = exsitingTags;
        }
      } else if (field === "categories") {
        // todos... remove
        const exsitingCategory = post?.categories || [];
        try {
          const parseValue = JSON.parse(data);
          if (
            Array.isArray(parseValue) &&
            parseValue.every((val) => typeof val === "string")
          ) {
            value = parseValue.map((tag) => capitalize(tag));
          }
        } catch (e) {
          value = exsitingCategory;
        }
      } else {
        value = removeQuotesFromText(data);
      }

      if (!value) {
        onError();
        return;
      }

      dispatch(
        editPostPropertiesByCollectionIdAndPostId({
          collectionId,
          postId: post.clientId,
          update: {
            [field]: value,
          },
        })
      );
      onIdle();
    } catch (e) {
      captureException(e);
      onError();
    } finally {
      onIdle();
    }
  };
};
export default onGenerateMeta;
