import { Box } from "components/ui/box";
import { IconButton } from "components/ui/button";
import { Description, Typography } from "components/ui/Typography";
import { PrimaryInput } from "components/input";
import { Dialog, DialogContent, DialogTitle } from "components/ui/dialog";
import { Section, SectionTitle } from "modules/selections/common";
import React, { useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { IntegrationContextProvider, useIntegrationContext } from "../context";
import { IntegrationType } from "client-server-shared/constants";
import { IntegrationLayout } from "../layout";
import { IconComponent } from "components/icon-component";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useClickUpgrade } from "hooks/use-click-upgrade";
import { SourceType } from "utils/tracking/type";
import { useUserHasFeatures } from "hooks/use-features";
import { InlineSpan } from "components/as-inline-span";
import { useCloseSelectedPopup } from "context/popup-context";
import { selectIntegrationsByType } from "selectors/user";
import { useSelector } from "hooks/use-selector";
import { GhostEditModal } from "./ghost-edit-modal";
import { IntegrationServer } from "client-server-shared/types/types";
import UpgradeRequired, { ghostBenefits } from "../upgrade-required";
export const GhostConnectModal = ({ onClose }: { onClose: () => void }) => {
  const { hasIntegrationFeature } = useUserHasFeatures();
  return (
    <Dialog fullWidth maxWidth={"sm"} open onClose={onClose}>
      <DialogTitle
        sx={{
          padding: "24 24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                marginRight: "24px",
              }}
            >
              Connect To Ghost
            </Typography>
          </Box>

          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              marginLeft: "auto",
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Description
          sx={{
            maxWidth: "350px",
          }}
        >
          You can connect to multiple Ghost blogs and choose which Ghost blog to
          publish to.
        </Description>
      </DialogTitle>
      <DialogContent>
        {!hasIntegrationFeature ? (
          <UpgradeRequired
            benefits={ghostBenefits}
            source={SourceType.GhostIntegrationModal}
          />
        ) : (
          <IntegrationLayout></IntegrationLayout>
        )}
      </DialogContent>
    </Dialog>
  );
};

const GhostConnectModalContainer = () => {
  const onClose = useCloseSelectedPopup();
  const integrations = useSelector((state) =>
    selectIntegrationsByType(state, IntegrationType.Ghost)
  );
  const [showConnectModal, setShowConnectModal] = React.useState(false);
  const [selectedIntegration, setSelectedIntegration] =
    React.useState<IntegrationServer | null>(null);
  return (
    <>
      <IntegrationContextProvider
        type={IntegrationType.Ghost}
        onAddNewIntegration={() => {
          setShowConnectModal(true);
        }}
        onCustomEditPost={(integration) => {
          setSelectedIntegration(integration);
        }}
      >
        {showConnectModal && (
          <GhostEditModal
            onClose={() => {
              setShowConnectModal(false);
            }}
          />
        )}
        {integrations.length === 0 ? (
          <GhostEditModal
            onClose={() => {
              onClose();
            }}
          />
        ) : selectedIntegration ? null : (
          <GhostConnectModal onClose={onClose} />
        )}
        {selectedIntegration && (
          <GhostEditModal
            url={selectedIntegration.ghost_url}
            apiKey={selectedIntegration.ghost_api_key}
            id={selectedIntegration.id}
            onClose={() => {
              setSelectedIntegration(null);
            }}
          />
        )}
      </IntegrationContextProvider>
    </>
  );
};

export default GhostConnectModalContainer;
