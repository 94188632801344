import { templateCategory, TemplateType } from "../types";

import { isDescriptionDefined, isPartialContentDefined } from "../validation";

const exampleRedditsComments = `- College writing tutor here: Read your work out loud. You will find mistakes that your eyes don't catch.
- I edit with voice over. I catch SO much more stuff that way.
- Does it really? Honestly I think I might start reading my comments allowed before I comment. Keep the grammar nazis off my back.
- Read it out loud without just saying words. Like, record yourself reading it out loud, then play it back & listen to yourself. Sounds like crazy talk, I know. I've only done it about 4 times because I usually have my hombre read it while I listen to him.
- Not advisable if you write erotic stories.

---
Kurt Vonnegut's no-bullshit tips are great:

Use the time of a total stranger in such a way that he or she will not feel the time was wasted.

Give the reader at least one character he or she can root for.

Every character should want something, even if it is only a glass of water.

Every sentence must do one of two things — reveal character or advance the action.

Start as close to the end as possible.

Be a Sadist. No matter how sweet and innocent your leading characters, make awful things happen to them-in order that the reader may see what they are made of.

Write to please just one person. If you open a window and make love to the world, so to speak, your story will get pneumonia.

Give your readers as much information as possible as soon as possible. To hell with suspense. Readers should have such complete understanding of what is going on, where and why, that they could finish the story themselves, should cockroaches eat the last few pages.
---

---
I've heard that one go farther: Try never to use adverbs.
---

---
Or be JK Rowling and use them all the time.
---

--- 
I don't understand why. They are just a part of speech. Used sparingly, what's the harm? That previous sentence being a good example: how would you even reword that to not include it without sounding stupid?

I get that you might not want to say quickly every time someone is fast, but sometimes brevity and simplicity are better than roundabout descriptions.

Edit: like the comment op said makes more sense.

" shut up" he shouted quickly=bad "Shut up" he snapped=better

And stupid wasn't the right word, I meant more along the lines of complicated. You add more words for no change in meaning or additional context.
---

---
My favorite has always been the Gary Provost lesson on varying sentence length to create rhythm and flow

This sentence has five words. Here are five more words. Five-word sentences are fine. But several together become monotonous. Listen to what is happening. The writing is getting boring. The sound of it drones. It’s like a stuck record. The ear demands some variety. Now listen. I vary the sentence length, and I create music. Music. The writing sings. It has a pleasant rhythm, a lilt, a harmony. I use short sentences. And I use sentences of medium length. And sometimes, when I am certain the reader is rested, I will engage him with a sentence of considerable length, a sentence that burns with energy and builds with all the impetus of a crescendo, the roll of the drums, the crash of the cymbals–sounds that say listen to this, it is important.

.

edit because I'm a cunt and can't leave my fleeting moments of minor popularity behind:

Wow, thanks for the upvotes and gold. Please don't think this was my quote though. It belongs to the late Gary Provost; please check out his writings and writers workshops for even more great tips on becoming a better writer. I'll see you motherfuckers at the lounge!!!!
---

---
Big fan of Junia AI. It is one of the most comprehensive for bloggers. It's great for informational posts, amazon product reviews, product roundups, post rewriting, etc. It can also crawl your backlinks and automatically insert them into the article. This makes it super easy to do inter linking. They also released a new feature called "polish" that rewrites the AI generated article using a custom model to make it easier to read. I would definitely recommend it.
---

---
Honestly I have used plenty of AI writers in the past year and love how far they have come in the past few years.

But the ones I continually work with are Junia AI and Helloscribe. I feel with the combination of these 2 AI platforms I am able to write high quality consistent work that is loved by the search engines.

Junia AI is just powerful when it comes to researching and optimizing articles, while Helloscribe is perfect for creating top quality content with multiple options, so you can select which option will fit your needs better.

Either way I hope this helps with your question and goodluck with you work
---

---
Why not run larger mains and branch close to the point of use? I could probably get all the cooling BTU’s needed in an 8 or 10”. Maybe every smaller connections to each machine as there’s less friction loss. Control valves at each branch if you need to modulate on demand.
---

---
I am really digging auto blogging for bulk content. The images are a little wonky so I typically replace those with images from MidJourney.

When it embeds a video into my post, sometimes they are ok and sometimes it’s in another language and doesn’t fit the article so I just replace it with a better one.

As with anything AI… at least right now, it’s not perfect but if it gets you 95% of the way there then that’s awesome!
---

---
Hi, I'm looking for an app with these features:

Tracking your favourite interprets, bands, DJs, festivals...

getting notifications about planned events from your favourites in your chosen city or your current location

optionall "social network" features like following the musicians page with updates (new album etc.)

Is there a thing like that? There is Go Out but it's specific for my country only and I wonder if an international one would be better.
---

---
Nice! Are the lines going to be insulated?
---

---
Don't waste money. Do step by step using Bard or.Bing that uses chatgpt. I think Bard is less clever than the others...just start with an outiline and fill it.
---

---

Idk if you already know it, but your app is not available in Italy.
In case you didn't know it, now you do lol
---

---

Did a road trip through mainland Greece last October, followed by a week on Crete. Rented a car the entire time.

If you plan on taking any ferries with the car (which you probably will considering Corfu is an island) make sure that your car rental allows that. Some don’t.

Because of that reason, when I was on the mainland (started in Athens, went to Delphi, Patras, Zakinthos, Kefalonia, Lefkada, Ioannina, Meteora, back to Athens) I rented from a specific company that allowed ferries. Actually found the company via Reddit lol. ACR Athens Car rental is the company, they may have locations outside of Athens.

In Crete, I used my credit card points to rent a car for the duration.

While I didn’t have any issues with either cars, the one that I used my points for (Chase Sapphire Reserve) was wayyyyyyyy more janky. Not sure if that was bc of how I got it or where, but the driving experience was notably different. Thought it wasn’t going to make it up some not so steep hills a couple times, was pretty hilarious but also sketchy.

Opted for the optional GPS addition but literally never used it.

OH and keep in mind gas was insanely expensive there, at least when I went. Remember it’s dollars per liter, not dollars per gallon. Keep an eye out for deals.

Have a wonderful trip :D
---

---
I remember reading somewhere that if all ice were to melt, average maximum rise would be around 77m. That's still disastrous but I just thought it was worth sharing.
---

---
Someone took their adderall that morning. All jokes aside nice fucken job.
---

---
Well, you can use plain ChatGPT but be careful with your prompts. Go for a complex and tailored pipeline for your generation process, bare output from ChatGPT is far too plastic. And if you can't afford $20/month for ChatGPT subscription, just use the OpenAI API instead from the playground interface. It will be much cheaper to do so.

I also have a product that writes personalized long-form AI articles with HTML formatting, auto inserted images and stuff. You can take a look at Milo, genuinely believe you may like it. The first 10 articles are completely free so try as you wish (and please leave your feedback).
---

---
I'm sorry, but if you need an AI bot for depth character development then you should be honing the skill of being a writer, not spending 45 minutes having a chatbot who stole from copyrighted material spell it out for you how the character works. Writing is a skill, same as drawing, music, singing, etc
---

---
When was the last time? Today. And I don’t need AI for that. I have my own imagination and creativity.

It won’t give you the value you think it can.
---

---
Tweakwrs be tweaking. Hope you got a iron door on that room.
---

---
Hi RRB

I appreciate the correction, thank you. Yes there is a new law. But read it carefully and …

Not sure you are correct. USA does not issue a national driver’s license, unlike the other countries.

I have spent enough money on expensive Kolinaki lawyers in my years working in Greece to know that its perfectionism in bureaucratic matters means it is obsessed with the letter of the law and tiny legal details do absolutely count in that country. The tiniest mistakes on forms can cost thousands to fix. Problems that seem like easy fixes can get held up for months because of things like translation problems.

Just saying, I myself would never risk it as an American. As a Brit sure. I’m not up on the licensing entities of Canada and Australia, so can’t comment on them.

The whole point of the IDL is to assure the average Greek policeman that a New Mexico driving license is the same as an American license from USA. Because most Greek policemen have never heard of New Mexico being in the United States. Or Puerto Rico. Or Guam. Etc etc.
---

---
Gonna be some humidity in that room . Neat job
---

---
There is a physical limit, there is only so much water on earth, but it may increase or decrease at certain times or locations because of the tides, weather, etc,.

But yes, maybe I could have worded it better.
---

---
This makes no sense lol :D how does the sea just appear in the middle of hungary? :D i guess they just converted everything below current sea level+100m into blue. Also some of europe is already below sea level but is not covered in water
---

---
Tbf this is more comically bad and funny than infuriating
---

---
This is infinitely cooler and more watchable / shareable than a standard jump lol
---

---
In the late 80s it was possible to climb up the rubble, right up to it. And when I say climb, I literally mean scrambling on all fours- you had to be quite agile to do that, and if you slipped, you could quite possibly keep tumbling for a while. I climbed up very close to Washington's chin, but it gets impossible to continue without ropes and harnesses after a while, and I was very unprepared, was not aware this was possible until I did it.

There is no way you can do that now, they have pretty sophisticated monitoring devices to keep trespassers out.
---

---
AI is only a tool, I am using ChatGPT every day now, however, for such a smart AI, I must adjust my input a lot of times before it produces satisfying content. The key factor is still your ideas, your thoughts, brain.
---

---
Sorry about that... But it really depends on what you're looking for in e-com.

Alli AI utilizes artificial intelligence to monitor and provide recommendations for code and content. SE Ranking incorporates AI for analyzing text and automatically generating articles. And so on and on...

In fact, many SEO tools these days rely on AI. It's become a standard feature for virtually any online service, not something cutting-edge or novel.

Lots of agencies are embracing these technologies, and they've really supercharged their workflows. It's all about that permanent scalability. You know what I mean?
---

---

Um I’m a professional driver actually. And I ride my motorbike in Athens without blinking an eye. Something that really worries my friends. But I feel comfortable splitting lanes. I owned a motorcycle in Manhattan for about ten years. ditto England where you drive on the wrong side of the road lol.

I have actually watched with my own eyes American tourists get sideswiped while pulling out of that Hertz pickup area on the sidewalk. I wouldn’t have mentioned it otherwise.

Of course it’s doable. It’s also stressful. Why mislead people?

You don’t think I got nervous every time I pulled a big Merc out into traffic there? It’s a bus lane. In Athens. Anybody who has ever done the same will be laughing reading this. Sweat does tend to bead the brow.

But I did it. And I always advised my friends to rent at the airport. And it is more like a EUR70 taxi from Plaka in my experience. Maybe that has changed?

EDIT the other issue with that “pickup location” is the pylons (bollards) that are within about 10 cm of the car doors when they line the cars up to be collected. Backing out into the bus lane without scratching the car takes a lot of concentration. Seriously. I rent there a lot. I can walk there from my house. It’s a problematic location—but hey that’s just my take.
---

---
Ha ha I was just thinking that!

Some people here be acting like Mhi’ya - and Q - personally came up to their house & pissed in their cereal! 🥣
---

---
Renting cars is consistent throughout Europe.

Cheap insurance? Don't you have an American credit card that covers CDW?

I use big companies, I generally have better experience through them. If you want to use a smaller company, check the reviews in Google to make sure.
---

---
Yeah I have Chase Sapphire Reserve, which I figured covered it. It’s just been awhile since I looked into what all that covered. Thanks!

You're good to go. Greece is a lot like Italy.. a little on the crazy side.
---

---
I’m in love with this cat’s little worried looking face.
---

---
Even while managing your handicap creates challenges, if you do a good job of managing it, you won't be deemed "disabled enough" to be eligible for any social care support that you'll probably need to keep managing and have a fulfilling life.
---

---
canceling appointments.

I be charged $50 (like a doctor or therapist) if I cancel an appointment.

There are no repercussions if they phone and cancel as I'm leaving the building.
---

---
Funny how "It's just five minutes" can be insubordination and a reasonable argument on the same day.
---
---
I'm legally blind, and I hate that I "have" to do this, but sometimes I play up my disability a bit more so that people don't judge me, because I often walk around like a "normal" person, for lack of a better word, but if people see me board an airplane or sit at the front of a bus or something, they think I'm an asshole.
---

---
FYI: This seems to be a Finnish Intercity train wherein there are designated pet carriages where pets are allowed. You can see the dog icon up and right on the third picture that's the pet carriage sign.

Edit: content not OC. But my intention was well-informed. This community needed this cat.
---

---
If you are US based, I recommend getting an international driver’s license through AAA. Makes it less of a hassle overall

I can recommend ACR and Hertz. Sometimes if you pay in cash there will be a discount

If you cannot drive a manual, definitely book an automatic in advance as the supply for those is lower

Since you are going to Kerkyra/Corfu you will need a ferry pass from the company as well, if you plan to take the car on the ferry. Since you are leaving from Thessaloniki, I recommend stopping by Litochoro/Mount Olympus if you are interested in hiking/outdoors activities

Have a good trip :)
---
`;

export const socialThreadReplyerTemplate: TemplateType = {
  id: "social-replyer-PmeQS",
  value: "social-replyer-PmeQS",
  label: "social-replyer",
  slug: "social-replyer",
  categories: [templateCategory.socialMedia],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "social media thread reply",
  title: "Social Media Thread Reply",
  defaultWordsCount: 100,
  contentTypeLabel: "social media thread reply",
  helpText:
    "Provide the original post content and any additional context to generate a relevant and engaging reply",
  defaultCollectionName: "Social Thread Replies",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your reply",
  defaultPostName: "Untitled Reply",
  toGeneratorPrompt: "Need to generate more social media thread replies?",
  cardDescription:
    "Generate engaging replies to social media threads or posts on platforms like Twitter and Facebook",
  shortDescription:
    "Craft a personalized response to a social media post, whether it's for personal or business purposes",
  freeLimit: 3,

  caption:
    "Join in on social media conversations and make your voice heard with well-crafted replies",
  addtionalFields: [],
  excludedFields: [
    "targetAudience",
    "keywords",
    "writingStyle",
    "editorialImage",
    "startFromOutline",
  ],
  fields: {
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a title for your reply",
      onValidate: (post) => {
        return !!(isDescriptionDefined(post) || isPartialContentDefined(post));
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt = "Generate a title for a reply to a social media post";

        if (description) {
          prompt += ` with the details of ${description}`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (content) {
          prompt += `. Here's some partial content from the reply: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },

    description: {
      label: "Details",
      fieldName: "description",
      required: true,
      placeholder: `- [Original post content]`,
      description: "Enter the original post text you're responding to",
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Friendly, formal",
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!isDescriptionDefined(post);
      },
      calculateCost: (config) => config.wordsCount || 100,
      onInValid: () => {},
      buildSystemPrompt: (post, func, bg) => {
        const language = post.language;

        let systemPrompt = `You are an expert in writing human-like, authentic Social Media Post Comment. ${
          bg
            ? `\nHere are some background that you may need to know: "${bg}".`
            : ""
        }
        ${
          language && language !== "English"
            ? `\nYou will write in ${language} language.`
            : ""
        }

${
  post.toneOfVoice
    ? `You will write in following tone of voice: "${post.toneOfVoice}".`
    : ``
} 
      
Here are some examples of Social Media comments you have written in the past that yielded positive results:
      
"${exampleRedditsComments}".
      
The above comments you have written in the past is simple and concise.
      
Remember, in order to succeed on Social Media platform without getting downvoted, reported, banned or redditors being suspicious of your comment is AI-generated, your reply needs to be:
      
1. Simple Language: Use more common language and sentence structure. Like a teenager would write.
2. Authenticity: Introduce occasional, minor grammatical or spelling errors to mimic typical human mistakes, enhancing the human-like quality of your comments.
3. Avoid being polite or overly formal. Talk casually and naturally.
4. No any form of figurative language like similes or metaphors.
5. No any form of exaggerations. No hyperbole.
      
And more, remember to refer to the past comments you have written above and use the same writing style, tone, language and choice of words.
      
You will be given a Social Media conversation and you will generate a comment.
      
Return the comment without anything else in your response.`;
        return systemPrompt;
      },
      buildPrompt: (post) => {
        const description = post.description;

        let prompt = `
"${description}".
    
Based on the above. Refer to the past example comments you have made and create a comment with the same writing style, tone, language and choice of words.
    
Do not use any form of figurative language like similes or metaphors, or exaggerations, or hyperbole.
    
Remember to use the same writing style, tone, language and choice of words as the past example comments you have written.
    
Return the comment without anything else in your response. 
             `;

        return prompt;
      },
    },
  },
};
