import { clientCustomTemplate } from "templates/client-templates/default-template";

import * as React from "react";
import { TemplateField } from "templates/shared-templates/types";

interface SelectedTemplateContextValue {
  getCanRender: (key: string) => boolean;
  getPlaceholder: (key: string) => string;
  getAutoCompleteSuggestions: (key: string) => string[] | undefined;
  getSectionTitle: (key: string) => string | undefined;
  template: any;
  getSectionDescription: (key: string) => string | undefined;
  getInfo: (key: string) => string | undefined;
  clientTemplatesById: Record<string, any>;
}

const SelectedTemplateContext =
  React.createContext<SelectedTemplateContextValue | null>(null);

const defaultValues = {
  getCanRender: () => true,
  getPlaceholder: () => "",
  getAutoCompleteSuggestions: () => undefined,
  getSectionTitle: () => undefined,
  getInfo: () => undefined,
  template: clientCustomTemplate,
};

const useSelectedTemplateContext = () => {
  const context = React.useContext(SelectedTemplateContext);
  if (!context) {
    return defaultValues;
    /*
    throw new Error(
      "SelectedTemplateContext needs to be used with SelectedTemplateContextProvider"
    );
    */
  }
  return context;
};

interface Props {
  children: React.ReactNode;
  templateId?: string;
}

export const SelectedTemplateContextProvider = (props: Props) => {
  const { templateId, children, getTemplateByTemplateId } = props;
  const effectiveTemplateId = templateId;

  const [template, setTemplate] = React.useState(() => {
    const foundTemplate = getTemplateByTemplateId(effectiveTemplateId);
    return foundTemplate;
  });
  const { excludedFields = [], fields } = template;

  React.useEffect(() => {
    const foundTemplate = getTemplateByTemplateId(effectiveTemplateId);

    setTemplate(foundTemplate);
  }, [effectiveTemplateId]);

  const getCanRender = React.useCallback(
    (key: string) => {
      return !excludedFields.includes(key);
    },
    [template]
  );

  const getPlaceholder = React.useCallback(
    (key: string) => {
      return fields[key]?.placeholder || "";
    },
    [fields]
  );

  const getAutoCompleteSuggestions = React.useCallback(
    (key: string) => {
      return fields[key]?.suggestions || undefined;
    },
    [fields]
  );

  const getSectionTitle = React.useCallback(
    (key: string) => {
      return fields[key]?.label || undefined;
    },
    [fields]
  );

  const getSectionDescription = React.useCallback(
    (key: string) => {
      return fields[key]?.description || undefined;
    },
    [fields]
  );

  const getInfo = React.useCallback(
    (key: string) => {
      return fields[key]?.info || undefined;
    },
    [fields]
  );

  const getField = React.useCallback(
    (key: string) => {
      return fields[key] || {};
    },
    [fields]
  );

  const values = {
    template,
    getCanRender,
    getPlaceholder,
    getAutoCompleteSuggestions,
    getSectionTitle,
    getSectionDescription,
    getInfo,
    getField,
  };
  return (
    <SelectedTemplateContext.Provider value={values}>
      {children}
    </SelectedTemplateContext.Provider>
  );
};

export const useCanRenderField = () =>
  useSelectedTemplateContext().getCanRender;

export const useGetAutoCompleteSuggestions = () => {
  return useSelectedTemplateContext().getAutoCompleteSuggestions;
};

export const useGetTemplateMeta = () => {
  return useSelectedTemplateContext().template;
};

export const useGetAllFields = (): Record<string, TemplateField> => {
  return useSelectedTemplateContext().template.fields;
};

export const useGetFieldConfig = (key: string) => {
  const {
    getAutoCompleteSuggestions,
    getPlaceholder,
    getSectionTitle,
    getSectionDescription,
    getInfo,
    getField,
  } = useSelectedTemplateContext();

  return {
    sectionTitle: getSectionTitle(key),
    sectionDescription: getSectionDescription(key),
    placeholder: getPlaceholder(key),
    autoCompleteSuggestions: getAutoCompleteSuggestions(key),
    info: getInfo(key),
    ...getField(key),
  };
};

export const useGetGetPlaceholder = () =>
  useSelectedTemplateContext().getPlaceholder;

export const useGetSectionTitle = () =>
  useSelectedTemplateContext().getSectionTitle;
