import EventEmitter from "eventemitter3";
import React from "react";
import { useImmer } from "./use-immer";
import { useProcessingLog } from "./use-loading";
import { captureException } from "utils/error-catching/lazy-sentry";

export const fieldChangeEventEmitter = new EventEmitter();

const contentEventKey = (postId: string) => `${postId}-content`;

export const getFieldProcessingStatus = (postId: string, fieldName: string) => {
  return `${postId}-${fieldName}-loading`;
};

export const emitContentByPostId = (
  postId: string,
  content: string,
  index: number
) => {
  try {
    fieldChangeEventEmitter.emit(contentEventKey(postId), content, index);
  } catch (e) {
    captureException(e);
  }
};

export const useFieldLoadingStatus = (postId: string, fieldName: string) => {
  const { isLoading } = useProcessingLog(
    getFieldProcessingStatus(postId, fieldName)
  );
  return { isLoading };
};

const emptyObject: Record<number, string> = {};

export const useSubscribeToContentChange = (
  postId: string,
  postIndex: number
) => {
  const [contentValueByIndex, setContentValueByIndex] = useImmer(emptyObject);
  const onContentChange = React.useCallback(
    (content: string, index: number) => {
      setContentValueByIndex((previous) => {
        return {
          ...previous,
          [index]: content,
        };
      });
    },
    []
  );

  React.useEffect(() => {
    fieldChangeEventEmitter.on(contentEventKey(postId), onContentChange);
    return () => {
      try {
        fieldChangeEventEmitter?.off(contentEventKey(postId), onContentChange);
      } catch (e) {
        captureException(e);
      }
    };
  }, []);
  return {
    contentValue: contentValueByIndex[postIndex] || "",
  };
};
