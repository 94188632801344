import { templateCategory, TemplateType } from "../types";

import {
  canGeneratePostContent,
  isDescriptionDefined,
  createOnInvalidFunc,
  isKeywordsDefined,
  isPartialContentDefined,
} from "../validation";

export const productReviewTemplate: TemplateType = {
  id: "product-review-TeHqM",
  value: "product-review-TeHqM",
  label: "Product Review",
  slug: "product-review",
  categories: [templateCategory.ecommerce],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "product review",
  title: "Product Review",
  contentTypeLabel: "product review",
  helpText: "Choose at least one keyword, description, or title to begin",
  defaultCollectionName: "Product Review collection",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a name for your product",
  defaultPostName: "Untitled Product Review",
  toGeneratorPrompt: "Need to generate more product reviews?",
  defaultWordsCount: 250,
  cardDescription:
    "Effortlessly generate detailed and engaging product reviews.",
  shortDescription: "All fields are <strong>optional</strong>",
  caption: "",
  requiredFields: ["title"],
  excludedFields: ["targetAudience", "editorialImage", "startFromOutline"],
  fields: {
    title: {
      label: "Product Name",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a product name",
      onValidate: (post) => {
        return !!(
          isDescriptionDefined(post) ||
          isPartialContentDefined(post) ||
          isKeywordsDefined(post)
        );
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt = "Generate a compelling title for a product review";

        if (description) {
          prompt += ` with the description or features of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }

        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (content) {
          prompt += `. Here's some partial content from the product review: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },
    writingStyle: {
      label: "Writing Style",
      fieldName: "writingStyle",
      optional: true,
      placeholder: "Eg: Informative, conversational",
    },
    description: {
      label: "Product description",
      fieldName: "description",
      required: true,
      description:
        "Key benefits/features of the product or your own personal opinion",
      placeholder: `- Name: Junia AI - an all-in-one content creation platform\n- More affordable compared to other AI tools\n- Low error rate\n- The generated content gets indexed by Google overnight`,
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Friendly, formal",
    },
    targetAudience: {
      label: "Target audience",
      fieldName: "targetAudience",
      optional: true,
      placeholder: "Examples: Students, Programmers",
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return canGeneratePostContent(post);
      },
      calculateCost: (config) => config.wordsCount || 250,
      onInValid: createOnInvalidFunc(
        "Either a product name or some product description is required to generate a product review"
      ),
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const keywords = post.keywords;
        const title = post.title;
        let prompt = "Write a detailed and engaging product review";

        if (description) {
          prompt += `, highlight the following product description or key benefits: ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, using a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, with a ${toneOfVoice} tone of voice`;
        }

        if (keywords && keywords.length > 0) {
          prompt += `, incorporating the following keywords: ${keywords.join(
            ", "
          )}`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        if (wordsCount) {
          prompt += `. The product review should be approximately ${wordsCount} words long`;
        }

        prompt +=
          ". Please focus on the product's features, benefits, and any potential drawbacks. Also, include personal experiences, if applicable.";

        return prompt;
      },
    },
  },
};
