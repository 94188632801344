import { templateCategory, TemplateType } from "../types";

import {
  isDescriptionDefined,
  isPartialContentDefined,
  isPurposeDefined,
} from "../validation";

export const instagramBioTemplate: TemplateType = {
  id: "instagram-bio-PmeQS",
  value: "instagram-bio-PmeQS",
  label: "instagram-bio",
  slug: "instagram-bio",
  categories: [templateCategory.socialMedia],
  tags: ["Artist", "Song", "Music", "Creative", "Lyric"],
  contentType: "instagram bio",
  title: "Instagram Bio",
  defaultWordsCount: 100,
  contentTypeLabel: "instagram bio",
  helpText: "Enter at least some personal information to begin",
  defaultCollectionName: "Instagram Bio collection",
  collectionNamePlaceholder: "Enter a name for your collection",
  postNamePlaceholder: "Enter a title for your Instagram Bio",
  defaultPostName: "Untitled Instagram Bio",
  toGeneratorPrompt: "Need to generate more instagram posts?",
  cardDescription:
    "Create the perfect Instagram bio that captures your personality and style",
  shortDescription: "All fields are <strong>optional</strong>",
  caption: "",
  addtionalFields: [],
  excludedFields: [
    "targetAudience",
    "keywords",
    "writingStyle",
    "editorialImage",
    "startFromOutline",
    "wordsCount",
  ],
  fields: {
    title: {
      label: "Title",
      fieldName: "title",
      calculateCost: () => 0,
      placeholder: "Enter or generate a title",
      onValidate: (post) => {
        return !!(
          isDescriptionDefined(post) ||
          isPartialContentDefined(post) ||
          isPurposeDefined(post)
        );
      },
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const targetAudience = post.targetAudience;
        const writingStyle = post.writingStyle;
        const toneOfVoice = post.toneOfVoice;
        const language = post.language;
        const content = post.content;
        const keywords = post.keywords;

        let prompt = "Generate a compelling title for an instagram bio";

        if (description) {
          prompt += ` with the topic of ${description}`;
        }

        if (targetAudience) {
          prompt += `, should appeals to the target audience: ${targetAudience}`;
        }

        if (writingStyle) {
          prompt += `, written in a ${writingStyle} writing style`;
        }

        if (toneOfVoice) {
          prompt += `, and has a ${toneOfVoice} tone of voice`;
        }
        if (language) {
          prompt += `, in ${language} language`;
        }
        if (keywords && keywords.length > 0) {
          prompt += `, based on the following keywords: ${keywords.join(", ")}`;
        }

        if (content) {
          prompt += `. Here's some partial content from the post: ${content}`;
        }

        prompt += ".";

        return prompt;
      },
    },

    description: {
      label: "Personal Information",
      fieldName: "description",
      required: true,
      placeholder: `Mom of 2 cats, loves cooking vegan food, and enjoys writing about my life`,
      description: " ",
    },
    toneOfVoice: {
      label: "Tone of voice",
      fieldName: "toneOfVoice",
      optional: true,
      placeholder: "Friendly, formal",
    },
    content: {
      label: "Content",
      fieldName: "content",
      description: "",
      onValidate: (post) => {
        return !!(isPurposeDefined(post) || isDescriptionDefined(post));
      },
      calculateCost: () => 100,
      onInValid: () => {},
      buildPrompt: (post) => {
        const description = post.description;
        const toneOfVoice = post.toneOfVoice;
        const wordsCount = post.wordsCount;
        const language = post.language;
        const purpose = post.purpose;

        let prompt =
          "Generate an short instagram bio (100-150 characters) based on the provided information";

        if (description) {
          prompt += `. Personal information: ${description}`;
        }

        if (toneOfVoice) {
          prompt += ` using a ${toneOfVoice} tone of voice`;
        }

        if (language) {
          prompt += `, in ${language} language`;
        }

        prompt += ".";

        return prompt;
      },
    },
  },
};
